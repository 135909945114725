import React from 'react';
import { Card, CardContent } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Carousel from 'react-multi-carousel';
import i18n from '../../i18n';
import RoomIcon from '@material-ui/icons/Room';
import 'react-multi-carousel/lib/styles.css';
import './styles.scss';
import { initialState } from '../../_reducers/general.reducer';
import { useSelector } from 'react-redux';

const responsive = {
  mobile: {
    breakpoint: { max: 4000, min: 0 },
    items: 1,
  },
};

const PropertyImagesSlider = (props) => {
  const { property_images, propertyId } = props;
  return (
    <Carousel responsive={responsive}>
      {property_images.map((image, index) => {
        return (
          <div key={index}>
            <Link to={'/property/view/' + propertyId}>
              <div
                className="property-image"
                style={{ backgroundImage: `url('` + image.image_path + `')` }}
              ></div>
            </Link>
          </div>
        );
      })}
    </Carousel>
  );
};

const PropertyFinancialBlock = (props) => {
  const { property, variant } = props;
  const coinRate = useSelector(({ general: { coinRate } }) => coinRate);

  function formatePrice(val) {
    if (val !== undefined && val !== null && !isNaN(val)) {
      var parts = val.toString().split('.');
      if (parts.length > 1) {
        val =
          parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.') +
          (parts[1] ? ',' + parts[1] : '');
      }
      val = numberWithCommas(val);

      return val;
    }
  }

  const formateCoinPrice = (val) => {
    if (val !== undefined && val !== null && !isNaN(val)) {
      var parts = val.toString().split(".");
      if (parts.length > 1) {
        val = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".") + (parts[1] ? "," + parts[1] : "");
        val = val.slice(0, Math.min(val.length, 10));
      }
      return val;
    }
  }

  function numberWithCommas(x) {
    if (x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }
    return x;
  }

  return (
    <>
      <p className='property-location'><RoomIcon />
        <span>{(initialState.languageCode === 'en') ? property.macrolocation_heading['en'] : property.macrolocation_heading['de']}, {i18n.t(property.address_coundivy)}</span>
        {(variant === "primary") && <span className='badge-new'>{i18n.t('NEW')}</span>}
      </p>

      <div className='property-financial-block'>
          {
            (variant === "primary")
            ? (
              <>
                <div>
                  <p className='txt-value'>&#36; {numberWithCommas(property.financing_volume)}</p>
                  <p className='txt-title'>{i18n.t('Property Price')}</p>
                </div>
                <div>
                  <p className='txt-value'>&#36; {formatePrice(property.price_per_share)}</p>
                  <p className='txt-title'>{i18n.t('Min. Investment')}</p>
                </div>
                <div>
                  <p className='txt-value'>{formatePrice(property.profit_payout_percent)} %</p>
                  <p className='txt-title'>{i18n.t('Net rental return')}</p>
                </div>
              </>
            )
            : property.property_type === 'direct'
            ? (
              <>
                <div>
                  <p className='txt-value'>&#36; {formatePrice(property.property_price)}</p>
                  <p className='txt-title'>{i18n.t('Price')}</p>
                </div>
                <div>
                  <p className='txt-value'>{coinRate?.BTC ? formateCoinPrice(property.property_price / coinRate.BTC) : '-'}</p>
                  <p className='txt-title'>BTC</p>
                </div>
                <div>
                  <p className='txt-value'>{coinRate?.ETH ? formateCoinPrice(property.property_price / coinRate.ETH) : '-'}</p>
                  <p className='txt-title'>ETH</p>
                </div>
              </>
            )
            : (
              <>
                <div>
                  <p className='txt-value'>&#36; {numberWithCommas((property.current_price_per_share * property.total_share_count).toFixed(0))}</p>
                  <p className='txt-title'>{i18n.t('Market Value')}</p>
                </div>
                <div>
                  <p className='txt-value'>&#36; {formatePrice(property.current_price_per_share)}</p>
                  <p className='txt-title'>{i18n.t('Price per Share')}</p>
                </div>
                <div>
                  <p className='txt-value'>{formatePrice(((property.disdivibution_surplus / (property.current_price_per_share * property.total_share_count)) * 100).toFixed(2))} %</p>
                  <p className='txt-title'>{i18n.t('Net rental return')}</p>
                </div>
              </>
            )
          }
      </div>
    </>
  )
};

export default function PropertyBoxComponent(props) {
  const { type, variant, property } = props;

  return (
    <Card className={type + "-view " + variant + "-type property-card-box"}>
      <div className='property-images'>
        <PropertyImagesSlider propertyId={property.property_id} property_images={(property.property_images && property.property_images.length > 0) ? property.property_images : []} />
        <div className='property-details'>
          <Link to={"/property/view/" + property.property_id}><p className='property-name'>{property.title}</p></Link>
        </div>
      </div>

      <CardContent className='property-content'>
        <Link to={"/property/view/" + property.property_id}><PropertyFinancialBlock property={property} variant={variant} /></Link>
      </CardContent>
    </Card>
  );
}
PropertyBoxComponent.defaultProps = {
  type: 'grid',
};
