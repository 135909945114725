import React, { useEffect, useState } from 'react';
import i18n from '../i18n';
import { useNavigate, useLocation } from 'react-router-dom';
import { useConfirm } from '../components/Confirm';
import { secureStorage } from '../_services';

const TOKEN_WALLET_PAGE_URL = '/dashboard/tokenwallet';

const ForceWalletComponent = ({ }) => {
  const [walletAddress, setWalletAddress] = useState(secureStorage.getItem("wallet_address"));
  const [isKYCVerified, setKYIVerified] = useState(secureStorage.getItem('is_kyc_verified'));
  const { isConfirmed } = useConfirm();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const wallet_address = secureStorage.getItem("wallet_address");
    if (wallet_address) {
      setWalletAddress(wallet_address);
    }
    function storageEventHandler(event) {
      if (event.key === "wallet_address") {
        setWalletAddress(event.newValue);
      } else if (event.key === "is_kyc_verified") {
        setKYIVerified(event.newValue);
      }
    }

    window.addEventListener("storage", storageEventHandler);
    return () => {
      window.removeEventListener("storage", storageEventHandler);
    };
  }, []);

  useEffect(() => {
    if (isKYCVerified && !walletAddress && (location.pathname !== TOKEN_WALLET_PAGE_URL && location.pathname.includes('dashboard') && !location.pathname.includes('profile'))) {
      forceConnect2Wallet();
    }
  }, [walletAddress, location.pathname]);

  const forceConnect2Wallet = async () => {
    await isConfirmed({
      title: 'Connect to your wallet',
      message: 'You do not currently have a wallet setup. If you have setup a wallet on another machine, or recently cleared your browser storage you will need to restore it using the seed phrase you wrote down. If you have never set one up, you can do that now',
      okay: 'Go to wallet page',
      disableBackdropClick: true,
    });

    navigate(TOKEN_WALLET_PAGE_URL);
  }

  return null;
}

export default ForceWalletComponent;
export { ForceWalletComponent };
