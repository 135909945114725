import React from "react";
import { connect } from 'react-redux'
import classNames from "classnames";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Toolbar, IconButton, Hidden, Drawer } from "@material-ui/core";
import Menu from "@material-ui/icons/Menu";
import NavBarLinks from "./NavBarLinks";
import { NavLink } from 'react-router-dom';
import { HeaderLinks } from "./HeaderLinks";
import logoB from '../../../assets/images/logo-b.png';
import logoW from '../../../assets/images/logo-w.png';
import styles from "../Style";
import "../styles.scss";

const useStyles = makeStyles(styles);


function NavBarComponent(props) {
    const classes = useStyles();
    const [isSticky, setIsSticky] = React.useState(false);
    const [mobileNavOpen, setMobileNavOpen] = React.useState(false);
    const  noStickyClass = 'noSticky'
    const handleNavDrawerToggle = () => {
        setMobileNavOpen(!mobileNavOpen);
    };

    const { leftLinks, absolute, pageName } = props;
    const appBarClasses = classNames({
        [classes.appBar]: true,
        [classes.absolute]: absolute,
        [classes.fixed]: "true",
    });

    const logos = {
        light: logoB,
        sea:  logoB,
        cosmos: logoW,
        spring:  logoB,
        forest: logoW,
        butterfly:  logoB,
        sensual: logoW,
        dark: logoW,
    };
    const theme = props.users?.profile_data?.theme || 'light';

    const brandComponent =
        // <div className="dashboard-nav-logo">
            <NavLink to="/" className="nav-logo">
            <img src={logos[theme] || logoB} style={{backgroundColor: props.logobackground, paddingLeft: 10, paddingRight: 10}} alt="" />
          </NavLink>
            {/* <a href="/" className={classes.title}>
                <img src={logos[theme] || logoB} className={classes.navDrawerImg} alt="" />
            </a> */}
        // </div>;

    return (
        <div className={`app-navbar-container ${classes.root} ${isSticky ? 'isSticky' : noStickyClass}`}>
        <AppBar className={appBarClasses}>
            <Toolbar disableGutters className={classes.container}>
                <Hidden mdUp>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleNavDrawerToggle}
                    >
                        <Menu />
                    </IconButton>
                </Hidden>

                {leftLinks !== undefined ? brandComponent : null}
                <div className={classes.flex}>
                    {leftLinks !== undefined ? (
                        <Hidden smDown implementation="css">
                            {leftLinks}
                        </Hidden>
                    ) : (
                            brandComponent
                        )}
                </div>
                <Hidden smDown implementation="css">
                    <HeaderLinks page={pageName} />
                </Hidden>
            </Toolbar>

            <Hidden smDown implementation="css">
                <Drawer
                    variant="permanent"
                    classes={{
                        paper: classes.appDrawerPaper,
                    }}
                    className="navbarlink-drawer"
                >
                    <NavBarLinks page={pageName} />
                </Drawer>
            </Hidden>

            <Hidden mdUp implementation="js">
                <Drawer
                    variant="temporary"
                    open={mobileNavOpen}
                    classes={{
                        paper: classes.appDrawerPaper,
                    }}
                    className="navbarlink-drawer"
                    onClose={handleNavDrawerToggle}
                >
                    <div className={classes.navBarTitle}>
                        {brandComponent}
                    </div>

                    <NavBarLinks page={pageName} navControl={handleNavDrawerToggle} />
                </Drawer>
            </Hidden>
        </AppBar>
        </div>
    );
}

NavBarComponent.defaultProp = {
    color: "white"
};

NavBarComponent.propTypes = {
    color: PropTypes.oneOf([
        "primary",
        "info",
        "success",
        "warning",
        "danger",
        "transparent",
        "white",
        "rose",
        "dark"
    ]),
    rightLinks: PropTypes.node,
    leftLinks: PropTypes.node,
    brand: PropTypes.string,
    fixed: PropTypes.bool,
    absolute: PropTypes.bool
};


function mapState(state) {
    const { users } = state;
    return { users };
}

const NavBar = connect(mapState)(NavBarComponent);

export default NavBar;
export { NavBar as NavBarComponent };
