import React, { Component } from 'react';
import { Button, Box, Grid } from '@material-ui/core';
import { connect } from "react-redux";
import { generalActions, userActions } from "../../_actions";

class PropertyInvestmentBox extends Component {
  constructor() {
    super()

    this.state = {
      available_financial_instruments: [],
      available_dividend_distributions: [],
      coinRate: {
        BTC: 40000,
        ETH: 4000,
      }
    }
  }

  componentDidMount() {
    // this.props.parseJwt();
    this.props.getFinancialInstruments();
    this.props.getDividendDistributions();
  }

  formatePrice = (val) => {
    if (val !== undefined && val !== null && !isNaN(val)) {
      var parts = val.toString().split(".");
      if (parts.length > 1) {
        val = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".") + (parts[1] ? "," + parts[1] : "");
      }
      val = this.numberWithCommas(val);
      return val;
    }
  }

  numberWithCommas = (x) => {
    if (x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }
    return x;
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      financial_instruments,
      dividend_distributions,
    } = nextProps.general;

    var update = {};
    if (financial_instruments && financial_instruments.status === true) {
      update = {
        ...update,
        available_financial_instruments: financial_instruments.data
      };
    }

    if (dividend_distributions && dividend_distributions.status === true) {
      update = {
        ...update,
        available_dividend_distributions: dividend_distributions.data
      };
    }

    return update;
  }

  calcuteDividendYield = (distribution_surplus, financing_volume) => {
    distribution_surplus = Number(distribution_surplus);
    financing_volume = Number(financing_volume);
    if (!isNaN(distribution_surplus / financing_volume)) {
      return (distribution_surplus / financing_volume).toFixed(2);
    }
    return 0;
  }

  calculatePropertySharePrice = (financing_volume, total_share_count) => {
    financing_volume = Number(financing_volume);
    total_share_count = Number(total_share_count);
    if (!isNaN(financing_volume / total_share_count)) {
      return (financing_volume / total_share_count).toFixed(2);
    }
    return 0;
  }

  getDividendDistributionName = (distributionId) => {
    const { available_dividend_distributions } = this.state;
    if (available_dividend_distributions.length) {
      var dividentDistribution = available_dividend_distributions.find((d) => d.id === distributionId);
      return dividentDistribution ? dividentDistribution.name : 'Unknown';
    }
    return 'Unknown';
  }

  getFinancialInstrumentsName = (instrumentId) => {
    const { available_financial_instruments } = this.state;
    if (available_financial_instruments.length) {
      var financialInstrument = available_financial_instruments.find((f) => f.id === instrumentId);
      return financialInstrument ? financialInstrument.name : 'Unknown';
    }
    return 'Unknown';
  }

  getPropertyIssueDetails = () => {
    // var tokenInfo = this.props.parseJwt();
    // if (tokenInfo && tokenInfo.status) {
    //   var profileData = tokenInfo.data;
    //   return profileData.first_name + " " + profileData.last_name;
    // }
    return "";
  }

  formatePrice = (val) => {
    if (val !== undefined && val !== null && !isNaN(val)) {
      var parts = val.toString().split(".");
      if (parts.length > 1) {
        val = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".") + (parts[1] ? "," + parts[1] : "");
      }
      val = this.numberWithCommas(val);
      return val;
    }
  }

  formateCoinPrice = (val) => {
    if (val !== undefined && val !== null && !isNaN(val)) {
      var parts = val.toString().split(".");
      if (parts.length > 1) {
        val = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".") + (parts[1] ? "," + parts[1] : "");
      }
      return val;
    }
  }

  render() {
    const { property } = this.props;

    return (
      <div className='property-investment-box'>
        {property.property_type === "primary" ? (
          <div className="property-investment-box-primary">
            <table className="table table-block-1">
              <tbody>
                <tr>
                  <td>
                    <p className='txt-value'>{this.formatePrice(this.calcuteDividendYield(property.distribution_surplus, property.financing_volume))} % <span>p.a.*</span></p>
                    <p className='txt-title'>Net rental return</p>
                  </td>
                  <td>
                    <p className='txt-value'>3,9 % <span>p.a.*</span></p>
                    <p className='txt-title'>Total Return</p>
                  </td>
                </tr>
              </tbody>
            </table>

            <table className="table table-block-2">
              <tbody>
                <tr>
                  <td>Distribution</td>
                  <td>{this.getDividendDistributionName(property.dividend_distribution)}</td>
                </tr>
                <tr>
                  <td>1. Payment Date</td>
                  <td>End of April</td>
                </tr>
                <tr>
                  <td>Type of property</td>
                  <td>Rental</td>
                </tr>
                <tr>
                  <td>Funding Goal</td>
                  <td>&euro; {this.formatePrice(property.financing_volume)}</td>
                </tr>
                <tr>
                  <td>Price per Share</td>
                  <td>&euro; {this.formatePrice(this.calculatePropertySharePrice(property.financing_volume, property.total_share_count))}</td>
                </tr>
                <tr>
                  <td>Annual rental income*</td>
                  <td>&euro; 4.796</td>
                </tr>
                <tr>
                  <td>Financial Instrument</td>
                  <td>{this.getFinancialInstrumentsName(property.financial_instrument)}</td>
                </tr>
                <tr>
                  <td>Issuer</td>
                  <td>{this.getPropertyIssueDetails()}</td>
                </tr>
              </tbody>
            </table>

            <Box mt={3}>
              <Button variant="contained" className="btn-invest btn-secondary">Invest Now</Button>
            </Box>
          </div>
        )
          : property.property_type === 'direct'
            ? (
              <div className="property-investment-box-direct">
                <Grid item xs={12} sm={12} md={12}>
                  <div className="value-block">
                    <p className="txt-value block-1">
                      <span>&#36; {this.formatePrice(property.property_price)}</span>
                    </p>
                    <p className="txt-information block-1">
                      <span>Purchase with Bitcoin</span>
                    </p>
                    <p className="txt-information block-1">
                      <span>
                        BTC <span className="crypto-value">{this.state.coinRate.BTC ? this.formateCoinPrice(property.property_price / this.state.coinRate.BTC) : '-'}</span>
                      </span>
                    </p>
                    <p className="txt-information block-1">
                      <span>Purchase with Ether</span>
                    </p>
                    <p className="txt-information block-1">
                      <span>
                        ETH <span className="crypto-value">{this.state.coinRate.ETH ? this.formateCoinPrice(property.property_price / this.state.coinRate.ETH) : '-'}</span>
                      </span>
                    </p>
                    <div
                      className="txt-action block-1"
                    >
                      <Box mt={3} >
                        <Button variant="contained" className="btn-order btn-secondary">Purchase Property</Button>
                      </Box>
                    </div>
                  </div>
                </Grid>
              </div>
            )
            : (
              <div className="property-investment-box-secondary">
                <Grid item xs={12} sm={12} md={12}>
                  <div className="value-block">
                    <p className="txt-title block-1">
                      <span>Invest Now</span>
                    </p>
                    <p className="txt-value block-1">
                      <span>€ 105,20</span>
                    </p>
                    <p className="txt-information block-1">
                      <span>Lowest Available Share Price</span>
                    </p>
                    <div>
                      <Box mt={3} >
                        <Button variant="contained" className="btn-order btn-secondary">Current offers</Button>
                      </Box>
                    </div>
                  </div>
                </Grid>
              </div>
            )}
      </div>
    );
  }
}

function mapState(state) {
  const { users, general } = state;
  return { users, general };
}

const actionCreators = {
  parseJwt: userActions.parseJwt,
  getFinancialInstruments: generalActions.getFinancialInstruments,
  getDividendDistributions: generalActions.getDividendDistributions,
};

export default connect(mapState, actionCreators)(PropertyInvestmentBox);
