import React, { Component } from 'react';
import { Dialog, DialogContent, Box, Button } from '@material-ui/core';
import CreditCardOutlinedIcon from '@material-ui/icons/CreditCardOutlined';
import i18n from "../../../i18n";
import "./styles.scss";

class WithdrawInfoAdminModal extends Component {
    constructor(props) {
        super(props);
    }

    formatAmount = (amount) => {
        amount = amount.split("").reverse().join("");
        if (amount) {
            const digits = amount.length;
            amount = amount.replace(/.{3}/g, '$&,');
            if (digits % 3 === 0)
                amount = amount.substring(0, amount.length-1);
        }
        return amount.split("").reverse().join("");
    }

    render() {
        const { user } = this.props;

        return (
            <Dialog className='deposit-modal wider-modal' fullWidth={true} maxWidth={"xs"} onClose={this.props.handleClose} open={this.props.open}>
                <DialogContent>
                    <div className="block-header">{i18n.t('Withdraw')}</div>
                    <div className="payment-icon"><CreditCardOutlinedIcon /></div>

                    <div className="block-content">
                        <p className="txt-title">From: {user.first_name} {user.last_name}</p>
                        <p className="txt-status">-{this.formatAmount(this.props.withdrawAmount)} ($)</p>
                    </div>

                    <div className="block-content">
                        <p className="text">You are about to remove funds from user {user.first_name} {user.last_name}. Press the <strong>FINISH WITHDRAW</strong> button when you are ready.</p>
                    </div>

                    <Box my={1}>
                        <Button className="btn-print" onClick={this.props.handleBack}>{i18n.t('Back')}</Button>
                        <Button className="btn-print" onClick={this.props.handleFinish}>{i18n.t('Finish withdraw')}</Button>
                    </Box>
                </DialogContent>
            </Dialog>
        )
    }
}

export default WithdrawInfoAdminModal;
