import React, { Component } from 'react';
import { Typography, Grid, Box } from '@material-ui/core';
import { Link } from 'react-router-dom';
import i18n from "../../i18n";
import PropertyBoxComponent from '../../components/PropertyBoxComponent';
import Loader from '../../components/GeneralComponent/Loader';
import "./styles.scss";

class PropertiesMarketplace extends Component {
  render() {
    const pmProperties = this.props.properties;
    const properties = pmProperties?.data || [];

    return (
      <Box>
        <div className='block market-lising-section'>
          {pmProperties && Object.keys(pmProperties).length > 0 ?
            <>
              <h2 className='h2 block-title'>{i18n.t('Explore all Properties on the marketplace')}</h2>

              {properties.some(p => p.property_type === 'direct') && (
                <>
                <h3 className='h3 block-title'>{i18n.t('Explore Direct Sale')}</h3>
                  <Grid container spacing={2}>
                    {properties.filter(p => p.property_type === 'direct').map((property, index) => {
                      return (<Grid key={index} item md={3} sm={6} xs={12}>
                        <PropertyBoxComponent variant={property.property_type} property={property} />
                      </Grid>)
                    })}
                  </Grid>
                </>
              )}

              {properties.some(p => p.property_type === 'secondary') && (
                <>
                  <h3 className='h3 block-title'>{i18n.t('Explore Sale')}</h3>
                  <Grid container spacing={2}>
                    {properties.filter(p => p.property_type === 'secondary').map((property, index) => {
                      return (<Grid key={index} item md={3} sm={6} xs={12}>
                        <PropertyBoxComponent variant={property.property_type} property={property} />
                      </Grid>)
                    })}
                  </Grid>
                </>
              )}

              {properties.some(p => p.property_type === 'primary') && (
                <>
                  <h3 className='h3 block-title'>{i18n.t('Explore Crowdfunding')}</h3>
                  <Grid container spacing={2}>
                    {properties.filter(p => p.property_type === 'primary').map((property, index) => {
                      return (<Grid key={index} item md={3} sm={6} xs={12}>
                        <PropertyBoxComponent variant={property.property_type} property={property} />
                      </Grid>)
                    })}
                  </Grid>
                </>
              )}

              <Typography align='left' variant="body2">
                <Link to="/marketplace" className="show-all-link">{i18n.t('Show all')} ({pmProperties && pmProperties.total ? pmProperties.total : 0}) &gt;</Link>
              </Typography>
            </>
            :
            <>
              <Loader />
              <Typography align='center' variant="body2">{i18n.t("Loading properties...")}</Typography>
            </>
          }
        </div>
      </Box>
    )
  }
}

export { PropertiesMarketplace };
