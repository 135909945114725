import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import { LinearProgress } from '@material-ui/core';
import i18n from "../../i18n";
import "./styles.scss";

const PropertyPledgedBlock = (props) => {
  return (
    <>
      <p className='pledged-info'>{props.property.funding_percentage}% {i18n.t('pledged')} (&#36; {props.property.invested_amount})</p>
      <LinearProgress
        variant="determinate"
        value={parseFloat(props.property.funding_percentage)}
      />
    </>
  )
};

class PropertyFinancialInformation extends Component {
  formatePrice = (val) => {
    if (val !== undefined && val !== null && !isNaN(val)) {
      var parts = val.toString().split(".");
      if (parts.length > 1) {
        val = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".") + (parts[1] ? "," + parts[1] : "");
      }
      val = this.numberWithCommas(val);
      return val;
    }
  }

  numberWithCommas = (x) => {
    if (x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }
    return x;
  }

  formatLastTradedPrice = (val) => {
    if (val !== undefined && val !== null && !isNaN(val)) {
      var preSign = (val > 0) ? '+' : '';
      var parts = val.toString().split(".");
      if (parts.length > 1) {
        val = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".") + (parts[1] ? "," + parts[1] : "");
      }
      return preSign + val;
    }
  }

  lastTradedPriceDiff = (lastTradedPrice, initialPricePerShare) => {
    var sharePriceDiff = lastTradedPrice - initialPricePerShare;
    var sharePricePerDiff = (sharePriceDiff / initialPricePerShare) * 100;
    return sharePricePerDiff.toFixed(2);
  }



  render() {
    const { property } = this.props;

    let lastTradePercent = this.formatLastTradedPrice(this.lastTradedPriceDiff(property.last_price_traded, property.price_per_share))
    let isPositivePercent = property.last_price_traded >= property.price_per_share

    return (
      property && (<div className='property-financial-information'>
        {
          property.property_type === "primary"
          ? (
            <div className="financial-information-primary">
              <p className='title-text'>{i18n.t('Open for Investments')}</p>
              <PropertyPledgedBlock property={property} />

              <table className="financial-table">
                <tbody>
                  <tr>
                    <td>
                      <p className='txt-title'>{i18n.t('Funding Goal')}</p>
                      <p className='txt-value'>&#36; {this.numberWithCommas(property.financing_volume)}</p>
                    </td>
                    <td>
                      <p className='txt-title'>{i18n.t('Shares available')}</p>
                      <p className='txt-value'>{this.formatePrice(property.available_shares)}</p>
                    </td>
                    <td>
                      <p className='txt-title'>{i18n.t('Price per share')}</p>
                      <p className='txt-value'>&#36; {this.formatePrice(property.price_per_share)}</p>
                    </td>
                    <td>
                      <p className='txt-title'>{i18n.t('Annual rental income')}</p>
                      <p className='txt-value'>&#36; {this.formatePrice(property.net_rental_income)}</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )
          : property.property_type === "direct"
          ? (
            <></>
          )
          : (
            <div className="financial-information-secondary">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4} md={4}>
                  <div className="value-block">
                    <p className="txt-value block-1">
                      <span>&#36; {this.formatePrice(property.last_price_traded)}</span>
                      <span className={isPositivePercent ? 'txt-percentage' : 'txt-percentage-red'}>{lastTradePercent} %</span>
                    </p>
                    <p className="txt-title block-1">
                      <span>{i18n.t("Last Price Traded")}</span>
                    </p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <div className="value-block">
                    <p className="txt-value block-2">
                      <span>&#36; {this.formatePrice((property.current_price_per_share * property.total_share_count).toFixed(2))}</span>
                    </p>
                    <p className="txt-title block-2">
                      <span>{i18n.t("Last Market Value")}</span>
                    </p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <div className="value-block">
                    <p className="txt-value block-2">
                      <span>{this.formatePrice(((property.distribution_surplus / (property.current_price_per_share * property.total_share_count)) * 100).toFixed(2))} %</span>
                    </p>
                    <p className="txt-title block-2">
                      <span>{i18n.t("Net rental return")} *</span>
                    </p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <div className="value-block">
                    <p className="txt-value block-3">
                      <span>&#36; {this.formatePrice(property.price_per_share)}</span>
                    </p>
                    <p className="txt-title block-3">
                      <span>{i18n.t("Initial Brick Price")} ({property.property_created_date})</span>
                    </p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <div className="value-block">
                    <p className="txt-value block-3">
                      <span>{property.total_share_count}</span>
                    </p>
                    <p className="txt-title block-3">
                      <span>{i18n.t("Issued Bricks")}</span>
                    </p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <div className="value-block">
                    <p className="txt-value block-3">
                      <span>{this.formatePrice((((property.distribution_surplus / (property.current_price_per_share * property.total_share_count)) * 100).toFixed(2) * 1 + property.predicted_value_increase_p_a * 1).toFixed(2))} %</span>
                    </p>
                    <p className="txt-title block-3">
                      <span>{i18n.t("Return")} *</span>
                    </p>
                  </div>
                </Grid>
              </Grid>
            </div>
          )
        }
      </div>)
    )
  }
}

export default PropertyFinancialInformation;
