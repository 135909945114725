import React, { Component } from 'react';
import { connect } from 'react-redux';
import QRCode from 'qrcode';
import { userActions, alertActions, generalActions } from '../../../_actions';
import { Dialog, DialogContent, Card, CardContent, Grid, Button,
        FormControlLabel, CardHeader, Avatar, Typography, Box } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import CheckboxValidatorElement from '../../../components/CheckboxValidatorElement';
import i18n from "../../../i18n";
import NotificationBox from '../../../components/GeneralComponent/NotificationBox';
import "./styles.scss";
import { generalService } from '../../../_services';
import LinearProgress from '@material-ui/core/LinearProgress';
import utrustLogo from '../../../assets/images/utrust-logo.png';

const MySwal = withReactContent(Swal)

class TradeModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeStep: 1,
            utrustLoader:false,
            utrustPayloadData:{},
            property: {},
            tncCheck: false,
            factsheetCheck: false,
            initialStepSet: false,
            riskCheck: false,
            methods: [1, 0, 0, 0, 0, 0],
            purchaseRefNumber: "",
            order_details: {
                _property_id: 0,
                _order_reference_id: null,
                _user_reference_id: null,
                order_type: "Buy",
                quantity: 0,
                price: 0,
                amount: 0,
                fee: 0,
                fee_amount: 0,
                total_amount: 0,
                payment_method: "account balance",
                order_complete: false
            },
            ibanButton: {
                text: "Copy",
                disabled: false,
            },
            swiftButton: {
                text: "Copy",
                disabled: false,
            },
            refNumberButton: {
                text: "Copy",
                disabled: false,
            },
        }

        this.canvasRef = React.createRef();
    }


    componentDidMount() {
        ValidatorForm.addValidationRule('isTruthy', value => value);
    }
    componentWillUnmount() {
        ValidatorForm.removeValidationRule('isTruthy');
    }


    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.property !== prevState.property) {
            return {
                ...prevState,
                property: nextProps.property,
            }
        }

        if (nextProps.orderDetails && nextProps.orderDetails !== prevState.order_details) {
            return {
                ...prevState,
                activeStep: (nextProps.orderDetails.initialStep && !prevState.initialStepSet) ? nextProps.orderDetails.initialStep : prevState.activeStep,
                initialStepSet: true,
                order_details: {
                    ...prevState.order_details,
                    _order_reference_id: nextProps.orderDetails._order_reference_id,
                    _user_reference_id: nextProps.orderDetails._user_reference_id,
                    _property_id: nextProps.orderDetails._property_id,
                    order_type: nextProps.orderDetails.order_type,
                    quantity: parseInt(nextProps.orderDetails.quantity),
                    price: parseFloat(nextProps.orderDetails.price),
                    amount: nextProps.orderDetails.amount,
                    fee: nextProps.orderDetails.fee,
                    fee_amount: nextProps.orderDetails.fee_amount,
                    total_amount: nextProps.orderDetails.total_amount,
                    order_complete: nextProps.orderDetails.order_complete
                }
            }
        }

        return null;
    }

    setActiveMethod = (method) => {
        let methods = [0, 0, 0, 0, 0, 0];
        methods[method-1] = 1;
        this.setState({ methods });
    }

    isActive = (method) => {
        return this.state.methods[method-1] ? 'active' : '';
    }

    closeModal = () => {
        if (this.state.activeStep === 3 && (this.state.methods[0] || this.state.methods[1])) {
            window.location = "/marketplace";
        }
        this.setState({ activeStep: 1 });
        this.props.handleClose();
    }

    goToNextStep = () => {
        this.form.isFormValid(false).then((isValid) => {
            if (isValid) {
                this.setState(prevState => ({
                    activeStep: prevState.activeStep + 1
                }));
            }
        })
    }

    goToPreviousStep = () => {
        const currentStep = this.state.activeStep;
        this.setState({ activeStep: currentStep - 1 });
    }

    handlePermisionChange = (name) => (event) => {
        this.setState({ [name]: event.target.checked });
    };

    formatePrice = (val) => {
        if (val !== undefined && val !== null && !isNaN(val)) {
            var parts = val.toString().split(".");
            if (parts.length > 1) {
                val = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".") + (parts[1] ? "," + parts[1] : "");
            }
            val = this.numberWithCommas(val)
            return val;
        }
    }

    numberWithCommas = (x) => {
        if (x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        }
        return x;
    }

    generatePurchaseRefNum = () => {
        const options = [
            'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
            '0123456789',
        ];
        const refNumberLen = 6;

        let refNumber = '';
        for(let i = 0; i < refNumberLen; i++) {
            const possibleChars = options[i < 3 ? 0 : 1];
            refNumber += possibleChars[Math.floor(Math.random() * possibleChars.length)];
        }

        return refNumber;
    }


    verifyUserSharesDetails() {
        var propertyId = this.state.order_details._property_id;
        this.props.verifyUserShares(propertyId, this.state.order_details).then(userSharesVerificationRes => {
            if (userSharesVerificationRes && userSharesVerificationRes.status) {
                this.goToNextStep();
                this.processOrder();
            } else {
                this.props.toggleNotification(true, "Not sufficient funds");
                setTimeout(() => this.props.toggleNotification(false), 3000);
            }
        })
    }


    processOrder = async () => {
        const updateProperty = {
            property_id: this.state.order_details._property_id,
            status: "inactive",
        }

        if (this.state.methods[0]) {
            updateProperty["_user_id"] = this.state.order_details._user_reference_id;
            updateProperty["is_promoted"] = 0;
            updateProperty["promoted_transaction_id"] = null;
            updateProperty["promoted_date"] = null;

            const updateUser = {
                user_id: this.props.user.user_id,
                INT_balance: parseInt(this.props.user.INT_balance - this.state.order_details.total_amount),
            }

            await this.props.updateProfile(updateUser);
            await this.props.addUserOrder(this.state.order_details);

            this.setState(prevState => ({
                order_details: {
                    ...prevState.order_details,
                    _order_reference_id: null,
                    _user_reference_id: null,
                    order_type: "Buy",
                    quantity: 0,
                    price: 0,
                    amount: 0,
                    fee: 0,
                    fee_amount: 0,
                    total_amount: 0,
                    payment_method: "account balance",
                    order_complete: false
                },
            }));
        } else if (this.state.methods[1]) {
            this.setState({ purchaseRefNumber: this.generatePurchaseRefNum() });
            updateProperty["_buyer_id"] = this.state.order_details._user_reference_id;
            updateProperty["purchase_ref_number"] = this.state.purchaseRefNumber;
            updateProperty["status"] = "pending-escrow";
        }

        else if (this.state.methods[2]) {
            this.setState({ purchaseRefNumber: this.generatePurchaseRefNum() });
            updateProperty["_buyer_id"] = this.state.order_details._user_reference_id;
            updateProperty["purchase_ref_number"] = this.state.purchaseRefNumber;
            updateProperty["status"] = "pending-utrust";
            this.setState({utrustLoader:true})
        }

        const purchaseRes = await generalService.purchaseProperty(updateProperty);

        if(this.state.methods[2]){
            if(purchaseRes.status){
                setTimeout(() => {
                    window.location.href = purchaseRes.data.attributes.redirect_url;
                }, 4000);
            }
            this.setState({utrustPayloadData:utrustResponse, utrustLoader:false})
        }

        setTimeout(() => this.props.clearAlerts(), 2000);
    }


    submit = async () => {
        // change to go through actions
        const property = await generalService.getProperty(
            this.props.property.property_id
        );
        const available = property.status === "active";

        if (available) {
            this.form.isFormValid(false).then((isValid) => {
                if (isValid && this.state.methods[0]) {
                    this.verifyUserSharesDetails()
                } else if (isValid) {
                    this.goToNextStep();
                    this.processOrder();
                }
            })
        } else {
            this.props.toggleNotification(true, "Property is not available anymore");
            setTimeout(() => { 
                this.props.toggleNotification(false); 
                window.location = "/marketplace"; 
            }, 3000);
        }
    }


    handleSubmit = () => { }

    mapPartnerTypes = (type) => {
        let mappedType = "";
        switch (type) {
            case "escrow":
                mappedType = "Escrow Agent";
                break;
            case "listing":
                mappedType = "Listing Agent";
                break;
            case "other":
                mappedType = "Other";
                break;
        }
        return mappedType;
    }

    isValidEscrowPartner = (partners) => {
        const partner = partners.filter(partner => { 
            if (partner.type === "escrow" && 
                !!partner.email && !!partner.iban && 
                !!partner.swift) {
                return partner;
            } 
        })[0];
        return !!partner;
    }

    generateQRCode = ({ bic, receiverName, iban, depositAmount, paymentRefNum }) => {
        // https://www.qr-code-generator.com/solutions/epc-qr-code/
        // https://en.wikipedia.org/wiki/EPC_QR_code
        const epc_qr_code =
            `BCD` + "\n" +
            `001` + "\n" +
            `1` + "\n" +
            `SCT` + "\n" +
            `${bic}` + "\n" +
            `${receiverName}` + "\n" +
            `${iban}` + "\n" +
            `USD${depositAmount}` + "\n" +
            `CHAR` + "\n" +
            `${paymentRefNum}` + "\n" +
            ``;
        if (this.canvasRef.current) {
            QRCode.toCanvas(this.canvasRef.current, epc_qr_code, function (error) {
                if (error) console.error(error)
                console.log('success!');
            });
        }   
    }

    showEscrowPartner = (partners) => {
        const partner = partners.filter(partner => { 
            if (partner.type === "escrow" && 
                !!partner.email && !!partner.iban && 
                !!partner.swift) {
                return partner;
            } 
        })[0];
        setTimeout(() => {
            this.generateQRCode({
                receiverName: partner.name,
                bic: partner.swift,
                iban: partner.iban,
                paymentRefNum: this.state.purchaseRefNumber,
                depositAmount: this.state.order_details.amount
            })
        }, 100);
        return (
            <Card key={`card-${partner.email}`} className="card-partner">
                <CardHeader
                    avatar={
                        <Avatar alt={partner.name} src={partner.logo} />
                    }
                    title={partner.name}
                    subheader={this.mapPartnerTypes(partner.type)}
                />
                <CardContent>
                    <div className='qr-canvas'>
                        <canvas ref={this.canvasRef}></canvas>
                    </div>  
                    <Box sx={{ mb: 2, display: "flex", justifyContent: "space-between" }}>
                        <Box>
                            <Typography id="iban_num" variant="body1" color="textPrimary" component="p">
                                {partner.iban}
                            </Typography>
                            <Typography
                                variant="body2"
                                color="textSecondary"
                                component="p"
                                className="text-title"
                            >
                                IBAN
                            </Typography>
                        </Box>
                        <Box>
                            <Button
                                name='btn-iban' 
                                className='btn-copy' 
                                onClick={this.copyToClipboard}
                                disabled={this.state.ibanButton.disabled}
                            >
                                {this.state.ibanButton.text}
                            </Button>
                        </Box>
                    </Box>
                    <Box sx={{ mb: 2, display: "flex", justifyContent: "space-between" }}>
                        <Box>
                            <Typography id="swift_num" variant="body1" color="textPrimary" component="p">
                                {partner.swift}
                            </Typography>
                            <Typography
                                variant="body2"
                                color="textSecondary"
                                component="p"
                                className="text-title"
                            >
                                SWIFT/BIC CODE
                            </Typography>
                        </Box>
                        <Box>
                            <Button
                                name='btn-swift' 
                                className='btn-copy' 
                                onClick={this.copyToClipboard}
                                disabled={this.state.swiftButton.disabled}
                            >
                                {this.state.swiftButton.text}
                            </Button>
                        </Box>
                    </Box>
                    <Box sx={{ mb: 1, display: "flex", justifyContent: "space-between" }}>
                        <Box>
                            <Typography id="ref_num" variant="body1" color="textPrimary" component="p">
                                {this.state.purchaseRefNumber}
                            </Typography>
                            <Typography
                                variant="body2"
                                color="textSecondary"
                                component="p"
                                className="text-title"
                            >
                                Purchase Reference Number
                            </Typography>
                        </Box>
                        <Box>
                            <Button
                                name='btn-number' 
                                className='btn-copy' 
                                onClick={this.copyToClipboard}
                                disabled={this.state.refNumberButton.disabled}
                            >
                                {this.state.refNumberButton.text}
                            </Button>
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        );
    }

    copyToClipboard = (event) => {
        let element = "";
        const update = {};
        if (event.currentTarget.name === "btn-iban") {
            element = document.getElementById("iban_num");
            update["ibanButton"] = {
                text: "Copied!",
                disabled: true,
            };
        } else if (event.currentTarget.name === "btn-swift") {
            element = document.getElementById("swift_num");
            update["swiftButton"] = {
                text: "Copied!",
                disabled: true,
            };
        } else if (event.currentTarget.name === "btn-number") {
            element = document.getElementById("ref_num");
            update["refNumberButton"] = {
                text: "Copied!",
                disabled: true,
            };
        }

        const range = document.createRange();
        range.selectNode(element);
        window.getSelection().removeAllRanges(); // clear current selection
        window.getSelection().addRange(range); // to select text
        document.execCommand("copy");
        window.getSelection().removeAllRanges();// to deselect

        this.setState({...update});
        Object.keys(update).forEach((key) => {
            update[key] = {
                text: "Copy",
                disabled: false,
            };
        });

        setTimeout(() => this.setState({ ...update }), 2000);
    }

    render() {
        const { activeStep, property, order_details } = this.state;
        const { alert } = this.props;
        const partners = property.property_project_partners.map(data => data.partner_master);

        return (
            <Dialog className='trade-modal' onClose={this.closeModal} open={this.props.open}>
                <DialogContent className='trade-modal-content'>
                    {alert.message && <NotificationBox open={true} variant={alert.type} message={alert.message} />}
                    <Button className="btn-modal-close" onClick={this.closeModal}><CloseIcon /></Button>
                    <ValidatorForm
                        className='trade-buy-form'
                        onSubmit={this.handleSubmit}
                        ref={(r) => { this.form = r; }}
                        autoComplete="off"
                        instantValidate
                    >
                        <h2 className="modal-title">{i18n.t('Property Purchase')} </h2>
                        {property && <div className="title-box">
                            <div>
                                {property.property_images && property.property_images.length > 0 && (
                                    <img src={property.property_images[0].image_path} className="property-img" alt="Property" />
                                )}
                            </div>
                            <div className="assets-box">
                                <p className="txt-asset-title">{property.title}</p>
                                <p className="txt-asset-symbol">{property.token_name}</p>
                            </div>
                        </div>}
                        {property && <div className="content-box">
                            {activeStep === 1 && (<div className="step-wizard block-1">
                                <div className="payment-method-box">
                                    <p className="title-text">{i18n.t('Payment method')}</p>

                                    <Grid container spacing={4}>
                                        <Grid item md={4} xs={12}>
                                            <Card className={`payment-type ${this.isActive(1)}`} onClick={() => this.setActiveMethod(1)}>
                                                <AccountBalanceIcon />
                                                <p>{i18n.t('Account balance')}</p>
                                            </Card>
                                        </Grid>
                                        <Grid item md={4} xs={12}>
                                            {this.isValidEscrowPartner(partners) ? (
                                                <Card className={`payment-type ${this.isActive(2)}`} onClick={() => this.setActiveMethod(2)}>
                                                    <AttachMoneyIcon />
                                                    <p>{i18n.t('Transfer to Escrow')}</p>
                                                </Card> 
                                            ) : (
                                                <Card className={`payment-type disabled`}>
                                                    <AttachMoneyIcon />
                                                    <p>{i18n.t('Transfer to Escrow')}</p>
                                                </Card>
                                            )}
                                        </Grid>
                                        <Grid item md={4} xs={12}>
                                            <Card className={`payment-type ${this.isActive(3)}`} onClick={() => this.setActiveMethod(3)}>
                                                <img src={utrustLogo} className="utrust-logo" alt="Property" />
                                                <p>{i18n.t('Pay with Crypto')}</p>
                                            </Card>
                                        </Grid>
                                        <Grid item md={4} xs={12}>
                                            <Card className="payment-type disabled">
                                                <div className="coming-soon-title">{i18n.t('Coming soon')}</div>
                                                <p>{i18n.t('Direct debit')}</p>
                                            </Card>
                                        </Grid>
                                        <Grid item md={4} xs={12}>
                                            <Card className="payment-type disabled">
                                                <div className="coming-soon-title">{i18n.t('Coming soon')}</div>
                                                <p>{i18n.t('Google pay')}</p>
                                            </Card>
                                        </Grid>
                                        <Grid item md={4} xs={12}>
                                            <Card className="payment-type disabled">
                                                <div className="coming-soon-title">{i18n.t('Coming soon')}</div>
                                                <p>{i18n.t('Apple pay')}</p>
                                            </Card>
                                        </Grid>
                                    </Grid>
                                </div>

                                <Button fullWidth className="btn-action" variant="contained" onClick={() => this.goToNextStep()}>{i18n.t('Next')}</Button>
                            </div>)}

                            {activeStep === 2 && this.isActive(1) && (<div className="step-wizard block-2">
                                <Card className="card-box">
                                    <CardContent>
                                        {(order_details.order_type === "Buy" || order_details.order_type === "Kaufe") ?
                                            <p>{i18n.t("Review details of your investment")}</p>
                                            :
                                            <p>{i18n.t("Review of your sell order")}</p>
                                        }

                                        <table className="table details-table">
                                            <tbody>
                                                <tr>
                                                    <td>{i18n.t("Property price")}</td>
                                                    <td>&#36; {this.formatePrice(order_details.amount)}</td>
                                                </tr>
                                                <tr>
                                                    <td>{i18n.t("Fee")} {this.formatePrice(order_details.fee)}%</td>
                                                    <td>&#36; {this.formatePrice(order_details.fee_amount)}</td>
                                                </tr>
                                                <tr>
                                                {(order_details.order_type == "Buy"  || order_details.order_type === "Kaufe") ?
                                                    <td>{i18n.t("Total Amount")}</td>
                                                :
                                                    <td>{i18n.t("Sales Proceeds")}</td>
                                                }


                                                    <td>&#36;  {this.formatePrice(order_details.total_amount)}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </CardContent>
                                </Card>

                                {(order_details.order_type == "Buy"  || order_details.order_type === "Kaufe") && (
                                <Card className="card-box">
                                    <CardContent>
                                        <p>{i18n.t("Contract Documents & Conditions")}</p>
                                        {(property && property.property_documents && property.property_documents.length > 0) && (
                                            <table className="table downloads-table">
                                                <tbody>
                                                    {property.property_documents.map((document, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td width="30"><SaveAltIcon /></td>
                                                                <td>
                                                                    <a href={`${process.env.REACT_APP_API_URL}/properties/download/${document.file_path}`} download
                                                                    ><span className="doc-name">{document.description}</span></a>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>)}

                                        <div className="acceptance-box">
                                            <FormControlLabel
                                                control={
                                                    <CheckboxValidatorElement
                                                        onChange={this.handlePermisionChange('tncCheck')}
                                                        validators={['isTruthy']}
                                                        errorMessages={[i18n.t('Please accept Terms and Conditions to continue')]}
                                                        checked={this.state.tncCheck}
                                                        value={this.state.tncCheck}
                                                    />
                                                }
                                                label={<TnCLabel />}
                                            />

                                            <FormControlLabel
                                                control={
                                                    <CheckboxValidatorElement
                                                        checked={this.state.factsheetCheck}
                                                        onChange={this.handlePermisionChange('factsheetCheck')}
                                                        validators={['isTruthy']}
                                                        errorMessages={[i18n.t('Please accept contract / cancellation instruction to continue')]}
                                                        value={this.state.factsheetCheck}
                                                    />
                                                }
                                                label={<FactSheetLabel />}
                                            />

                                            <FormControlLabel
                                                control={
                                                    <CheckboxValidatorElement
                                                        checked={this.state.riskCheck}
                                                        onChange={this.handlePermisionChange('riskCheck')}
                                                        validators={['isTruthy']}
                                                        errorMessages={[i18n.t('Please risk to continue')]}
                                                        value={this.state.riskCheck}
                                                    />
                                                }
                                                label={<RiskLabel />}
                                            />
                                        </div>
                                    </CardContent>
                                </Card>
                                )}

                                <Button fullWidth className="btn-action" variant="contained" onClick={() => this.submit()}>
                                            {i18n.t("Purchase Property")}
                                    </Button>
                            </div>)}


                            {activeStep === 2 && this.isActive(2) && (<div className="step-wizard block-2 escrow-modal">
                                <div className="block-content">
                                    <p className="txt-status">&#36; {this.formatePrice(order_details.amount)}</p>
                                </div>

                                <Card className="card-box">
                                    <CardContent>

                                        <div className="block-content">
                                            <h3>Title message</h3>
                                            <p className="text">
                                                To make payments in US Dollars, please transfer your funds to designated Escrow Service Provider - as per specification stated below.
                                                <br /><br />
                                                Please make sure to enter the Payment Reference exactly as it appears below, as that helps us automate the allocation of funds.
                                                <br /><br />
                                                In case of doubt or if you need more information, do reach out to our support team using either Telegram chat or Email: support@immobilium.io
                                            </p>
                                        </div>

                                    </CardContent>
                                </Card>

                                <Box sx={{ my: 5 }}>
                                    <Typography variant="h5" component="h5">Partners</Typography>
                                    {partners.map((partner) =>
                                        <Card key={`card-${partner.email}`} className="card-partner">
                                            <CardHeader
                                                avatar={
                                                    <Avatar alt={partner.name} src={partner.logo} />
                                                }
                                                title={partner.name}
                                                subheader={this.mapPartnerTypes(partner.type)}
                                            />
                                        </Card>
                                    )}
                                </Box>

                                {(order_details.order_type == "Buy"  || order_details.order_type === "Kaufe") && (
                                <Card className="card-box">
                                    <CardContent>
                                        <p>{i18n.t("Contract Documents & Conditions")}</p>
                                        {(property && property.property_documents && property.property_documents.length > 0) && (
                                            <table className="table downloads-table">
                                                <tbody>
                                                    {property.property_documents.map((document, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td width="30"><SaveAltIcon /></td>
                                                                <td>
                                                                    <a href={`${process.env.REACT_APP_API_URL}/properties/download/${document.file_path}`} download
                                                                    ><span className="doc-name">{document.description}</span></a>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>)}

                                        <div className="acceptance-box">
                                            <FormControlLabel
                                                control={
                                                    <CheckboxValidatorElement
                                                        onChange={this.handlePermisionChange('tncCheck')}
                                                        validators={['isTruthy']}
                                                        errorMessages={[i18n.t('Please accept Terms and Conditions to continue')]}
                                                        checked={this.state.tncCheck}
                                                        value={this.state.tncCheck}
                                                    />
                                                }
                                                label={<TnCLabel />}
                                            />

                                            <FormControlLabel
                                                control={
                                                    <CheckboxValidatorElement
                                                        checked={this.state.factsheetCheck}
                                                        onChange={this.handlePermisionChange('factsheetCheck')}
                                                        validators={['isTruthy']}
                                                        errorMessages={[i18n.t('Please accept contract / cancellation instruction to continue')]}
                                                        value={this.state.factsheetCheck}
                                                    />
                                                }
                                                label={<FactSheetLabel />}
                                            />

                                            <FormControlLabel
                                                control={
                                                    <CheckboxValidatorElement
                                                        checked={this.state.riskCheck}
                                                        onChange={this.handlePermisionChange('riskCheck')}
                                                        validators={['isTruthy']}
                                                        errorMessages={[i18n.t('Please risk to continue')]}
                                                        value={this.state.riskCheck}
                                                    />
                                                }
                                                label={<RiskLabel />}
                                            />
                                        </div>
                                    </CardContent>
                                </Card>
                                )}

                                <Button fullWidth className="btn-action" variant="contained" onClick={() => this.submit()}>
                                    {i18n.t("Purchase Property")}
                                </Button>
                            </div>)}

                            {activeStep === 2 && this.isActive(3) && (<div className="step-wizard block-2 escrow-modal">
                                <div className="block-content">
                                    <p className="txt-status">&#36; {this.formatePrice(order_details.amount)}</p>
                                </div>

                                <Card className="card-box">
                                    <CardContent>

                                        <div className="block-content">
                                            <h3>Utrust — Pay with Cryptocurrencies</h3>
                                            <p className="text">
                                                You can pay for this property using some of the most popular cryptocurrencies. We use a third party service provider for cryptocurrency payments processing - <a href="https://utrust.com/" target="_blank">Utrust</a>.
                                                <br /><br />
                                                As this is a third party service, we cannot guarantee the safety of your funds nor for timely execution of your payment orders. Please make sure to conduct your own due diligence of Utrust as a cryptocurrency service provider and proceed at your own risk and responsibility.
                                            </p>
                                        </div>

                                    </CardContent>
                                </Card>

                                {(order_details.order_type == "Buy"  || order_details.order_type === "Kaufe") && (
                                <Card className="card-box">
                                    <CardContent>
                                        <p>{i18n.t("Contract Documents & Conditions")}</p>
                                        {(property && property.property_documents && property.property_documents.length > 0) && (
                                            <table className="table downloads-table">
                                                <tbody>
                                                    {property.property_documents.map((document, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td width="30"><SaveAltIcon /></td>
                                                                <td>
                                                                    <a href={`${process.env.REACT_APP_API_URL}/properties/download/${document.file_path}`} download
                                                                    ><span className="doc-name">{document.description}</span></a>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>)}

                                        <div className="acceptance-box">
                                            <FormControlLabel
                                                control={
                                                    <CheckboxValidatorElement
                                                        onChange={this.handlePermisionChange('tncCheck')}
                                                        validators={['isTruthy']}
                                                        errorMessages={[i18n.t('Please accept Terms and Conditions to continue')]}
                                                        checked={this.state.tncCheck}
                                                        value={this.state.tncCheck}
                                                    />
                                                }
                                                label={<TnCLabel />}
                                            />

                                            <FormControlLabel
                                                control={
                                                    <CheckboxValidatorElement
                                                        checked={this.state.factsheetCheck}
                                                        onChange={this.handlePermisionChange('factsheetCheck')}
                                                        validators={['isTruthy']}
                                                        errorMessages={[i18n.t('Please accept contract / cancellation instruction to continue')]}
                                                        value={this.state.factsheetCheck}
                                                    />
                                                }
                                                label={<FactSheetLabel />}
                                            />

                                            <FormControlLabel
                                                control={
                                                    <CheckboxValidatorElement
                                                        checked={this.state.riskCheck}
                                                        onChange={this.handlePermisionChange('riskCheck')}
                                                        validators={['isTruthy']}
                                                        errorMessages={[i18n.t('Please risk to continue')]}
                                                        value={this.state.riskCheck}
                                                    />
                                                }
                                                label={<RiskLabel />}
                                            />
                                        </div>
                                    </CardContent>
                                </Card>
                                )}

                                <Button fullWidth className="btn-action" variant="contained" onClick={() => this.submit()}>
                                    {i18n.t("Purchase Property")}
                                </Button>
                            </div>)}

                            {(activeStep === 3 && !this.isActive(3)) && (<div className={"step-wizard block-3 " + (this.state.methods[1] && "escrow-modal")}>
                                <Card className="card-box">
                                    <CardContent>
                                        {this.state.methods[1] ? (
                                            <p className="txt-style-1">{i18n.t("You need to transfer the funds now!")}</p>
                                        ) : (
                                            <p className="txt-style-1">{i18n.t("Congratulations on property purchase!")}</p>
                                        )}
                                        {this.state.methods[1] ? (
                                            <p className="txt-style-2">Your purchase reference number is: <strong>{this.state.purchaseRefNumber}</strong>. When you transfer the funds the transaction will be completed. If the transfer isn't done in 2 days, the property will be put back on market.</p>
                                        ) : (
                                            <p className="txt-style-2">{i18n.t("You can track all your investments your dashboard.")}</p>
                                        )}
                                    </CardContent>
                                </Card>
                                
                                {this.state.methods[1] ? this.showEscrowPartner(partners) : ""}

                                <Link to="/dashboard"><Button fullWidth className="btn-action" variant="contained">{i18n.t("Go to dashboard")}</Button></Link>
                            </div>)}

                            {(activeStep === 3 && this.isActive(3) ) && (
                            <div className="step-wizard block-3">
                                <Card className="card-box">
                                    <CardContent>

                                {this.state.utrustLoader?<>
                                    <p>Your order is being created, please wait...</p>
                                    <LinearProgress />
                                </>:<>
                                <p>{this.state.utrustPayloadData.message}</p>
                                </>}
                                    </CardContent>
                                </Card>
                                <img src={utrustLogo} className="utrust-logo-step-wizard" alt="Property" />
                            </div>)}

                        </div>}
                    </ValidatorForm>
                </DialogContent>
            </Dialog>
        );
    }
}

const TnCLabel = () => {
    return (
        <p>{i18n.t("I have read the")} <a href="http://www.africau.edu/images/default/sample.pdf" target="_blank" rel="noopener noreferrer">{i18n.t("terms and conditions")}</a> {i18n.t("of ABCD1234 GmBH and accept them hereby.")}</p>
    )
}

const FactSheetLabel = () => {
    return (
        <p>{i18n.t("I am taking note of that")} <a href="http://www.africau.edu/images/default/sample.pdf" target="_blank" rel="noopener noreferrer">{i18n.t("contract / cancellation")}</a> {i18n.t("instruction and the")} <a href="http://www.africau.edu/images/default/sample.pdf" target="_blank" rel="noopener noreferrer">{i18n.t("Fact Sheet")}</a> {i18n.t("and accpet them.")}</p>
    )
}

const RiskLabel = () => {
    return (
        <p>{i18n.t("I am aware of the")} <a href="http://www.africau.edu/images/default/sample.pdf" target="_blank" rel="noopener noreferrer">{i18n.t("risk")}</a>.</p>
    )
}



function mapState(state) {
    const { alert } = state;
    return { alert };
}


const actionCreators = {
    addUserOrder: userActions.addUserOrder,
    updatePropertyValues: generalActions.updatePropertyValues,
    updateProfile: userActions.updateProfile,
    verifyUserShares: userActions.verifyUserShares,
    clearAlerts: alertActions.clear,
}

export default connect(mapState, actionCreators)(TradeModal);
