import React, { Component } from 'react';
import { Grid, Container, Select, FormControl, MenuItem, Button, Paper } from '@material-ui/core';
import { Link } from "react-router-dom";
import InputRange from 'react-input-range';
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import i18n from "../../i18n";
import { Popper, Grow, InputAdornment } from "@material-ui/core";
import "react-input-range/lib/css/index.css";
import main_bg_image from '../../assets/images/main-bg-image.jpg';
import "./styles.scss";

const TypeLabels = {
  direct: 'Direct Sale',
  secondary: 'Sale',
  primary: 'Crowdfunding',
};

class HeroBanner extends Component {
  constructor(props) {
    super(props);

    this.search_filter = React.createRef();

    this.state = {
      searchFilterOpen: false,
      propertyValueRange: { min: 0, max: 10 },
      search: {
        location: [],
        type: [],
        status: "",
        propertyValue: { min: 0, max: 330000 },
        pricePerShareValue: { min: 0, max: 340 },
        dividendYieldValue: { min: 0, max: 4.2 },
        "order": "",
        "availability": false,
      },
      locations: [],
      properties: {}
    }
  }

  componentDidMount() {
    ValidatorForm.addValidationRule('belowThan', value => {
      return value < this.state.propertyValueRange.max;
    });

    ValidatorForm.addValidationRule('aboveThan', value => {
      return value > this.state.propertyValueRange.min;
    });
  }

  componentWillUnmount() {
    ValidatorForm.removeValidationRule('belowThan');
    ValidatorForm.removeValidationRule('aboveThan');
  }

  componentWillReceiveProps(props) {
    const { properties, locations } = props;
    if (properties && properties !== this.state.properties) {
      this.setState({
        ...this.state,
        properties: properties,
        search: {
          ...this.state.search,
          'propertyValue': {
            min: 0,
            max: properties.max_property_value ? properties.max_property_value : 0
          },
          'pricePerShareValue': {
            min: 0,
            max: properties.max_price_per_share ? properties.max_price_per_share : 0
          },
          'dividendYieldValue': {
            min: 0,
            max: properties.max_dividend_yield ? properties.max_dividend_yield : 0
          }
        },
        propertyValueRange: {
          min: 0,
          max: properties.max_property_value ? properties.max_property_value : 0
        }
      });
    }

    if (locations) {
      this.setState({
        ...this.state,
        locations: locations
      })
    }

    return null;
  }


  toggleSearchFilter = () => {
    this.setState({ searchFilterOpen: !this.state.searchFilterOpen });
  }

  setFilter = (value, type) => {
    this.setState(prevState => ({
      search: {
        ...prevState.search,
        [type]: value
      }
    }));
  }

  handleChange = (event) => {
    const { search } = this.state;
    search[event.target.name] = event.target.value;
    this.setState({ search });
  }

  formatePrice(price) {
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }

  render() {
    const { search, locations } = this.state;

    return (
      <div className='hero-banner' style={{ backgroundImage: (this.props.background != '' ? `url(${this.props.background})` : `url(${main_bg_image})`) }}>
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <div className="search-form">
                <p className='search-title'>Immobilium Real Estate Sales and Crowd Investing Platform. Backed by the 0bsnetwork Blockchain For The Real World.</p>

                <FormControl className='form-control'>
                  <Select
                    multiple
                    inputProps={{
                      name: 'location',
                      id: 'search-location'
                    }}
                    disableUnderline
                    value={search.location}
                    onChange={this.handleChange}
                    displayEmpty
                    renderValue={selected => {
                      if (selected.length === 0) {
                        return <span>{i18n.t('Location')}</span>;
                      }

                      return selected.join(', ');
                    }}
                  >
                    <MenuItem value="" disabled>{i18n.t('Location')}</MenuItem>
                    {locations.length > 0 && locations.map((city, index) => (
                      <MenuItem key={index} value={city.city_name}>
                        {city.city_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl className='form-control'>
                  <Button variant="outlined" className="btn-filter" ref={this.search_filter} onClick={this.toggleSearchFilter} >{i18n.t('Value')}</Button>

                  <Popper className="filter-box" open={this.state.searchFilterOpen} anchorEl={this.search_filter.current} role={undefined} transition disablePortal placement={"bottom-start"}>
                    {({ TransitionProps }) => (
                      <Grow
                        {...TransitionProps}
                      >
                        <Paper className="filter-content">
                          <p className="txt-title">{i18n.t('Value')}</p>

                          <div className="range-slider">
                            <p className="range-value">
                              <ValidatorForm
                                onSubmit={() => {}}
                                autoComplete="off"
                                instantValidate
                              >
                                <TextValidator
                                  fullWidth
                                  value={this.state.search.propertyValue.min}
                                  onChange={(e) => {
                                    const min = parseInt(e.target.value) || this.state.propertyValueRange.min;
                                    const max = this.state.search.propertyValue.max;
                                    min >= this.state.propertyValueRange.min && min <= max && this.setFilter({ min, max }, 'propertyValue');
                                  }}
                                  InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                  }}
                                  min={this.state.propertyValueRange.min}
                                  max={this.state.search.propertyValue.max}
                                  type="number"
                                  label="Min *"
                                  validators={["required", "isNumber", "aboveThan"]}
                                  errorMessages={["This field is required", ""]}
                                />
                                <TextValidator
                                  fullWidth
                                  value={this.state.search.propertyValue.max}
                                  onChange={(e) => {
                                    const min = this.state.search.propertyValue.min;
                                    const max = parseInt(e.target.value) || 0;
                                    max >= min && max <= this.state.propertyValueRange.max && this.setFilter({ min, max }, 'propertyValue');
                                  }}
                                  InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                  }}
                                  min={this.state.search.propertyValue.min}
                                  max={this.state.propertyValueRange.max}
                                  type="number"
                                  label="Max *"
                                  validators={["required", "isNumber", "belowThan"]}
                                />
                              </ValidatorForm>
                            </p>
                          </div>

                          <div className="action-footer">
                            <Button className="btn-delete" onClick={this.toggleSearchFilter}>{i18n.t("Delete")}</Button>
                            <Button className="btn-save" onClick={this.toggleSearchFilter}>{i18n.t("Save")}</Button>
                          </div>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </FormControl>

                <FormControl className='form-control double-width'>
                  <Select
                    multiple
                    inputProps={{
                      name: 'type',
                      id: 'search-type'
                    }}
                    disableUnderline
                    value={search.type}
                    onChange={this.handleChange}
                    displayEmpty
                    renderValue={selected => {
                      if (selected.length === 0) {
                        return <span>{i18n.t('Type of Property')}</span>;
                      }

                      return selected.map(type => TypeLabels[type]).join(', ');
                    }}
                  >
                    <MenuItem value="" disabled>{i18n.t('Type of Property')}</MenuItem>
                    <MenuItem value="direct">Direct Sale</MenuItem>
                    <MenuItem value="secondary">Sale</MenuItem>
                    <MenuItem value="primary">Crowdfunding</MenuItem>
                  </Select>
                </FormControl>

                <div style={{ textAlign: 'right', marginRight: '10px', marginTop: '10px' }}>
                  <Link
                    to='/marketplace'
                    state={{ searchInvestment: this.state.search }}
                  >
                    <Button variant="contained" className="btn-primary btn-filled-out">{i18n.t('Search')}</Button>
                  </Link>
                </div>
                <p style={{ marginTop: 20, marginBottom: -10 }}>Built by Blockstruct GmbH</p>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    )
  }
}

export { HeroBanner };
