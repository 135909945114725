import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, CardContent, Grid, Box, MenuItem, FormControl, Select } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import TinyMCE from 'react-tinymce';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import NotificationBox from './../../components/GeneralComponent/NotificationBox';
import {
  LanguageTabEn,
  LanguageTabGn,
  a11yProps,
  TabPanel,
} from '../../components/GeneralComponent/LanguageTabs';
import { generalActions, alertActions } from '../../_actions';
import { NavBarComponent as DasboardNavBarComponent } from '../../components/DashboardComponent/common/NavBarComponent';
import { withRouter } from '../../_helpers';
import './styles.scss';

class AddPartnerPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      partner: {
        name_en: '',
        name_de: '',
        type: 'escrow',
        email: '',
        iban: '',
        swift: '',
        description_en: '',
        description_de: '',
        partner_logoPreview: '',
        partner_logo: '',
      },
      tab_value: 0,
    };
    this.props.clearAlerts();
  }

  handleChange = (inputType) => (event) => {
    var fieldName =
      inputType === 'input-text' ? event.target.name : event.target.id;
    var fieldValue =
      inputType === 'input-text'
        ? event.target.value
        : event.target.getContent();
    this.setState((prevState) => ({
      partner: {
        ...prevState.partner,
        [fieldName]: fieldValue,
      },
    }));
  };

  handleTabChange = (event, newValue) => {
    this.setState({
      tab_value: newValue,
    });
  };

  handleChangeSelect = (event) => {
    this.setState({ 
      partner: {
        ...this.state.partner,
        type: event.target.value,
      },
    });
  };

  handleSubmit = () => {
    this.props.addPartner(this.state.partner).then((value) => {
      setTimeout(() => {
        this.props.clearAlerts()
        this.props.navigate("/dashboard/partners")
    }, 2000);
    });
  };

  handleCapture = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      this.setState((prevState) => ({
        partner: {
          ...prevState.partner,
          partner_logo: file,
          partner_logoPreview: reader.result,
        },
      }));
    };
    reader.readAsDataURL(file);
  };

  selectPartnerLogo = () => {
    document.getElementById('partner_logo').click();
  };

  render() {
    const { partner, tab_value } = this.state;
    const { alert } = this.props;

    return (
      <div className="main-content add-partner-page">
          <DasboardNavBarComponent pageName="manage-partner" />
        <Grid container>
          <Grid item md={8} xs={12}>
            <Card className="default-card">
              <CardContent>
                {alert.message && (
                  <NotificationBox
                    open={true}
                    variant={alert.type}
                    message={alert.message}
                  />
                )}
                <ValidatorForm
                  className="add-partner-form partner-form default-form"
                  onSubmit={this.handleSubmit}
                  ref="form"
                  autoComplete="off"
                >
                  <Grid container spacing={2}>
                    <Grid item md={12} xs={12}>
                      <Paper square>
                        <Tabs
                          value={tab_value}
                          className="tabs-container"
                          onChange={this.handleTabChange}
                          indicatorColor="primary"
                          textColor="primary"
                          variant="fullWidth"
                          aria-label="full width tabs example"
                        >
                          <Tab label={<LanguageTabEn />} {...a11yProps(0)} />
                          <Tab label={<LanguageTabGn />} {...a11yProps(1)} />
                        </Tabs>
                      </Paper>
                      <TabPanel
                        value={tab_value}
                        index={0}
                        className="tab-panel"
                      >
                        <Grid container spacing={2}>
                          <Grid item md={12} xs={12}>
                            <label className="form-label">Type</label>
                            <FormControl variant="outlined" fullWidth>
                              <Select
                                id="demo-simple-select-outlined"
                                value={partner.type}
                                onChange={this.handleChangeSelect}
                              >
                                <MenuItem value="" disabled>
                                  Select partner type
                                </MenuItem>
                                <MenuItem value={"escrow"}>Escrow agent</MenuItem>
                                <MenuItem value={"listing"}>Listing agent</MenuItem>
                                <MenuItem value={"other"}>Other</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item md={12} xs={12}>
                            <label className="form-label">Name *</label>
                            <TextValidator
                              fullWidth
                              value={partner.name_en}
                              onChange={this.handleChange('input-text')}
                              id="text-name"
                              name="name_en"
                              className="form-control"
                              placeholder="enter name"
                              variant="outlined"
                              validators={['required']}
                              errorMessages={['This field is required']}
                            />
                          </Grid>
                          <Grid item md={12} xs={12}>
                            <label className="form-label">Email</label>
                            <TextValidator
                              fullWidth
                              value={partner.email}
                              onChange={this.handleChange('input-text')}
                              id="text-email"
                              name="email"
                              className="form-control"
                              placeholder="enter email"
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item md={12} xs={12}>
                            <label className="form-label">IBAN</label>
                            <TextValidator
                              fullWidth
                              value={partner.iban}
                              onChange={this.handleChange('input-text')}
                              id="text-iban"
                              name="iban"
                              className="form-control"
                              placeholder="enter iban"
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item md={12} xs={12}>
                            <label className="form-label">SWIFT/BIC code</label>
                            <TextValidator
                              fullWidth
                              value={partner.swift}
                              onChange={this.handleChange('input-text')}
                              id="text-swift"
                              name="swift"
                              className="form-control"
                              placeholder="enter swift"
                              variant="outlined"    
                            />
                          </Grid>
                          <Grid item md={12} xs={12}>
                            <label className="form-label">Description</label>
                            <TinyMCE
                              id="description_en"
                              name="description_en"
                              content={partner.description_en}
                              config={{
                                plugins:
                                  'autolink link image lists print preview',
                                toolbar:
                                  'undo redo | bold italic | alignleft aligncenter alignright | styleselect',
                                menubar: false,
                                preview_styles: false,
                                branding: false,
                              }}
                              onChange={this.handleChange('input-editor')}
                            />
                          </Grid>
                        </Grid>
                      </TabPanel>
                      <TabPanel
                        value={tab_value}
                        index={1}
                        className="tab-panel"
                      >
                        <Grid container spacing={2}>
                          <Grid item md={12} xs={12}>
                            <label className="form-label">Type</label>
                            <FormControl variant="outlined" fullWidth>
                              <Select
                                id="demo-simple-select-outlined"
                                value={partner.type}
                                onChange={this.handleChangeSelect}
                              >
                                <MenuItem value="" disabled>
                                  Select partner type
                                </MenuItem>
                                <MenuItem value={"escrow"}>Escrow agent</MenuItem>
                                <MenuItem value={"listing"}>Listing agent</MenuItem>
                                <MenuItem value={"other"}>Other</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item md={12} xs={12}>
                            <label className="form-label">Name *</label>
                            <TextValidator
                              fullWidth
                              value={partner.name_de}
                              onChange={this.handleChange('input-text')}
                              id="text-name"
                              name="name_de"
                              className="form-control"
                              placeholder="enter name"
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item md={12} xs={12}>
                            <label className="form-label">Email</label>
                            <TextValidator
                              fullWidth
                              value={partner.email}
                              onChange={this.handleChange('input-text')}
                              id="text-email"
                              name="email"
                              className="form-control"
                              placeholder="enter email"
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item md={12} xs={12}>
                            <label className="form-label">IBAN</label>
                            <TextValidator
                              fullWidth
                              value={partner.iban}
                              onChange={this.handleChange('input-text')}
                              id="text-iban"
                              name="iban"
                              className="form-control"
                              placeholder="enter iban"
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item md={12} xs={12}>
                            <label className="form-label">SWIFT/BIC code</label>
                            <TextValidator
                              fullWidth
                              value={partner.swift}
                              onChange={this.handleChange('input-text')}
                              id="text-swift"
                              name="swift"
                              className="form-control"
                              placeholder="enter swift"
                              variant="outlined"    
                            />
                          </Grid>
                          <Grid item md={12} xs={12}>
                            <label className="form-label">Description</label>
                            <TinyMCE
                              id="description_de"
                              name="description_de"
                              content={partner.description_de}
                              config={{
                                plugins:
                                  'autolink link image lists print preview',
                                toolbar:
                                  'undo redo | bold italic | alignleft aligncenter alignright | styleselect',
                                menubar: false,
                                preview_styles: false,
                                branding: false,
                              }}
                              onChange={this.handleChange('input-editor')}
                            />
                          </Grid>
                        </Grid>
                      </TabPanel>
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <Button variant="contained" component="label">
                        <span>Upload Logo</span>
                        <input
                          type="file"
                          id="partner_logo"
                          onChange={this.handleCapture}
                          style={{ display: 'none' }}
                        />
                      </Button>
                    </Grid>
                    <Grid item md={12} xs={12}>
                      {partner.partner_logoPreview !== '' && (
                        <Box component="div" m={2} ml={0}>
                          <Avatar
                            style={{
                              cursor: 'pointer',
                              height: 150,
                              width: 150,
                              borderRadius: 6,
                            }}
                            alt="Profile Picture"
                            variant="square"
                            onClick={this.selectPartnerLogo}
                            src={partner.partner_logoPreview}
                          />
                        </Box>
                      )}
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <Button
                        type="submit"
                        variant="outlined"
                        color="default"
                        className="btn-primary btn-submit"
                      >
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                </ValidatorForm>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    );
  }
}

function mapState(state) {
  const { alert } = state;
  return { alert };
}

const actionCreators = {
  clearAlerts: alertActions.clear,
  addPartner: generalActions.addPartner,
};

const connectedPage = withRouter(connect(mapState, actionCreators)(AddPartnerPage));
export { connectedPage as AddPartnerPage };

export default AddPartnerPage;
