import React, { Component } from 'react';
import {
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import HeaderComponent from '../components/HeaderComponent';
import FooterComponent from '../components/FooterComponent';
import HomePage from '../pages/HomePage';
import NotFound from '../pages/NotFound';

import { LoginPage } from '../pages/LoginPage';
import { RegistrationPage } from '../pages/RegistrationPage';
import { ResetPasswordPage } from '../pages/ResetPasswordPage';
import { PropertyDetailPage } from '../pages/PropertyDetailsPage';
import { SearchPage } from '../pages/SearchPage';

const GuestRoute = ({
  logopath,
  pagebackground,
  logobackground,
}) => {
  return (
    <Routes>
      <Route
        path="/login"
        element={
          <>
            <HeaderComponent
              logopath={logopath}
              logobackground={logobackground}
            />
            <LoginPage />
            <FooterComponent />
          </>
        }
      />
      <Route
        path="/register"
        element={
          <>
            <HeaderComponent
              logopath={logopath}
              logobackground={logobackground}
            />
            <RegistrationPage />
            <FooterComponent />
          </>
        }
      />
      <Route
        path="/reset-password"
        element={
          <>
            <HeaderComponent
              logopath={logopath}
              logobackground={logobackground}
            />
            <ResetPasswordPage />
            <FooterComponent />
          </>
        }
      />
      <Route
        path="/property/view/:id"
        element={
          <>
            <HeaderComponent
              logopath={logopath}
              logobackground={logobackground}
            />
            <PropertyDetailPage />
          </>
        }
      />
      <Route
        path="/marketplace"
        element={
          <>
            <div className="fixed">
              <HeaderComponent
                logopath={logopath}
                logobackground={logobackground}
              />
            </div>
            <SearchPage />
          </>
        }
      />

      <Route
          exact
          path="/"
          element={
            <>
              <HeaderComponent
                logopath={logopath}
                logobackground={logobackground}
                stickyPosition={200}
                noStickyClass="homeNoStickedHeader"
              />
              <HomePage background={pagebackground} />
              <FooterComponent />
            </>
          }
        />

        <Route path="*" element={
          <>
            <HeaderComponent
              logopath={logopath}
              logobackground={logobackground}
              stickyPosition={200}
              noStickyClass="homeNoStickedHeader"
            />
            <NotFound />
            <FooterComponent />
          </>
        } />
    </Routes>
  )
}

export default GuestRoute;
export { GuestRoute };
