import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import i18n from "../../i18n";
import "./styles.scss";
import Gallery from "react-grid-gallery";

class PropertyKeyFigures extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openGallery: false,
        }
    }

    openGalleryModal = (floor_plan) => {
        this.props.onModalOpen();
        this.setState({
            openGallery: true,
        })
    }

    closeGalleryModal = () => {
        this.props.onModalClose();
        this.setState({
            openGallery: false,
        })
    }

    handleDataTransformation = (floor_plan_path) => {
        return floor_plan_path.map(({ image_path }) => {
            return {
                src: image_path,
                thumbnailWidth: 300,
                thumbnailHeight: 300,
                thumbnail: image_path
            }
        });
    }

    render() {
        const { property } = this.props;

        return (
            property && (<div className='property-key-figures-box'>
                <Grid container spacing={3}>
                    {property.property_key_figures && (<Grid item md={6}>
                        <p className="title-text">{i18n.t('Key Figures')}</p>
                        <table className="table">
                            <tbody>
                                {property.property_key_figures.length > 0 ?
                                    property.property_key_figures.map((key_kigure, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{i18n.t(key_kigure.property_key)} :</td>
                                                <td>{key_kigure.property_value}</td>
                                            </tr>
                                        )
                                    })
                                    :
                                    <tr><td>Not available</td></tr>
                                }
                            </tbody>
                        </table>

                        {/* <div className="card-block">
                            <p className="block-title">{i18n.t('Rooms')}</p>
                            <p className="block-content">Bedroom, Kitchen, Storeroom, Bath, Anteroom, Toilet</p>
                        </div>

                        <div className="card-block">
                            <p className="block-title">{i18n.t('Equipment')}</p>
                            <p className="block-content">Kitchen block with e appliances, SAT, Cellar compartment, Lift</p>
                        </div> */}
                    </Grid>)}
                    {property.property_floor_plans?.length > 0 && (
                        <Grid item md={6}>
                            {property.property_floor_plans.map((fp, idx) => (
                                <img
                                    src={fp.image_path}
                                    alt="floor-plan-img"
                                    className="floor-plan-img"
                                    onClick={this.openGalleryModal}
                                />
                            ))}
                        </Grid>
                    )}
                    <div className="modal-container">
                        {property && property.property_floor_plans && property.property_floor_plans.length > 0 && this.state.openGallery && (<Grid container>
                            <Grid item md={12} xs={12}>
                                <Gallery
                                    margin={0}
                                    images={this.handleDataTransformation(property.property_floor_plans)}
                                    thumbnailImageComponent={ImageComponent}
                                    enableImageSelection={false}
                                    showLightboxThumbnails={true}
                                    isOpen={true}
                                    lightboxWillClose={this.closeGalleryModal}
                                />
                            </Grid>
                        </Grid>)}
                    </div>
                </Grid>
            </div>)
        )
    }
}

class ImageComponent extends React.Component {
    render() {
        return <img alt="Property" className="property-image" {...this.props.imageProps} />;
    }
}

export default PropertyKeyFigures;
