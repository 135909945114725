import React, { Component } from 'react';
import ReactDOM from "react-dom";
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, Container, Grid, Hidden } from '@material-ui/core';
import { StickyContainer, Sticky } from 'react-sticky'; //
import PropertyImageSlider from './PropertyImageSlider';
import PropertyTitle from './PropertyTitle';
import HighlightsComponent from './HighlightsComponent';
import PropertyInvestmentBox from './PropertyInvestmentBox';
import PropertyDescription from './PropertyDescription';
import PropertyFinancialInformation from './PropertyFinancialInformation';
import PropertyKeyFigures from './PropertyKeyFigures';
import InvestmentDetails from './InvestmentDetails';
import LocationDetails from './LocationDetails';
import PatnerDetails from './PatnerDetails';
import Loader from '../../components/GeneralComponent/Loader';
import { generalActions } from '../../_actions';
import { withParams } from '../../_helpers/withParams';
import "./styles_description.scss";
import CloseIcon from "@material-ui/icons/Close";
import i18n from "../../i18n";
import FooterComponent from "../../components/FooterComponent";
import NotificationBox from '../../components/GeneralComponent/NotificationBox';
import { convertUnit } from '../../utils/convertUnit';

class PropertyDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notification: false,
      message: '',
      investment_block_height: 500,
      property_detail: {},
      propertyId: null,
      isModalOpen: false,
      investHeight: 0
    }
    this.setInvestHeight.bind(this);
  }

  componentDidMount() {
    const { id: propertyId } = this.props.params
    this.props.getProperty(propertyId);
    this.setState({ propertyId })

    var _self = this;
    setTimeout(() => {
      if (document.getElementById('investment-box') !== null) {
        const height = document.getElementById('investment-box').clientHeight;
        _self.setState({ investment_block_height: height });
      }
    }, 1000);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { property, users: { profile_data } } = nextProps;
    if (property !== prevState.property_detail) {
      if (property && Object.keys(property).length !== 0) {
        const toSystem = (profile_data && profile_data.user_id) ? profile_data.measurement : 'metric';
        if (property.macrolocation_description) {
          property.macrolocation_description = convertUnit(property.macrolocation_description, toSystem);
        }
        if (property.microlocation_description) {
          property.microlocation_description = convertUnit(property.microlocation_description, toSystem);
        }
        if (property.property_sections) {
          property.property_sections = property.property_sections.map(
            ({description, ...ps}) => ({
              ...ps,
              description: convertUnit(description, toSystem),
            })
          );
        }
        if (property.property_key_figures) {
          property.property_key_figures = property.property_key_figures.map(
            ({property_value, ...pkf}) => ({
              ...pkf,
              property_value: convertUnit(property_value, toSystem)
            })
          );
        }        
      }
      return {
        ...prevState,
        property_detail: property
      }
    }

    return null;
  }

  onModalOpen = () => {
    this.setState({
      isModalOpen: true
    })
  }

  onModalClose = () => {
    this.setState({
      isModalOpen: false
    })
  }

  setInvestHeight(height) {
    if (height == 0 || height == this.state.investHeight)
      return
    this.setState({
      investHeight: height
    })
  }

  toggleNotification = (notification, message='') => {
    this.setState({ notification, message });
  }

  render() {
    const { property_detail, propertyId, investHeight } = this.state;

    return (
      property_detail && Object.keys(property_detail).length > 0 ?
        (<div className='property-details-page'>
            <NotificationBox
                open={this.state.notification}
                variant={'error'}
                message={this.state.message}
            />
          <PropertyImageSlider property={property_detail} onModalOpen={this.onModalOpen} onModalClose={this.onModalClose} />
          <Container>
            <div className='property-detail-box'  >
              <Grid container spacing={3}>
                <Grid item md={8} xs={12}>
                  <PropertyTitle property={property_detail} />
                </Grid>
              </Grid>
              <StickyContainer >
                <Grid container spacing={3}>
                  <Hidden mdUp>
                    <Grid id="investment-box" className="investment-box" item md={4} xs={12}>
                      <Sticky disableCompensation topOffset={this.state.investment_block_height}>
                        {({
                          isSticky,
                          distanceFromBottom,
                        }) => (
                          <div>
                            <PropertyInvestmentBox
                              top={distanceFromBottom}
                              isSticky={isSticky}
                              property={property_detail}
                              propertyId={propertyId}
                              onModalOpen={this.onModalOpen} onModalClose={this.onModalClose}
                              toggleNotification={this.toggleNotification}
                            />
                          </div>
                        )}
                      </Sticky>
                    </Grid>
                  </Hidden>

                  <Grid item md={8} xs={12}>
                    <PropertyFinancialInformation property={property_detail} />
                    <HighlightsComponent property={property_detail} />
                  </Grid>

                  <Hidden smDown>
                    <Grid item md={4} xs={12}>
                      <Sticky bottomOffset={investHeight + 45}>
                        {({
                          distanceFromBottom,
                          style,
                          calculatedHeight
                        }) => (
                          <div>
                            {
                              this.setInvestHeight(calculatedHeight)
                            }
                            <PropertyInvestmentBox
                              style={style}
                              height={this.state.investment_block_height}
                              top={distanceFromBottom}
                              isFooter={true}
                              property={property_detail}
                              propertyId={propertyId}
                              onModalOpen={this.onModalOpen} onModalClose={this.onModalClose}
                              toggleNotification={this.toggleNotification}
                            />
                          </div>
                        )}
                      </Sticky>
                    </Grid>
                  </Hidden>
                </Grid>
              </StickyContainer>

              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <PropertyDescription property={property_detail} onModalOpen={this.onModalOpen} onModalClose={this.onModalClose} />
                  <PropertyKeyFigures property={property_detail} onModalOpen={this.onModalOpen} onModalClose={this.onModalClose} />
                  <InvestmentDetails property={property_detail} showFinancialInfo={property_detail.property_type !== 'direct'} />
                  <LocationDetails property={property_detail} />
                  <PatnerDetails property={property_detail} />
                </Grid>
              </Grid>
            </div>
          </Container>
          <FooterComponent />
        </div >)
        :
        <Loader />

    )
  }
}


function mapState(state) {
  const { users, general } = state;
  const { property } = general;
  return { users, property };
}

const actionCreators = {
  getProperty: generalActions.getProperty,
  propertyFileDownload: generalActions.propertyFileDownload
}

const connectedPropertyDetail = withParams(connect(mapState, actionCreators)(PropertyDetail));
export { connectedPropertyDetail as PropertyDetailPage };
