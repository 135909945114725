import React, { useEffect } from 'react'
import './styles.scss'
import i18n from '../../i18n';

const languages = [
    { code: 'en', name: 'English'},
    { code: 'es', name: 'Espanol'},
    { code: 'de', name: 'Deutsch'}
  ]

  export default function LanguageSelect({lang}) {

   if(!lang) {
       lang = localStorage.getItem('i18nextLng');
   }

    const onChange = (e) => {
        console.log(e)
        i18n.changeLanguage(e).then((t) => {
            window.location.reload();
        }).catch(e => console.log(e));
    }
    console.log('lang')
    console.log(lang);
    let options = lang != undefined ? languages.map(language => {
        if(language.code != lang.split('-')[0]){
          return <li onClick={() => onChange(language.code)}><div  key={language.code} value={language.code} className={language.code} ></div></li>
        }
      }) : [];

    return (
        <div className="lang">
          <div
            className={lang ? lang.split('-')[0] : ''}
          >
          </div>
          <ul class="dropdown">
            {options}
          </ul>
        </div>

    )
  }

