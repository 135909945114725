import React, { Component } from 'react';
import { Dialog, DialogContent, Box, Button } from '@material-ui/core';
import CreditCardOutlinedIcon from '@material-ui/icons/CreditCardOutlined';
import i18n from "../../../i18n";
import "./styles.scss";

class DepositInfoAdminModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            accountButton: {
                text: "Copy",
                disabled: false,
            },
            refNumberButton: {
                text: "Copy",
                disabled: false,
            },
        }
    }

    copyToClipboard = (event) => {
        let element = "";
        const update = {};
        if (event.currentTarget.name === "btn-account") {
            element = document.getElementById("acc_iban");
            update["accountButton"] = {
                text: "Copied!",
                disabled: true,
            };
        } else if (event.currentTarget.name === "btn-number") {
            element = document.getElementById("ref_num");
            update["refNumberButton"] = {
                text: "Copied!",
                disabled: true,
            };
        }

        const range = document.createRange();
        range.selectNode(element);
        window.getSelection().removeAllRanges(); // clear current selection
        window.getSelection().addRange(range); // to select text
        document.execCommand("copy");
        window.getSelection().removeAllRanges();// to deselect

        this.setState({...update});
        Object.keys(update).forEach((key) => {
            update[key] = {
                text: "Copy",
                disabled: false,
            };
        });

        setTimeout(() => this.setState({ ...update }), 2000);
    }

    formatDate = () => {
        const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

        const now = new Date();
        const day = now.getDate();
        const month = months[now.getMonth()].substring(0, 3);
        const hours = now.getHours().toString().length > 1 ? now.getHours() : `0${now.getHours()}`;
        const minutes = now.getMinutes().toString().length > 1 ? now.getMinutes() : `0${now.getMinutes()}`;

        return `${day} ${month} ${hours}:${minutes}`;
    }

    formatAmount = (amount) => {
        amount = amount.split("").reverse().join("");
        if (amount) {
            const digits = amount.length;
            amount = amount.replace(/.{3}/g, '$&,');
            if (digits % 3 === 0)
                amount = amount.substring(0, amount.length-1);
        }
        return amount.split("").reverse().join("");
    }

    render() {
        const { user } = this.props;

        return (
            <Dialog className='deposit-modal wider-modal' fullWidth={true} maxWidth={"xs"} onClose={this.props.handleClose} open={this.props.open}>
                <DialogContent>
                    <div className="block-header">{i18n.t('Deposit')}</div>
                    <div className="payment-icon"><CreditCardOutlinedIcon /></div>

                    <div className="block-content">
                        <p className="txt-title">To: {user.first_name} {user.last_name}</p>
                        <p className="txt-status">+{this.formatAmount(this.props.depositAmount)} ($)</p>
                    </div>

                    <div className="block-content">
                        <p className="text">You are about to add funds to user {user.first_name} {user.last_name}. Press the <strong>FINISH DEPOSIT</strong> button when you are ready.</p>
                    </div>

                    <Box my={1}>
                        <Button className="btn-print" onClick={this.props.handleBack}>{i18n.t('Back')}</Button>
                        <Button className="btn-print" onClick={this.props.handleFinish}>{i18n.t('Finish deposit')}</Button>
                    </Box>
                </DialogContent>
            </Dialog>
        )
    }
}

export default DepositInfoAdminModal;
