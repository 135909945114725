import { generalConstants } from '../_constants';
import { generalService } from '../_services';
import { alertActions } from './';

function getAllLocations() {
  return (dispatch) => {
    generalService
      .get_locations()
      .then((locations) => {
        dispatch(success(locations.data))});
  };

  function success(locations) {
    return { type: generalConstants.GET_LOCATION_SUCCESS, locations };
  }
}

const updatePropertyValues = (data) => dispatch => {
  return new Promise(function (resolve, reject) {
      generalService.updatePropertyValues(data)
          .then(
              data => {
                  if (data.edited) {
                      dispatch(alertActions.success('Property updated successfully'));
                      resolve('resolved');
                  }
                  else {
                      dispatch(alertActions.error(data.message.toString()));
                  }
              }
          );
  });
}

const purchaseProperty = (data) => dispatch => {
  return new Promise(function (resolve, reject) {
      generalService.purchaseProperty(data)
          .then(
              data => {
                  if (data.edited) {
                      dispatch(alertActions.success('Property updated successfully'));
                      resolve('resolved');
                  }
                  else {
                      dispatch(alertActions.error(data.message.toString()));
                  }
              }
          );
  });
}

const transferProperty = (data) => dispatch => {
  return new Promise(function (resolve, reject) {
      generalService.transferProperty(data)
          .then(
              data => {
                  if (data.edited) {
                      dispatch(alertActions.success('Property updated successfully'));
                      resolve('resolved');
                  }
                  else {
                      dispatch(alertActions.error(data.message.toString()));
                  }
              }
          );
  });
}


function getProperties(dataLimit, page, isPromoted) {
  return dispatch => {
      generalService.getProperties(dataLimit, page, isPromoted)
          .then(
              data => {
                  if (data.status) {
                      dispatch(success(data));
                  }
                  else {
                      dispatch(success({ status: true, data: [] }));
                      dispatch(alertActions.error(data.message.toString()));
                  }
              }
          );
  };

  function success(data) { return { type: generalConstants.GET_PROPERTIES_SUCCESS, data } }
}

const deletePropertyById = (id) => dispatch => {
  return new Promise(function (resolve, reject) {
      generalService.deletePropertyById(id)
          .then(
              data => {
                  if (data.status) {
                      dispatch(alertActions.success('Property removed successfully'));
                      resolve('resolved');
                  }
                  else {
                      dispatch(alertActions.error(data.message.toString()));
                  }
              }
          );

  });
}

const updateProperty = (data) => dispatch => {
  console.log('update property', data);
  return new Promise(function (resolve, reject) {
      generalService.updateProperty(data)
          .then(
              data => {
                  if (data.status) {
                      const responseMessage = data.message ? data.message : 'Property successfully edited'
                      dispatch(alertActions.success(responseMessage));
                      resolve(data);
                  }
                  else {
                      dispatch(alertActions.error(data.message.toString()));
                  }
              }
          );
  });
}

function getProperty(propertyId) {
  return (dispatch) => {
    generalService
      .getProperty(propertyId)
      .then((properties) => dispatch(success(properties)));
  };

  function success(data) {
    return { type: generalConstants.GET_PROPERTY_SUCCESS, data };
  }
}

function getPropertyById(id) {
  console.log(id);;
  return dispatch => {
      generalService.getPropertyById(id)
          .then(
              data => {
                  if (data) {
                      dispatch(success(data))
                  }
                  else {
                      dispatch(success({ status: true, data: null }));
                      dispatch(alertActions.error(data.message));
                  }
              },
              error => {
                  dispatch(success({ status: true, data: null }));
                  dispatch(alertActions.error(error.toString()));
              }
          );

  };

  function success(data) { return { type: generalConstants.GET_PROPERTY_SUCCESS, data } }
}

const addProperty = (data) => dispatch => {
  return new Promise(function (resolve, reject) {
      generalService.addProperty(data)
          .then(
              data => {
                  if (data.status) {
                      dispatch(alertActions.success('Property add successfully'));
                      resolve(data);
                  }
                  else {
                      dispatch(alertActions.error(data.message.toString()));
                  }
              }
          );
  });
}
function getAllProperties(page=0, size=10) {
  return dispatch => {
      generalService.get_all_properties(page, size)
          .then(
              properties => {
                  if (properties.status) {
                      dispatch(success(properties))
                  }
                  else {
                      dispatch(success(properties))
                      //dispatch(alertActions.error(properties.message.toString()));
                  }
              }
          );
  };
  function success(properties) { return { type: generalConstants.GET_ALL_PROPERTIES_SUCCESS, properties } }
}
function getPurchasePendingProperties(page=0, size=10) {
  return dispatch => {
    generalService.get_all_properties(page, size, "pending-escrow")
      .then(
          properties => {
              if (properties.status) {
                  dispatch(success(properties));
              }
              else {
                  dispatch(success(properties));
              }
          }
      );
    };
  function success(properties) { return { type: generalConstants.GET_ALL_PROPERTIES_SUCCESS, properties }; }
}
// PARTNER ACTIONS
const addPartner = (data) => dispatch => {
  return new Promise(function (resolve, reject) {
      generalService.addPartner(data)
          .then(
              data => {
                  if (data.status) {
                      dispatch(alertActions.success('Partner add successfully'));
                      resolve('resolved');
                  }
                  else {
                      dispatch(alertActions.error(data.message.toString()));
                  }
              }
          );
  });
}

function getPartners() {
  return (dispatch) => {
    generalService.getPartner().then((data) => {
      if (data.status) {
        dispatch(success(data));
      } else {
        dispatch(alertActions.error(data.message.toString()));
      }
    });
  };

  function success(data) {
    return { type: generalConstants.GET_PARTNER_SUCCESS, data };
  }
}

function getPartnerById(id) {
  return dispatch => {
      generalService.getPartnerById(id)
          .then(
              data => {
                  if (data.status) {
                      dispatch(success(data));
                  }
                  else {
                      dispatch(alertActions.error(data.message.toString()));
                  }
              }
          );
  };

  function success(data) { return { type: generalConstants.GET_PARTNER_BY_ID_SUCCESS, data } }
}

const updatePartnerById = (data) => dispatch => {
  return new Promise(function (resolve, reject) {
      generalService.updatePartnerById(data)
          .then(
              data => {
                  if (data.status) {
                      dispatch(success(data));
                      dispatch(alertActions.success('Partner updated successfully'));
                      resolve('resolved');
                  }
                  else {
                      dispatch(alertActions.error(data.message.toString()));
                  }
              }
          );
  });
  function success(data) { return { type: generalConstants.GET_PARTNER_BY_ID_SUCCESS, data } }
}

const deletePartnerById = (id) => dispatch => {
    return new Promise(function (resolve, reject) {
        generalService.deletePartnerById(id)
            .then(
                data => {
                    if (data.status) {
                        dispatch(alertActions.success('Partner removed successfully'));
                        resolve('resolved');
                    }
                    else {
                        dispatch(alertActions.error(data.message.toString()));
                    }
                }
            );
    })
}

// PARTNER ACTIONS

function getAvailableLocations() {
  return dispatch => {
      generalService.getAvailableLocations()
          .then(
              getLocationsResponse => {
                  if (getLocationsResponse.status) {
                      dispatch(success(getLocationsResponse));
                  }
                  else {
                      dispatch(alertActions.error(getLocationsResponse.message.toString()));
                  }
              }
          );
  };

  function success(getLocationsResponse) { return { type: generalConstants.GET_AVAILABLE_LOCATIONS_SUCCESS, payload: getLocationsResponse.data } }
}

const filterProperty = (dataLimit, filterObj, page) => (dispatch) => {
  return new Promise(function (resolve, reject) {
    generalService.filterProperty(dataLimit, filterObj, page).then(
      (properties) => {
        if (properties.status) {
          dispatch(success(properties));
          resolve('resolved');
        } else {
          dispatch(success(properties));
          //dispatch(alertActions.error(properties.message.toString()));
          resolve('resolved');
        }
      },
      (error) => {
        //dispatch(alertActions.error(error.toString()));
      }
    );
  });

  function success(properties) {
    return { type: generalConstants.GET_ALL_PROPERTIES_SUCCESS, properties };
  }
};

function getFinancialInstruments() {
  return dispatch => {
      generalService.getFinancialInstruments()
          .then(
              data => {
                  if (data.status) {
                      dispatch(success(data));
                  }
                  else {
                      dispatch(alertActions.error(data.message.toString()));
                  }
              }
          );
  };

  function success(data) { return { type: generalConstants.GET_FINANCIAL_INSTRUMENTS_SUCCESS, data } }
}

//DIVIDENT DISTRIBUTIONS ACTIONS
function getDividendDistributions() {
  return dispatch => {
      generalService.getDividendDistributions()
          .then(
              data => {
                  if (data.status) {
                      dispatch(success(data));
                  }
                  else {
                      dispatch(alertActions.error(data.message.toString()));
                  }
              }
          );
  };

  function success(data) { return { type: generalConstants.GET_DIVIDENT_DISTRIBUTIONS_SUCCESS, data } }
}

//FINANCIAL INSTRUMENTS ACTIONS

const verifyPaymentDetails =
  (propertyId, investmentDetailObj) => (dispatch) => {
    return new Promise(function (resolve, reject) {
      generalService.verifyPaymentDetails(propertyId, investmentDetailObj).then(
        (paymentDetailVeficationRes) => {
          resolve(paymentDetailVeficationRes);
        },
        (error) => {
          dispatch(alertActions.error(error.toString()));
        }
      );
    });
  };
  
const updateCoinRates = () => dispatch => {
  return new Promise(function (resolve, reject) {
    generalService.getCryptoList()
      .then(
        coinRates => {
          dispatch(success(coinRates));
        },
        error => {}
      )
  })
  function success(coinRate) { return { type: generalConstants.UPDATE_COIN_RATES, coinRate } }
}

const hashDocuments = (propertyId, propertyDocuments) => dispatch => {
  return new Promise(function (resolve, reject) {
    generalService.hashDocuments(propertyId, propertyDocuments).then();
  });
}

function getPropertyPricing(type) {
  return (dispatch) => {
    generalService.getPropertyPricing(type).then((data) => {
      if (data.status) {
        dispatch(success(data));
      } else {
        dispatch(alertActions.error(data.message.toString()));
      }
    });
  };

  function success(data) {
    return { type: generalConstants.GET_PRICING_SUCCESS, data };
  }
}

export const generalActions = {
  getAllLocations,
  getProperty,
  addProperty,
  hashDocuments,
  getFinancialInstruments,
  getDividendDistributions,
  getAvailableLocations,
  getAllProperties,
  getPurchasePendingProperties,
  getProperties,
  deletePropertyById,
  updateProperty,
  updatePropertyValues,
  purchaseProperty,
  transferProperty,
  getPropertyById,
  filterProperty,
  verifyPaymentDetails,
  addPartner,
  getPartners,
  getPartnerById,
  updatePartnerById,
  deletePartnerById,
  updateCoinRates,
  getPropertyPricing,
};
