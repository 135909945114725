import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-locize-backend';
import LastUsed from 'locize-lastused';

const detectionOptions = {
    order: [ 'localStorage', 'cookie']
}

const backendOptions = {
    projectId: 'f7ce6d56-6d31-46ed-bf2e-cd1b3191256d',
    apiKey: '67045583-4fc0-4fad-a357-0e8de47962ab',
    referenceLng: 'en',
    version: 'latest',
    private: false,
    allowedAddOrUpdateHosts: ['localhost'],
  };

i18n
    .use(Backend)
    .use(LastUsed)
    .use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        ns: ['main'],
        defaultNS: 'main',
        //fallbackLng: "en", // use en if detected lng is not available
        debug: true,
        detection: detectionOptions,
        saveMissing: true,
        backend: backendOptions,
        locizeLastUsed: backendOptions,
        keySeparator: false, // we do not use keys in form messages.welcome
        interpolation: {
            escapeValue: false // react already safes from xss
        },
    }, (err, t) => {
        if (err)
            console.error(err)
    });
export default i18n;
