import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Box, Grid } from '@material-ui/core';
import i18n from "../../i18n";
import { userActions } from '../../_actions';
import InvestmentModal from '../../components/DashboardComponent/modals/InvestmentModal';
import OrderBookModal from '../../components/DashboardComponent/modals/OrderBookModal';
import TradeModal from '../../components/DashboardComponent/modals/TradeModal';
import InvetmentFooter from './InvetmentFooter';
import { history, withRouter } from '../../_helpers';
import { secureStorage } from '../../_services';
import "./styles.scss";

const TOKEN_WALLET_PAGE_URL = '/dashboard/tokenwallet';
class PropertyInvestmentBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalStatus: false,
      isUserLoggedIn: false,
      order_details: {
        _property_id: props.property.property_id,
        _order_reference_id: null,
        _user_reference_id: 0,
        order_type: "Buy",
        quantity: 0,
        price: 0,
        amount: 0,
        fee: 0,
        fee_amount: 0,
        total_amount: 0,
        payment_method: "account balance",
        order_complete: false
      },
    }
  }

  componentDidMount() {
    this.setInitialValues();
  }

  componentDidUpdate(prevProps) {
    if (this.props.property.property_id !== prevProps.property.property_id) {
      this.setInitialValues()
    }
  }

  componentWillUnmount() {
    this.state.intervalId && clearInterval(this.state.intervalId);
  }

  setInitialValues = () => {
    var tokenInfo = this.props.parseJwt();
    if (tokenInfo && tokenInfo.status) {
      const { property_price } = this.props.property;
      const quantity = 1, fee = 2.5;

      let fee_amount = property_price * fee/100;
      if (fee_amount % 1 !== 0) {
        fee_amount = parseFloat(fee_amount.toFixed(2));
      }

      let _user_reference_id = secureStorage.getItem('user_id');
      try {
        if (this.props.users.profile_data.user_id) {
          _user_reference_id = this.props.users.profile_data.user_id;
        }
      } catch(err) {
        console.log("Error occurred", err);
      }

      this.setState({ 
        isUserLoggedIn: true,
        order_details: {
          ...this.state.order_details,
          quantity, fee, fee_amount, _user_reference_id, 
          _property_id: this.props.propertyId, 
          price: property_price,
          amount: quantity * property_price,
          total_amount: property_price + fee_amount,
        } 
      });
    }
  }

  openInvestmentModal = () => {

    if (this.state.isUserLoggedIn) {
      if (this.props.propertyId) {
        this.props.onModalOpen();
        this.props.getUserDetails();
        this.setState({ modalStatus: true });
      }
    } else {
      this.props.navigate("/login")
      // history.push('/login', { next: `/property/view/${this.props.propertyId}` })
    }
  }

  openOrderBookModal = () => {
    const walletAddress = secureStorage.getItem("wallet_address");
    if (this.state.isUserLoggedIn) {
      if(!walletAddress){
        this.props.navigate(TOKEN_WALLET_PAGE_URL)
      }
      else if (this.props.propertyId) {
        this.props.getUserDetails();
        this.props.getSettings();
        this.props.getUserRecentTransactions(this.props.propertyId);
        this.props.getUsersOrders(this.props.propertyId);
        this.setState({ modalStatus: true });
      }
    } else {
      this.props.navigate("/login")
      // history.push('/login', { next: `/property/view/${this.props.propertyId}` })
    }
  }

  closeModal = () => {
    this.props.onModalClose();
    this.setState({ modalStatus: false });
  }

  formatePrice = (val) => {
    if (val !== undefined && val !== null && !isNaN(val)) {
      var parts = val.toString().split(".");
      if (parts.length > 1) {
        val = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".") + (parts[1] ? "," + parts[1] : "");
      }
      val = this.numberWithCommas(val);
      return val;
    }
  }

  formateCoinPrice = (val) => {
    if (val !== undefined && val !== null && !isNaN(val)) {
      var parts = val.toString().split(".");
      if (parts.length > 1) {
        val = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".") + (parts[1] ? "," + parts[1] : "");
      }
      return val;
    }
  }

  numberWithCommas = (x) => {
    if (x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }
    return x;
  }

  getButton = (type, message) => {
    if(type === 'disabled') {
      return (<>
        <Button onClick={this.openOrderBookModal} variant="contained" className="btn-order btn-secondary btn-disabled" disabled>
          {i18n.t('Purchase Property')}
        </Button>
        <br />
        <p>*{message}</p>
      </>);
    } else {
      return (<Button onClick={this.openOrderBookModal} variant="contained" className="btn-order btn-secondary">
        {i18n.t('Purchase Property')}
      </Button>);
    }
  } 

  render() {
    const {
      top, isSticky, isFooter, height,
      property, propertyId,
      style
    } = this.props;

    var issuer_name = undefined
    if (property.property_project_partners && property.property_project_partners[0] && property.property_project_partners[0].partner_master) {
      issuer_name = property.property_project_partners[0].partner_master.name
    }

    return (
      <>
        {property && <div
          className='property-investment-box'
          style={style}
        >
          {
            property.property_type === 'primary'
              ? (
                <div className="property-investment-box-primary">
                  <table className="table table-block-1">
                    <tbody>
                      <tr>
                        <td>
                          <p className='txt-value'>{this.formatePrice(property.profit_payout_percent)} % <span>p.a.*</span></p>
                          <p className='txt-title'>{i18n.t('Net rental return')}</p>
                        </td>
                        <td>
                          <p className='txt-value'>{this.formatePrice((property.profit_payout_percent * 1 + property.predicted_value_increase_p_a * 1).toFixed(2))} % <span>p.a.*</span></p>
                          <p className='txt-title'>{i18n.t('Total Return')} </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <table className="table table-block-2">
                    <tbody>
                      <tr>
                        <td>{i18n.t('investbox_Net rental return')}</td>
                        <td>{property.dividend_distribution_name !== '' ? property.dividend_distribution_name : i18n.t('Unknown')}</td>
                      </tr>
                      <tr>
                        <td>{i18n.t('investbox_Type of real estate')}</td>
                        <td>{i18n.t('Rental')}</td>
                      </tr>
                      <tr>
                        <td>{i18n.t('investbox_Financial Instrument')}</td>
                        <td>{property.financial_instrument_name !== '' ? property.financial_instrument_name : 'Unknown'}</td>
                      </tr>
                      {issuer_name && (
                        <tr>
                          <td>{i18n.t('Issuer')}</td>
                          <td>{issuer_name}</td>
                        </tr>
                      )}
                      {/* <tr>
                                            <td>{i18n.t('investbox_Issuer')}</td>
                                            <td>{(property.issuer_details && property.issuer_details.first_name && property.issuer_details.last_name) ?
                                                property.issuer_details.first_name + " " + property.issuer_details.last_name
                                                : ''}
                                            </td>
                                        </tr> */}
                    </tbody>
                  </table>

                  <Box
                    mt={3}
                    className={(isSticky || top < 0) ? 'mobile-btn-invest' : ''}
                  >
                    <Button onClick={this.openInvestmentModal} variant="contained" className="btn-invest btn-secondary">{i18n.t('Invest Now')}</Button>
                  </Box>

                  {this.state.isUserLoggedIn && (
                    <InvestmentModal
                      handleClose={this.closeModal}
                      open={this.state.modalStatus}
                      property={this.props.property}
                      propertyId={propertyId}
                    />
                  )}

                </div>
              )
              : property.property_type === 'direct'
                ? (
                  <div className="property-investment-box-direct">
                    <Grid item xs={12} sm={12} md={12}>
                      <div className="value-block">
                        <p className="txt-value block-1">
                          <span>&#36; {this.formatePrice(property.property_price)}</span>
                        </p>
                        <p className="txt-information block-1">
                          <span>{i18n.t('Purchase with Bitcoin')}</span>
                        </p>
                        <p className="txt-information block-1">
                          <span>
                            BTC <span className="crypto-value">{this.props.coinRate?.BTC ? this.formateCoinPrice(property.property_price / this.props.coinRate.BTC) : '-'}</span>
                          </span>
                        </p>
                        <p className="txt-information block-1">
                          <span>{i18n.t('Purchase with Ether')}</span>
                        </p>
                        <p className="txt-information block-1">
                          <span>
                            ETH <span className="crypto-value">{this.props.coinRate?.ETH ? this.formateCoinPrice(property.property_price / this.props.coinRate.ETH) : '-'}</span>
                          </span>
                        </p>
                        <div
                          className={(isSticky || top < 0) ? 'mobile-btn-order txt-action block-1' : 'txt-action block-1'}
                        >
                          <Box mt={3} >
                            {this.state.order_details._user_reference_id === this.props.property._user_id ? 
                              (this.getButton('disabled', 'You own this property')) : ( 
                                secureStorage.getItem('is_kyc_verified') ? (this.getButton()) : (
                                  this.getButton('disabled', 'You must be KYC verified in order to purchase property')
                                ))}
                          </Box>
                        </div>
                      </div>
                    </Grid>
                    {this.state.isUserLoggedIn && (
                      <TradeModal
                        open={this.state.modalStatus}
                        orderDetails={this.state.order_details}
                        property={this.props.property}
                        user={this.props.users.profile_data}
                        handleClose={this.closeModal}
                        toggleNotification={this.props.toggleNotification}
                      />)}
                  </div>
                )
                : (
                  <div className="property-investment-box-secondary">
                    <Grid item xs={12} sm={12} md={12}>
                      <div className="value-block">
                        <p className="txt-title block-1">
                          <span>{i18n.t('Invest Now')}</span>
                        </p>
                        <p className="txt-value block-1">
                          <span>&#36; {property.lowest_available_brick_price}</span>
                        </p>
                        <p className="txt-information block-1">
                          <span>{i18n.t('Lowest Available Brick Price')}</span>
                        </p>
                        <div
                          className={(isSticky || top < 0) ? 'mobile-btn-order txt-action block-1' : 'txt-action block-1'}
                        >
                          <Box mt={3} >
                            <Button onClick={this.openOrderBookModal} variant="contained" className="btn-order btn-secondary">{i18n.t('Current offers')}</Button>
                          </Box>
                        </div>
                      </div>
                    </Grid>
                    {this.state.isUserLoggedIn && (
                      <OrderBookModal
                        handleClose={this.closeModal}
                        open={this.state.modalStatus}
                        property={this.props.property}
                        tabIndex={0}
                      />)}
                  </div>
                )
          }
        </div>}


        {(isFooter && top < (height * -1)) && <InvetmentFooter
          property={this.props.property} onClick={this.props.property.property_type === "primary" ? this.openInvestmentModal : this.openOrderBookModal} />}
      </>
    )
  }
}
function mapState(state) {
  const { general: { coinRate }, users, authentication } = state;
  return { coinRate, users, authentication };
}

const actionCreators = {
  parseJwt: userActions.parseJwt,
  getUserRecentTransactions: userActions.getUserRecentTransactions,
  getUsersOrders: userActions.getUsersOrders,
  getUserDetails: userActions.getUserDetails,
  getSettings: userActions.getSettings,
}

export default withRouter(connect(mapState, actionCreators)(PropertyInvestmentBox));
