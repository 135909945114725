import React, { useState, useEffect } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles } from '@material-ui/core/styles';
import { amber, green } from '@material-ui/core/colors';
import clsx from 'clsx';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

const useStyles1 = makeStyles((theme) => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  notificationContainer:{
    zIndex:3000
  }
}));

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};


export default function NotificationBox(props) {
  const classes = useStyles();
  const classes1 = useStyles1();
  const [snackStatus, updateSnackStatus] = useState(props.open)
  const { className, message, variant } = props;
  const Icon = variantIcon[variant];


  useEffect(() => {
    updateSnackStatus(props.open)
  }, [props])

  const handleClose = () => {
    updateSnackStatus(false)
  }

  return (
    <div className={classes.root}>
      <Snackbar
        open={snackStatus}
        className={classes1.notificationContainer}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <SnackbarContent
          className={clsx(classes1[variant], className)}
          aria-describedby="client-snackbar"
          message={
            <span id="client-snackbar" className={classes1.message}>
              <Icon className={clsx(classes1.icon, classes1.iconVariant)} />
              {message}
            </span>
          }
        />
      </Snackbar>
    </div>
  );
};