import React, { Component } from 'react';
import { connect } from 'react-redux';
import { locationActions, alertActions } from '../../_actions';
import { Grid, Card, CardContent } from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Button from "@material-ui/core/Button";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Paper from "@material-ui/core/Paper";
import {
    LanguageTabEn,
    LanguageTabGn,
    a11yProps,
    TabPanel
} from "../../components/GeneralComponent/LanguageTabs";
import NotificationBox from './../../components/GeneralComponent/NotificationBox';
import TinyMCE from "react-tinymce";
import { NavBarComponent as DasboardNavBarComponent } from '../../components/DashboardComponent/common/NavBarComponent';
import { withRouter } from '../../_helpers';

class EditLocationPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            location_heading_tabindex: 0,
            location_description_tabindex: 0,
            location: {
                id: 0,
                city_name: "",
                heading_en: "",
                heading_de: "",
                description_en: "",
                description_de: ""
            }
        }

    }

    componentDidMount() {
        this.props.clearAlerts();
        this.props.getLocationById(this.props.params.id)
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { location } = nextProps.location;
        if (location && Object.keys(location).length && location.id !== prevState.location.id) {
            return {
                location: {
                    id: location.id,
                    city_name: location.city_name,
                    heading_en: location.location_heading['en'],
                    heading_de: location.location_heading['de'],
                    description_en: location.location_description['en'],
                    description_de: location.location_description['de'],
                },
                location_heading_tabindex: prevState.location_heading_tabindex ? 0 : 1,
                location_description_tabindex: prevState.location_description_tabindex ? 0 : 1,
            }
        }
        return null;
    }

    handleChange = event => {
        var fieldName = event.target.name;
        var fieldValue = event.target.value;
        this.setState(prevState => ({
            location: {
                ...prevState.location,
                [fieldName]: fieldValue
            }
        }));
    };

    handleLocationTabChange = locationInputName => (event, newValue) => {
        this.setState({
            [locationInputName]: newValue
        });
    }

    handleLocationDescEditorChange = event => {
        var fieldName = event.target.id.split("-")[1];
        var fieldValue = event.target.getContent();
        this.setState(prevState => ({
            location: {
                ...prevState.location,
                [fieldName]: fieldValue
            }
        }));
    };

    submit = () => {
        this.form.isFormValid(false).then(isValid => {
            if (isValid) {
                this.props.updateLocationById(this.state.location).then(value => {
                    setTimeout(() => {
                        this.props.clearAlerts()
                        this.props.navigate("/dashboard/locations")
                    }, 2000);
                });
            }
        });
    };

    handleSubmit = () => {
        this.form.submit();
    };

    render() {
        const { alert } = this.props;
        const { location, location_heading_tabindex, location_description_tabindex } = this.state;

        return (
            <div className="main-content edit-location-page">
                <DasboardNavBarComponent pageName="manage-location"/>
                <Card>
                    <CardContent>
                        {alert.message &&
                            <NotificationBox open={true} variant={alert.type} message={alert.message} />
                        }
                        <ValidatorForm
                            className="default-form location-form edit-location-form"
                            onSubmit={this.handleSubmit}
                            ref={r => {
                                this.form = r;
                            }}
                            autoComplete="off"
                            instantValidate
                        >
                            <Grid container spacing={2}>
                                <Grid item md={4} xs={12}>
                                    <TextValidator
                                        fullWidth
                                        value={location.city_name}
                                        onChange={this.handleChange}
                                        id="text-name"
                                        name="city_name"
                                        className="form-control"
                                        label="Location City *"
                                        validators={["required"]}
                                        errorMessages={["This field is required"]}
                                    />
                                </Grid>
                                <Grid item md={12} xs={12}>
                                    <Paper square>
                                        <Tabs
                                            value={location_heading_tabindex}
                                            className="tabs-container"
                                            onChange={this.handleLocationTabChange('location_heading_tabindex')}
                                            indicatorColor="primary"
                                            textColor="primary"
                                            variant="fullWidth"
                                            aria-label="full width tabs example"
                                        >
                                            <Tab
                                                label={<LanguageTabEn />}
                                                {...a11yProps(0)}
                                            />
                                            <Tab
                                                label={<LanguageTabGn />}
                                                {...a11yProps(1)}
                                            />
                                        </Tabs>
                                    </Paper>
                                    <TabPanel
                                        value={location_heading_tabindex}
                                        index={0}
                                    >
                                        <TextValidator
                                            fullWidth
                                            value={location.heading_en}
                                            onChange={this.handleChange}
                                            id="text-heading_en"
                                            name="heading_en"
                                            className="form-control"
                                            label="Location Heading EN"
                                        />
                                    </TabPanel>
                                    <TabPanel
                                        value={location_heading_tabindex}
                                        index={1}
                                    >
                                        <TextValidator
                                            fullWidth
                                            value={location.heading_de}
                                            onChange={this.handleChange}
                                            id="text-heading_de"
                                            name="heading_de"
                                            className="form-control"
                                            label="Location Heading DE"
                                        />
                                    </TabPanel>
                                </Grid>
                                <Grid item md={12} xs={12}>
                                    <p className="location-description-lable">Location Description</p>
                                    <Paper square>
                                        <Tabs
                                            value={location_description_tabindex}
                                            className="tabs-container"
                                            onChange={this.handleLocationTabChange('location_description_tabindex')}
                                            indicatorColor="primary"
                                            textColor="primary"
                                            variant="fullWidth"
                                            aria-label="full width tabs example"
                                        >
                                            <Tab label={<LanguageTabEn />} {...a11yProps(0)} />
                                            <Tab label={<LanguageTabGn />} {...a11yProps(1)} />
                                        </Tabs>
                                    </Paper>

                                    <TabPanel value={location_description_tabindex} index={0}>
                                        <TinyMCE
                                            id="text-description_en"
                                            name="description_en"
                                            content={location.description_en}
                                            config={{
                                                plugins: "autolink link image lists print preview",
                                                toolbar: "undo redo | bold italic | alignleft aligncenter alignright | styleselect",
                                                menubar: false,
                                                preview_styles: false,
                                                branding: false,
                                                height: 250
                                            }}
                                            onChange={this.handleLocationDescEditorChange}
                                        />
                                    </TabPanel>
                                    <TabPanel value={location_description_tabindex} index={1}>
                                        <TinyMCE
                                            id="text-description_de"
                                            name="description_de"
                                            content={location.description_de}
                                            config={{
                                                plugins: "autolink link image lists print preview",
                                                toolbar: "undo redo | bold italic | alignleft aligncenter alignright | styleselect",
                                                menubar: false,
                                                preview_styles: false,
                                                branding: false,
                                                height: 250
                                            }}
                                            onChange={this.handleLocationDescEditorChange}
                                        />
                                    </TabPanel>
                                </Grid>
                                <Grid item md={12} xs={12}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={this.submit}
                                    >
                                        Save
                                        </Button>
                                </Grid>
                            </Grid>
                        </ValidatorForm>
                    </CardContent>
                </Card>
            </div>
        );
    }
}

function mapState(state) {
    const { alert, location } = state;
    return { alert, location };
}

const actionCreators = {
    clearAlerts: alertActions.clear,
    getLocationById: locationActions.getLocationById,
    updateLocationById: locationActions.updateLocationById
};

const connectedPage = withRouter(connect(mapState, actionCreators)(EditLocationPage));
export { connectedPage as EditLocationPage };

