import { generalConstants } from '../_constants';
import { userService, secureStorage } from '../_services';
import i18n from 'i18next';

export const initialState = () => {
  let language = window.secureStoragei18nextLng?.split('-')[0] || 'en';
  if(secureStorage.getItem(process.env.REACT_APP_LOGIN_KEY)){
  try {
    userService.getUserDetails()
    .then(userData => {
      // if (userData && userData.data.language) {
      //   language = userData.data.language;
      //   window.secureStoragei18nextLng = language;
      // }
    })
  } catch (ex) {
    console.log(ex);
  }
}

  const state = {
    locations: [],
    property: {},
    partners: {},
    partner: {},
    properties: [],
    dashboard_properties: [],
    macrolocation_heading_en: "",
    macrolocation_heading_de: "",
    properties_count: null,
    macrolocation_heading: '',
    financial_instruments: {},
    dividend_distributions: {},
    languageCode: i18n.language?.split('-')[0] || language,
    property_invested_shares: {},
    available_locations: {},
    coinRate: {
      BTC: 1,
      ETH: 1,
    }
  };
  return state;
};

export function general(state = initialState(), action) {
  switch (action.type) {
    case generalConstants.GET_PROPERTY_SUCCESS:
      return {
        ...state,
        property: action.data,
      };
    case generalConstants.GET_PROPERTIES_SUCCESS:
      return {
        ...state,
        properties: action.data,
      };
    case generalConstants.GET_ALL_PROPERTIES_SUCCESS:
      return {
        ...state,
        dashboard_properties: action.properties,
      };
    case generalConstants.GET_PROPERTIES_COUNT_SUCCESS:
      return {
        ...state,
        properties_count: action.payload,
      };
    case generalConstants.GET_LOCATION_SUCCESS:
      return {
        ...state,
        locations: action.locations,
      };
    case generalConstants.GET_PRICING_SUCCESS:
      return {
        ...state,
        propertyPricing: action.data,
      };
    case generalConstants.GET_FINANCIAL_INSTRUMENTS_SUCCESS:
      return {
        ...state,
        financial_instruments: action.data,
      };
    case generalConstants.GET_DIVIDENT_DISTRIBUTIONS_SUCCESS:
      return {
        ...state,
        dividend_distributions: action.data,
      };
    case generalConstants.GET_PARTNER_SUCCESS:
      return {
        ...state,
        partners: action.data,
      };
    case generalConstants.GET_PARTNER_BY_ID_SUCCESS:
      return {
        partner: action.data,
      };
    case generalConstants.GET_AVAILABLE_LOCATIONS_SUCCESS:
      return {
        available_locations: action.payload,
      };
    case generalConstants.UPDATE_COIN_RATES:
      return {
        ...state,
        coinRate: action.coinRate
      }
    default:
      return state;
  }
}
