import {
  addressBalance,
  addressAssetBalance,
  seedUtils,
  broadcast,
  transfer,
} from "@0bsnetwork/zbs-transactions";
import { secureStorage } from "./";

const ZBS_DECIMAL = 100000000;
const IMMO_DECIMAL = 100;

const getBalances = async () => {
  let nodeAddress = process.env.REACT_APP_NODE_API;

  let encryptionKey = process.env.REACT_APP_SEED_ENCRYPTION + "###"; //TODO: Salt
  let encryptedSeed = secureStorage.getItem("encryptedSeed");
  if (encryptedSeed) {
    let seedDecrypted = seedUtils.decryptSeed(
      encryptedSeed,
      encryptionKey,
      2048
    );
    if (seedDecrypted !== "") {
      let seedObj = seedUtils.Seed.fromExistingPhrase(
        seedDecrypted,
        process.env.REACT_APP_CHAIN_ID
      );

      return addressBalance(seedObj.address, nodeAddress).then((bal) => {
        return addressAssetBalance(
          seedObj.address,
          process.env.REACT_APP_TOKEN_ID,
          nodeAddress
        ).then((assetBal) => {
          let balObj = {
            zbs: bal / ZBS_DECIMAL,
            immo: assetBal / IMMO_DECIMAL,
          };
          return balObj;
        });
      });
    }
  }
  return {};
};

const spendTokens = async (amt) => {
  let nodeAddress = process.env.REACT_APP_NODE_API;
  let encryptionKey = process.env.REACT_APP_SEED_ENCRYPTION + "###"; //TODO: Salt
  let encryptedSeed = secureStorage.getItem("encryptedSeed");

  if (encryptedSeed) {
    let seedDecrypted = seedUtils.decryptSeed(
      encryptedSeed,
      encryptionKey,
      2048
    );

    const { transfer } = require("@0bsnetwork/zbs-transactions");
    const signedTranserTx = transfer(
      {
        assetId: "7iPq8gNGbsp35cuWGAKHkgCz5vtE2zaxcdWy3rUDgMG3",
        amount: amt * IMMO_DECIMAL,
        recipient: process.env.REACT_APP_SPEND_ADDRESS
      },
      seedDecrypted
    );

    let resp = await broadcast(signedTranserTx, nodeAddress);
    return resp;
  }
};

export const blockchainService = {
  getBalances,
  spendTokens
};
