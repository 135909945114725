import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import { initialState } from '../../_reducers/general.reducer'
import "./styles.scss";

class PropertyKeyFigures extends Component {
    getLanguageBasedKeyFigure = (keyFigure) => {
        var key = initialState.languageCode === 'en' ? keyFigure.property_key_en : keyFigure.property_key_de;
        var value = initialState.languageCode === 'en' ? keyFigure.property_value_en : keyFigure.property_value_de;
        return {
            key,
            value
        }
    }


    render() {
        const { property } = this.props;
        const isPropertyKeyFigures = property && property.key_figures;
        const property_key_figures = property.key_figures;

        return (
            property && (<div className='property-key-figures-box'>
                <Grid container spacing={3}>
                    {
                        isPropertyKeyFigures && <Grid item md={6}>
                            <p className="title-text">Key Figures</p>
                            <table className="table">
                                <tbody>
                                    {property_key_figures.length > 0 ?
                                        property_key_figures.map((key_kigure, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{this.getLanguageBasedKeyFigure(key_kigure).key} :</td>
                                                    <td>{this.getLanguageBasedKeyFigure(key_kigure).value} </td>
                                                </tr>
                                            )
                                        })
                                        :
                                        <tr><td>Not available</td></tr>
                                    }
                                </tbody>
                            </table>

                            {/* <div className="card-block">
                                <p className="block-title">Rooms</p>
                                <p className="block-content">Bedroom, Kitchen, Storeroom, Bath, Anteroom, Toilet</p>
                            </div>

                            <div className="card-block">
                                <p className="block-title">Equipment</p>
                                <p className="block-content">Kitchen block with e appliances, SAT, Cellar compartment, Lift</p>
                            </div> */}
                        </Grid>
                    }
                    {
                        (property && property.property_floor_plans.length) &&
                        <Grid item md={6}>
                            {property.property_floor_plans.filter(fp => !fp.is_delete).map((fp, idx) => (
                                <img key={idx} src={fp.floor_plan_image_preview} alt="floor-plan-img" className="floor-plan-img" />
                            ))}
                        </Grid>
                    }
                </Grid>
            </div>)
        )
    }
}

export default PropertyKeyFigures;