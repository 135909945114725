import React, { useEffect, useState, useRef } from 'react';
import {
  Grid,
  Box,
  Button,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Tabs,
  Tab,
  Avatar,
  Paper,
} from "@material-ui/core";
import { TextValidator } from "react-material-ui-form-validator";
import Autocomplete from 'react-google-autocomplete';
import TinyMCE from "react-tinymce";
import {
  LanguageTabEn,
  LanguageTabGn,
  a11yProps,
  TabPanel
} from "../../../components/GeneralComponent/LanguageTabs";
import { generalService } from '../../../_services';

const DirectPropertyDetailEdit = ({
  property,
  onUpdate,
  onUpdateLocation,
}) => {

  const [available_locations, setAvailableLocation] = useState([]);

  const [macrolocation_heading_tabindex, setMacrolocationHeadingTabindex] = useState(0);
  const [macrolocation_description_tabindex, setMacrolocationDescriptionTabindex] = useState(0);
  const [microlocation_heading_tabindex, setMicrolocationHeadingTabindex] = useState(0);
  const [microlocation_description_tabindex, setMicrolocationDescriptionTabindex] = useState(0);

  const prevPropertyRef = useRef();

  useEffect(() => {
    let isMounted = true;

    (async () => {
      const {
        status: location_status,
        data: locations
      } = await generalService.getAvailableLocations();
      location_status && isMounted && setAvailableLocation(locations);

    })();
    return () => {
      isMounted = false;
    }
  }, []);

  useEffect(() => {
    if (prevPropertyRef.current && !prevPropertyRef.current.property_id && property.property_id) {
      setMacrolocationHeadingTabindex(macrolocation_heading_tabindex ? 0 : 1);
      setMacrolocationDescriptionTabindex(macrolocation_description_tabindex ? 0 : 1);
      setMicrolocationHeadingTabindex(microlocation_heading_tabindex ? 0 : 1);
      setMicrolocationDescriptionTabindex(microlocation_description_tabindex ? 0 : 1);
    }
    prevPropertyRef.current = property;
  }, [property]);
  
  const handleChange = (event) => {
    const fieldName = event.target.name;
    const fieldValue = event.target.value;
    onUpdate({
      [fieldName]: fieldValue
    });
  }

  const onPlaceSelected = (place) => {
    function handleSubTypes(field) {
      var fieldKey = field.types.find((subType) => subType === "postal_code" || subType === "country");
      return {
        'field_name': fieldKey,
        'field_value': field.long_name
      }
    };

    var fields = (place && place.address_components)
      ? place.address_components
          .filter((c) => c.types.some((sc) => sc === "postal_code" || sc === "country"))
          .map(handleSubTypes)
      : [];
    var postalCodeObj = fields.find(f => f.field_name === "postal_code");
    var countryObj = fields.find(f => f.field_name === "country");
    var postal_code = (postalCodeObj && postalCodeObj.field_value) ? postalCodeObj.field_value : "";
    var country = (countryObj && countryObj.field_value) ? countryObj.field_value : "";
    var vicinity = place.vicinity;

    onUpdate({
      address_postcode: postal_code,
      address_line1: vicinity || "",
      address_country: country
    });

    if (place && place.geometry && place.geometry.location) {
      var location_cords = {
        position: {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng()
        }
      };

      onUpdateLocation && onUpdateLocation(location_cords);
    }
  };

  const handleAddressCityChange = event => {
    var fieldName = event.target.name, cityName = event.target.value;
    var city = available_locations.find(l => l.city_name === cityName);
    if (city) {
      var cityValue = city.city_name;
      onUpdate({
        [fieldName]: cityValue,
          macrolocation_heading_en: city.location_heading['en'],
          macrolocation_heading_de: city.location_heading['de'],
          macrolocation_description_en: city.location_description['en'],
          macrolocation_description_de: city.location_description['de'],
      });
      setMacrolocationDescriptionTabindex(macrolocation_description_tabindex ? 0 : 1);
      setMacrolocationHeadingTabindex(macrolocation_heading_tabindex ? 0 : 1);
    }
  }

  const handleLocationTabChange = locationInputName => (_event, newValue) => {
    const sets = {
      macrolocation_heading_tabindex: setMacrolocationHeadingTabindex,
      macrolocation_description_tabindex: setMacrolocationDescriptionTabindex,
      microlocation_heading_tabindex: setMicrolocationHeadingTabindex,
      microlocation_description_tabindex: setMicrolocationDescriptionTabindex,
    }
    sets[locationInputName] && sets[locationInputName](newValue);
  }

  const handleMacrolocationPicture = e => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      onUpdate({
        macro_location_image: file,
        macro_location_image_preview: reader.result
      });
    };
    reader.readAsDataURL(file);
  }

  const handleMicrolocationPicture = e => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      onUpdate({
        micro_location_image: file,
        micro_location_image_preview: reader.result
      });
    };
    reader.readAsDataURL(file);
  }

  const selectImageWithName = (e, id) => {
    document.getElementById(id).click();
  };

  const handleEditorChange = event => {
    var fieldName = event.target.id.split("-")[1];
    var fieldValue = event.target.getContent();
    onUpdate({
      [fieldName]: fieldValue
    });
  };

  return (
    <>
      <Grid container spacing={1}>                        
        <Grid item md={4} xs={12}>
          <TextValidator
            fullWidth
            value={property.title}
            onChange={handleChange}
            id="text-name"
            name="title"
            className="form-control"
            label="Property Title *"
            validators={["required"]}
            errorMessages={["This field is required"]}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <FormControl fullWidth>
            <InputLabel shrink>Property type</InputLabel>
            <Select
              id="text-property_category_type"
              name="property_category_type"
              value={property.property_category_type}
              onChange={handleChange}
              fullWidth
            >
              <MenuItem value="apartment"> Apartment</MenuItem>
              <MenuItem value="apartment house">Apartment House</MenuItem>
              <MenuItem value="house">House</MenuItem>
              <MenuItem value="office space">Office Space</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item md={12} xs={12}>
          <FormControl fullWidth>
            <label className="autocomplete-form-label">Address Line1</label>
            <Autocomplete
              className="autocomplete-input"
              onPlaceSelected={onPlaceSelected}
              options={{
                fields: [
                  'address_components',
                  'adr_address',
                  'formatted_phone_number',
                  'icon',
                  'name',
                  'photos',
                  'vicinity',
                  'geometry.location',
                  'place_id',
                  'formatted_address'
                ],
                types: ['address'],
              }}
            // types={['(administrative_area2)']}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item md={4} xs={12}>
          <FormControl fullWidth>
            <InputLabel shrink>Address City</InputLabel>
            <Select
              name="address_city"
              id="text-address_city"
              value={property.address_city}
              onChange={handleAddressCityChange}
            >
              <MenuItem disabled value={"None"}><em>None</em></MenuItem>
              {available_locations.length > 0 &&
                available_locations.map((location, index) => (
                  <MenuItem key={index} value={location.city_name}>
                    {location.city_name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={4} xs={12}>
          <TextValidator
            fullWidth
            value={property.address_postcode}
            onChange={handleChange}
            id="text-address_postcode"
            name="address_postcode"
            className="form-control"
            label="Address Postcode"
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <TextValidator
            fullWidth
            value={property.address_country}
            onChange={handleChange}
            id="text-address_country"
            name="address_country"
            className="form-control"
            label="Address Country"
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item md={12} xs={12}>
          <Paper square>
            <Tabs
              value={macrolocation_heading_tabindex}
              className="tabs-container"
              onChange={handleLocationTabChange('macrolocation_heading_tabindex')}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              aria-label="full width tabs example"
            >
              <Tab
                label={<LanguageTabEn />}
                {...a11yProps(0)}
              />
              <Tab
                label={<LanguageTabGn />}
                {...a11yProps(1)}
              />
            </Tabs>
          </Paper>
          <TabPanel
            value={macrolocation_heading_tabindex}
            index={0}
          >
            <TextValidator
              fullWidth
              value={property.macrolocation_heading_en}
              onChange={handleChange}
              id="text-macrolocation_heading_en"
              name="macrolocation_heading_en"
              className="form-control"
              label="Macrolocation Heading EN"
            />
          </TabPanel>
          <TabPanel
            value={macrolocation_heading_tabindex}
            index={1}
          >
            <TextValidator
              fullWidth
              value={property.macrolocation_heading_de}
              onChange={handleChange}
              id="text-macrolocation_heading_de"
              name="macrolocation_heading_de"
              className="form-control"
              label="Macrolocation Heading DE"
            />
          </TabPanel>
        </Grid>
        <Grid item md={12} xs={12}>
          <Box component="div" m={2} ml={0}>
            Macrolocation Picture
            <Button className="location-image-button" variant="contained" component="label">
              <span>Upload Picture</span>
              <input
                type="file"
                id="macro_photo"
                onChange={handleMacrolocationPicture}
                className="hide"
                accept="image/*"
              />
            </Button>
          </Box>
          <Box component="div" m={2} ml={0}>
            {property.macro_location_image_preview !== "" && (
              <Box component="div" m={2} ml={0}>
                <Avatar
                  className="location-image"
                  alt="Macrolocation Picture"
                  variant="square"
                  onClick={(e) => selectImageWithName(e, "macro_photo")}
                  src={property.macro_location_image_preview}
                />
              </Box>
            )}
          </Box>
        </Grid>
        <Grid item md={12} xs={12}>
          <p>Macrolocation Description</p>
          <Paper square>
            <Tabs
              value={macrolocation_description_tabindex}
              className="tabs-container"
              onChange={handleLocationTabChange('macrolocation_description_tabindex')}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              aria-label="full width tabs example"
            >
              <Tab
                label={<LanguageTabEn />}
                {...a11yProps(0)}
              />
              <Tab
                label={<LanguageTabGn />}
                {...a11yProps(1)}
              />
            </Tabs>
          </Paper>

          <TabPanel
            value={macrolocation_description_tabindex}
            index={0}
          >
            <TinyMCE
              id={`text-macrolocation_description_en`}
              name="macrolocation_description"
              content={property.macrolocation_description_en}
              config={{
                plugins:
                  "autolink link image lists print preview",
                toolbar:
                  "undo redo | bold italic | alignleft aligncenter alignright | styleselect",
                // menubar: 'file edit insert view format table tools help'
                menubar: false,
                preview_styles: false,
                branding: false
              }}
              onChange={handleEditorChange}
            />
          </TabPanel>
          <TabPanel
            value={macrolocation_description_tabindex}
            index={1}
          >
            <TinyMCE
              id="text-macrolocation_description_de"
              name="macrolocation_description"
              content={property.macrolocation_description_de}
              config={{
                plugins:
                  "autolink link image lists print preview",
                toolbar:
                  "undo redo | bold italic | alignleft aligncenter alignright | styleselect",
                // menubar: 'file edit insert view format table tools help'
                menubar: false,
                preview_styles: false,
                branding: false
              }}
              onChange={handleEditorChange}
            />
          </TabPanel>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item md={12} xs={12}>
          <Paper square>
            <Tabs
              value={microlocation_heading_tabindex}
              className="tabs-container"
              onChange={handleLocationTabChange('microlocation_heading_tabindex')}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              aria-label="full width tabs example"
            >
              <Tab
                label={<LanguageTabEn />}
                {...a11yProps(0)}
              />
              <Tab
                label={<LanguageTabGn />}
                {...a11yProps(1)}
              />
            </Tabs>
          </Paper>
          <TabPanel
            value={microlocation_heading_tabindex}
            index={0}
          >
            <TextValidator
              fullWidth
              value={property.microlocation_heading_en}
              onChange={handleChange}
              id="text-microlocation_heading_en"
              name="microlocation_heading_en"
              className="form-control"
              label="Microlocation Heading EN"
            />
          </TabPanel>
          <TabPanel
            value={microlocation_heading_tabindex}
            index={1}
          >
            <TextValidator
              fullWidth
              value={property.microlocation_heading_de}
              onChange={handleChange}
              id="text-microlocation_heading_de"
              name="microlocation_heading_de"
              className="form-control"
              label="Microlocation Heading DE"
            />
          </TabPanel>
        </Grid>
        <Grid item md={12} xs={12}>
          <Box component="div" m={2} ml={0}>
            Microlocation Picture
            <Button className="location-image-button" variant="contained" component="label">
              <span>Upload Picture</span>
              <input
                type="file"
                id="micro_photo"
                onChange={handleMicrolocationPicture}
                className="hide"
                accept="image/*"
              />
            </Button>
          </Box>
          <Box component="div" m={2} ml={0}>
            {property.micro_location_image_preview !== "" && (
              <Box component="div" m={2} ml={0}>
                <Avatar
                  className="location-image"
                  alt="Microlocation Picture"
                  variant="square"
                  onClick={(e) => selectImageWithName(e, "micro_photo")}
                  src={property.micro_location_image_preview}
                />
              </Box>
            )}
          </Box>
        </Grid>
        <Grid item md={12} xs={12}>
          <p>Microlocation Description</p>
          <Paper square>
            <Tabs
              value={microlocation_description_tabindex}
              className="tabs-container"
              onChange={handleLocationTabChange('microlocation_description_tabindex')}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              aria-label="full width tabs example"
            >
              <Tab
                label={<LanguageTabEn />}
                {...a11yProps(0)}
              />
              <Tab
                label={<LanguageTabGn />}
                {...a11yProps(1)}
              />
            </Tabs>
          </Paper>
          <TabPanel
            value={microlocation_description_tabindex}
            index={0}
          >
            <TinyMCE
              id="text-microlocation_description_en"
              name="microlocation_description"
              content={property.microlocation_description_en}
              config={{
                plugins:
                  "autolink link image lists print preview",
                toolbar:
                  "undo redo | bold italic | alignleft aligncenter alignright | styleselect",
                // menubar: 'file edit insert view format table tools help'
                menubar: false,
                preview_styles: false,
                branding: false
              }}
              onChange={handleEditorChange}
            />
          </TabPanel>
          <TabPanel
            value={microlocation_description_tabindex}
            index={1}
          >
            <TinyMCE
              id="text-microlocation_description_de"
              name="microlocation_description_de"
              content={property.microlocation_description_de}
              config={{
                plugins:
                  "autolink link image lists print preview",
                toolbar:
                  "undo redo | bold italic | alignleft aligncenter alignright | styleselect",
                // menubar: 'file edit insert view format table tools help'
                menubar: false,
                preview_styles: false,
                branding: false
              }}
              onChange={handleEditorChange}
            />
          </TabPanel>
        </Grid>
      </Grid>    
    </>
  );
}

export default DirectPropertyDetailEdit;
export { DirectPropertyDetailEdit };
