import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import { connect } from 'react-redux';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { Typography, Link, Dialog, DialogContent, Button } from '@material-ui/core'
import NotificationBox from './../../components/GeneralComponent/NotificationBox';
import { userActions, alertActions } from '../../_actions';
import { history } from '../../_helpers';
import i18n from "../../i18n";
import "./styles.scss";
import ConfirmModal from "../../components/ConfirmModal";

class LoginPage extends Component {
    constructor(props) {
        super(props);

        var isConfirm = false;
        if (history.location && history.location.search === "?success") {
            isConfirm = true;
        }

        this.state = {
            user: {
                email: "",
                password: "",
            },
            confirmModalStatus: false,
            input_notify: false,
            isConfirm: isConfirm,
            reset_email_res: {},
        }
        this.props.clearAlerts();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.reset_email_res !== prevState.reset_email_res) {
            return {
                ...prevState,
                reset_email_res: nextProps.reset_email_res,
            };
        }
        return null;
    }

    handleChange = (event) => {
        const { user } = this.state;
        user[event.target.name] = event.target.value;
        this.setState({ user });
    }

    openConfirmModal = () => {        
        const email = this.state.user.email;
        if (!email.match(/.{2,}@{1}.{2,}\..+/)) {
            this.setState({ input_notify: true });
            setTimeout(() => { 
                this.setState({ input_notify: false });
            }, 2000);
            return;
        }
        
        this.setState({ confirmModalStatus: true });
    }

    closeConfirmModal = () => {
        this.setState({ confirmModalStatus: false });
    }

    handleSendEmail = async () => {
        await this.props.sendResetEmail(this.state.user.email);
        this.closeConfirmModal();
    }

    generateToken(length) {
        return Math.random().toString(36).substr(2, length);
    }

    handleSubmit = () => {
        this.props.clearAlerts();

        var email_id = this.state.user.email;
        var password = this.state.user.password;


        if (email_id && password) {
            this.props.login(email_id, password);
        }
// TODO FIX AUTH
        setTimeout(() => {
            const historyState = history.location.state;
            if (this.props.loggedIn !== undefined && this.props.loggedIn) {
                if (historyState && historyState !== undefined && historyState.next && historyState.next !== undefined) {
                    window.location = historyState.next;
                } else {
                    window.location = "/";
                }
            }
        }, 1000)
    }

    render() {
        const { user } = this.state;
        const { alert } = this.props;

        return (
            <div className='login-box'>
                {/* <Button variant="outlined" color="default" className="btn-primary btn-social-login">{i18n.t('Login with Google')}</Button> */}

                <ValidatorForm
                    className='default-form'
                    onSubmit={this.handleSubmit}
                    ref="form"
                    autoComplete="off">

                    {alert.message &&
                        <NotificationBox open={true} variant={alert.type} message={alert.message} />
                    }

                    {this.state.input_notify && 
                        <NotificationBox open={true} variant={'error'} message={'Mail input incorrect'} />
                    }

                    <TextValidator
                        fullWidth
                        value={user.email}
                        onChange={this.handleChange}
                        name="email"
                        className="form-control"
                        id="outlined-name"
                        label={i18n.t('Email Address')}
                        margin="normal"
                        variant="outlined"
                        validators={['required', 'isEmail']}
                        errorMessages={[i18n.t('this field is required'), i18n.t('Email address is not valid')]}
                    />
                    <TextValidator
                        fullWidth
                        value={user.password}
                        onChange={this.handleChange}
                        name="password"
                        type="password"
                        className="form-control"
                        id="outlined-name"
                        label={i18n.t('Password')}
                        margin="normal"
                        variant="outlined"
                        validators={['required']}
                        errorMessages={[i18n.t('this field is required')]}
                    />
                    <Typography align='left' variant="body2">
                        <Link className='primary-link' onClick={this.openConfirmModal}>
                            {i18n.t('Forgot password? Click here to reset your password')}
                        </Link>
                    </Typography>

                    <Button type="submit" variant="outlined" color="default" className="btn-primary btn-filled-out btn-submit">{i18n.t('Login')}</Button>

                </ValidatorForm>

                <Typography align='center' variant="body2">
                    <NavLink to="/register" className='primary-link'>{i18n.t('Not registered yet? Click here to register')}</NavLink>
                </Typography>

                <ConfirmModal 
                    open={this.state.confirmModalStatus} 
                    handleClose={this.closeConfirmModal} 
                    handleConfirm={this.handleSendEmail} 
                    email={this.state.user.email} 
                />

                <Dialog
                    open={this.state.isConfirm}
                    onClose={() => this.setState({ isConfirm: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent className='modal-success'>
                        <Typography align='center' color="initial" variant="body2">
                            {i18n.t('Thank you for confirming your email address. You can now log in.')}
                        </Typography>

                        <Typography align='center' variant="body2">
                            <Button variant="outlined" color="default" onClick={() => this.setState({ isConfirm: false })} className="btn-primary btn-filled-out btn-login btn-width-300">{i18n.t('Ok')}</Button>
                        </Typography>
                    </DialogContent>
                </Dialog>
            </div>
        )
    }
}

function mapState(state) {
    const { loggedIn } = state.authentication;
    const { alert, users } = state;
    const { reset_email_res } = state.users;
    return { loggedIn, alert, reset_email_res, users };
}

const actionCreators = {
    clearAlerts: alertActions.clear,
    login: userActions.login,
    logout: userActions.logout,
    sendResetEmail: userActions.sendResetEmail,
};

const connectedLoginPage = connect(mapState, actionCreators)(LoginPage);
export { connectedLoginPage as LoginPage };

export default LoginPage;