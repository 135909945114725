import React, { Component } from 'react';
import { Grid, Container } from '@material-ui/core';
import i18n from "../../i18n";
import "./styles.scss";


class FooterComponent extends Component {
    render() {
        return (
            <footer className='footer'>
                <Container>
                    <Grid container spacing={3}>
                        <Grid item md={12} xs={12}>
                            <a href="https://www.immobilium.io" target="_blank">More Information</a>
                        </Grid>
                    </Grid>

                    <div className="footer-bottom">
                        <p>&copy; 2022 Immobilium and Blockstruct. {i18n.t('All rights reserved')}.</p>
                    </div>
                </Container>
            </footer>
        )
    }
}

export default FooterComponent;