import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Card, CardContent, Button, Grid, TextField, FormControl, MenuItem } from '@material-ui/core';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import PhoneValidator from '../../components/PhoneValidatorElement'
import SelectValidator from '../../components/SelectValidatorElement'
import CountryData from '../../_helpers/country-nationality.json'
import { NavBarComponent as DasboardNavBarComponent } from '../../components/DashboardComponent/common/NavBarComponent';
import NotificationBox from '../../components/GeneralComponent/NotificationBox';
import { useConfirm } from '../../components/Confirm';
import { withRouter } from '../../_helpers';
import { userManageService } from '../../_services';
import { userActions, alertActions } from '../../_actions';
import DatePicker from 'react-datepicker'
import i18n from '../../i18n'
import 'react-datepicker/dist/react-datepicker.css'

import './styles.scss';

const EditUserPage = ({
  profile_data,
  params,
  navigate,
  alert,
  clearAlerts,
  successAlerts,
  errorAlerts,
  logOut,
}) => {
  const { isConfirmed } = useConfirm();

  const [user, setUser] = useState({
    first_name: '',
    last_name: '',
    email_id: '',
    address: '',
    postal_code: '',
    contact_no: '',
    birth_date: '',
    city: '',
    phone: '',
    nationality: '',
    country: '',
    profile_image: '',
    // 'is_kyc_verified': '',
    language: '',
    wallet_address: '',
    status: '',
    theme: '',
    // 'kyc_session_id': '',
    // 'kyc_session_started': '',
    // 'kyc_session_updated': '',
    // 'kyc_session_status': '',
    // 'kyc_session_fail_reason': '',
    is_verified: false,
    is_admin: false,
  });

  useEffect(() => {
    clearAlerts();
    loadUserProfile();

    ValidatorForm.addValidationRule('isPhoneNumber', (value) => {
      if (value.length < 5) {
        return false;
      }
      return true;
    });

    return () => {
      ValidatorForm.removeValidationRule('isPhoneNumber');
    }
  }, []);

  const loadUserProfile = () => {
    const id = params?.id;
    (async () => {
      if (id) {
        const { error, data } = await userManageService.getUser(id);
        const user_data = {
          ...data,
          is_verified: !!data.is_verified,
          is_admin: !!data.is_admin,
          birth_date: data.birth_date ? moment(data.birth_date).toDate() : ''
        }
        setUser(user_data);
      } else {
        setUser({
          first_name: '',
          last_name: '',
          email_id: '',
          password: '',
        })
      }
    })();
  }

  const handleUpdate = async () => {
    clearAlerts();
    try {
      const {error, message} = await userManageService.updateUser(user.user_id, user);
      if (error) {
        errorAlerts(message || 'Something went wrong')
      } else {
        successAlerts('User updated successfully')
        setTimeout(() => {
          navigate("/dashboard/users")
        }, 2000);
      }
    } catch (e) {
      errorAlerts('Something went wrong')  
    }
  }

  const handleRegister = async () => {
    clearAlerts();
    try {
      const {error, message} = await userManageService.addUser(user);
      if (error) {
        errorAlerts(message || 'Something went wrong')  
      } else {
        successAlerts('User added successfully')
        navigate('/dashboard/users');
      }
    } catch (e) {
      errorAlerts('Something went wrong')  
    }
  }

  const handleVerify = async () => {
    clearAlerts();
    try {
      const {error, message} = await userManageService.updateUser(user.user_id, {is_verified: true});
      if (error) {
        errorAlerts(message || 'Something went wrong')
      } else {
        successAlerts('User updated successfully')
        setUser({
          ...user,
          is_verified: true,
        })
      }
    } catch (e) {
      errorAlerts('Something went wrong')  
    }
  }

  const handleAdminPrivilege = async (isAdmin) => {
    clearAlerts();
    const confirmed = await isConfirmed({
      title: 'Admin Privilege?',
      message: `Are you sure you want to ${isAdmin ? 'assign' : 'remove'} privilege on this user?`,
      okay: 'Yes',
      cancel: 'No',
    });

    if (!confirmed) {
      return;
    }

    const handler = isAdmin ? userManageService.setUserToAdmin : userManageService.removeUserFromAdmin;
    try {
      const {error, message} = await handler(user.user_id);
      if (error) {
        errorAlerts(message || 'Something went wrong')  
      } else {
        successAlerts('User updated successfully')

        if (user.email_id === profile_data.email_id && !isAdmin) {
          logOut();
        } else {
          loadUserProfile();
        }
      }
    } catch (e) {
      errorAlerts('Something went wrong')  
    }
  }

  const handleDelete = async () => {
    clearAlerts();
    const confirmed = await isConfirmed({
      title: 'Delete User?',
      message: 'Are you sure you want to delete this user?',
      okay: 'Yes',
      cancel: 'No',
    });

    if (!confirmed) {
      return;
    }

    try {
      const {error, message} = await userManageService.deleteUser(user.user_id);
      if (error) {
        errorAlerts(message || 'Something went wrong')  
      } else {
        successAlerts('User deleted successfully')
        if (user.email_id === profile_data.email_id && !isAdmin) {
          logOut();
        } else {
          navigate('/dashboard/users');
        }
      }
    } catch (e) {
      errorAlerts('Something went wrong')  
    }
  }

  const generatePassword = () => {
    const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const passwordLength = 8;
    let password = '';
    for (let i = 0; i <= passwordLength; i++) {
      var randomNumber = Math.floor(Math.random() * chars.length);
      password += chars.substring(randomNumber, randomNumber +1);
    }
    setUser({ ...user, password });
  }

  const handleChange = (event) => {
    setUser({ ...user, [event.target.name]: event.target.value })
  }

  const handleBirthDateChange = birth_date => {
    setUser({ ...user, birth_date })
  };

  return (
    <div className="main-content edit-user-page">
      <DasboardNavBarComponent pageName="manage-users" />

      {alert.message && (
        <NotificationBox
          open={true}
          variant={alert.type}
          message={alert.message}
        />
      )}

      {!user.user_id &&
        <>
          <h3>New User</h3>
          <Card>
            <CardContent>
              <ValidatorForm
                className="new-form default-form"
                onSubmit={handleRegister}
                autoComplete="off"
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <label className="form-label">{i18n.t("First name")} *</label>
                    <TextValidator
                      placeholder={i18n.t("Enter First name")}
                      name="first_name"
                      id="first_name"
                      value={user.first_name}
                      onChange={handleChange}
                      variant="outlined"
                      className="form-control"
                      validators={['required']}
                      errorMessages={[i18n.t('First name is required!')]}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <label className="form-label">{i18n.t("Last name")} *</label>
                    <TextValidator
                      placeholder={i18n.t("Enter Last name")}
                      name="last_name"
                      value={user.last_name}
                      onChange={handleChange}
                      variant="outlined"
                      className="form-control"
                      validators={['required']}
                      errorMessages={[i18n.t('Last name is required!')]}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <label className="form-label">{i18n.t("Email Address")} *</label>
                    <TextValidator
                      placeholder={i18n.t("Enter Email Address")}
                      name="email_id"
                      value={user.email_id}
                      onChange={handleChange}
                      variant="outlined"
                      className="form-control"
                      validators={['required', 'isEmail']}
                      errorMessages={[i18n.t('Email is required!'), i18n.t('Email address is not valid')]}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <label className="form-label password-label">
                      {i18n.t("Password")} *
                      <Button type="button" variant="text" onClick={generatePassword}>
                        {i18n.t('Generate')}
                      </Button>
                    </label>
                    <TextValidator
                      autoComplete="off"
                      value={user.password}
                      onChange={handleChange}
                      name="password"
                      className="form-control"
                      variant="outlined"
                      validators={['required']}
                      errorMessages={[i18n.t('this field is required')]}
                    />                    
                  </Grid>

                  <Grid item xs={12} style={{ textAlign: 'right' }}>
                    <Button className="btn btn-secondary" type="submit" variant={"contained"}>
                      {i18n.t('Save')}
                    </Button>
                  </Grid>
                </Grid>
              </ValidatorForm>
            </CardContent>
          </Card>
        </>
      }

      {!!user.user_id &&
        <>
          <h3>User Info</h3>
          <Card>
            <CardContent>
              <ValidatorForm
                className="default-form"
                onSubmit={handleUpdate}
                autoComplete="off"
              >
                <Grid container spacing={2}>
                  <Grid item md={4} xs={12}>
                    <label className="form-label">{i18n.t("First name")} *</label>
                    <TextValidator
                      placeholder={i18n.t("Enter First name")}
                      name="first_name"
                      id="first_name"
                      value={user.first_name}
                      onChange={handleChange}
                      variant="outlined"
                      fullWidth
                      className="form-control"
                      validators={['required']}
                      errorMessages={[i18n.t('First name is required!')]}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <label className="form-label">{i18n.t("Address")} *</label>
                    <TextValidator
                      placeholder={i18n.t("Enter Address")}
                      name="address"
                      value={user.address}
                      onChange={handleChange}
                      variant="outlined"
                      fullWidth
                      className="form-control"
                      validators={['required']}
                      errorMessages={[i18n.t('Address is required!')]}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <label className="form-label">{i18n.t("Email Address")} *</label>
                    <TextValidator
                      placeholder={i18n.t("Enter Email Address")}
                      name="email_id"
                      value={user.email_id}
                      onChange={handleChange}
                      variant="outlined"
                      fullWidth
                      disabled
                      className="form-control"
                      validators={['required']}
                      errorMessages={[i18n.t('Email is required!')]}
                    />
                  </Grid>

                  <Grid item md={4} xs={12}>
                    <label className="form-label">{i18n.t("Last name")} *</label>
                    <TextValidator
                      placeholder={i18n.t("Enter Last name")}
                      name="last_name"
                      value={user.last_name}
                      onChange={handleChange}
                      variant="outlined"
                      fullWidth
                      className="form-control"
                      validators={['required']}
                      errorMessages={[i18n.t('Last name is required!')]}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <label className="form-label">{i18n.t("Postal Code")} *</label>
                    <TextValidator
                      placeholder={i18n.t("Enter Postal Code")}
                      name="postal_code"
                      onChange={handleChange}
                      value={user.postal_code}
                      variant="outlined"
                      fullWidth
                      className="form-control"
                      validators={['required']}
                      errorMessages={[i18n.t('Postal code is required!')]}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <label className="form-label">{i18n.t("Mobile phone number")} *</label>
                    <PhoneValidator
                      name="contact_no"
                      onChange={handleChange}
                      value={user.contact_no}
                      phoneNumber={user.contact_no}
                      country='at'
                      validators={['isPhoneNumber']}
                      errorMessages={[i18n.t('Phone number is required!')]}
                    />
                  </Grid>

                  <Grid item md={4} xs={12} className="bithdate-container">
                    <label className="form-label">{i18n.t("Birthdate")}</label>
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      className="date-picker-birthdate"
                      isClearable={true}
                      placeholderText={i18n.t('Enter Birth date')}
                      selectsStart={true}
                      shouldCloseOnSelect={true}
                      disabledKeyboardNavigation={false}
                      showYearDropdown={true}
                      customInput={
                        <TextValidator
                          variant="outlined"
                          fullWidth
                          className="form-control"
                        />
                      }
                      forceShowMonthNavigation={true}
                      selected={user.birth_date || ''}
                      onChange={handleBirthDateChange}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <label className="form-label">{i18n.t("City")} *</label>
                    <TextValidator
                      placeholder={i18n.t('Enter city')}
                      name="city"
                      onChange={handleChange}
                      value={user.city}
                      variant="outlined"
                      fullWidth
                      className="form-control"
                      validators={['required']}
                      errorMessages={[i18n.t('City is required!')]}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <label className="form-label">{i18n.t("Phone")}</label>
                    <TextField
                      placeholder={i18n.t('Enter phone number')}
                      name="phone"
                      onChange={handleChange}
                      value={user.phone}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>

                  <Grid item md={4} xs={12}>
                    <label className="form-label">{i18n.t("Nationality")} *</label>
                    <FormControl variant="outlined" fullWidth>
                      <SelectValidator
                        name="nationality"
                        value={user.nationality}
                        onChange={handleChange}
                        fullWidth
                        displayEmpty
                        className="form-control"
                        validators={['required']}
                        errorMessages={[i18n.t('Nationality is required!')]}
                      >
                        <MenuItem value="" disabled>{i18n.t('Select Nationality')}</MenuItem>
                        {CountryData.map((country, index) => {
                          return (
                            <MenuItem key={index} value={country.nationality}>{i18n.t(country.nationality)}</MenuItem>
                          )
                        })}
                      </SelectValidator>
                    </FormControl>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <label className="form-label">{i18n.t("Country")} *</label>
                    <FormControl variant="outlined" fullWidth>
                      <SelectValidator
                        name="country"
                        value={user.country}
                        onChange={handleChange}
                        fullWidth
                        displayEmpty
                        className="form-control"
                        validators={['required']}
                        errorMessages={[i18n.t('Country is required!')]}
                      >
                        <MenuItem value="" disabled>{i18n.t('Select Country')}</MenuItem>
                        {CountryData.map((country, index) => {
                          return (
                            <MenuItem key={index} value={country.en_short_name}>{i18n.t(country.en_short_name)}</MenuItem>
                          )
                        })}
                      </SelectValidator>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} style={{ textAlign: 'right' }}>
                    <Button className="btn btn-secondary" type="submit" variant={"contained"}>
                      {i18n.t('Update')}
                    </Button>
                  </Grid>
                </Grid>
              </ValidatorForm>
            </CardContent>
          </Card>

          <h3>User Status</h3>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item md={3} sm={6} xs={12} className="label">Created At</Grid>
                <Grid item md={9} sm={6} xs={12}>{moment(user.createdAt).format('dddd, MMMM Do YYYY, h:mm:ss a')}</Grid>
                <Grid item md={3} sm={6} xs={12} className="label">Verification Status</Grid>
                <Grid item md={9} sm={6} xs={12}>
                  {user.is_verified
                    ? 'VERIFIED'
                    : (
                      <>
                        NOT VERIFIED
                        <Button variant="contained" color="primary" style={{marginLeft: 16}} onClick={handleVerify}>Set to verified</Button>
                      </>
                    )
                  }
                </Grid>
                <Grid item md={3} sm={6} xs={12} className="label">Password</Grid>
                <Grid item md={9} sm={6} xs={12}>
                  <Button variant="contained" color="primary">Reset Password</Button>
                </Grid>
                <Grid item md={3} sm={6} xs={12} className="label">Admin Privilege</Grid>
                <Grid item md={9} sm={6} xs={12}>
                  {user.is_admin
                    ? <Button variant="contained" color="primary" onClick={() => handleAdminPrivilege(false)}>Remove Privilege</Button>
                    : <Button variant="contained" color="primary" onClick={() => handleAdminPrivilege(true)}>Set Privilege</Button>
                  }
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          <h3 className='danger'>Danger Area</h3>
          <Card className='danger'>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item md={3} sm={6} xs={12} className="label">Delete Account</Grid>
                <Grid item md={9} sm={6} xs={12}>
                  <Button variant="contained" className="danger" onClick={handleDelete}>Delete</Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </>
      }
    </div>
  )
}

function mapState(state) {
  const { users: { profile_data }, alert } = state;
  return { profile_data, alert };
}
const actionCreators = {
  clearAlerts: alertActions.clear,
  successAlerts: alertActions.success,
  errorAlerts: alertActions.error,
  logOut: userActions.logout
};


const connectedPage = connect(mapState, actionCreators)(withRouter(EditUserPage));
export { connectedPage as EditUserPage };
