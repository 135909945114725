import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dialog, DialogContent, Card, CardContent, Grid, Button, FormControlLabel } from '@material-ui/core';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import CheckIcon from '@material-ui/icons/Check';
import SpeedIcon from '@material-ui/icons/Speed';
import CloseIcon from '@material-ui/icons/Close';
import GetAppIcon from '@material-ui/icons/GetApp';
import { userActions, alertActions, generalActions } from '../../../_actions';
import i18n from "../../../i18n";
import CheckboxValidatorElement from '../../../components/CheckboxValidatorElement';
import NotificationBox from '../../../components/GeneralComponent/NotificationBox';
import ProgressiveButton from '../../GeneralComponent/ProgressiveButton';
import 'react-circular-progressbar/dist/styles.css';
import "./styles.scss";

const MySwal = withReactContent(Swal)


class InvestmentModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeStep: 1,
            tncCheck: false,
            factsheetCheck: false,
            riskCheck: false,
            property: {},
            investment_details: {
                _property_id: 0,
                _user_id: 0,
                amount: '',
                price_per_share: 0,
                investment_amount: 0,
                payment_method: "account balance"
            },
            verificationState: {
                generalInformation: false,
                verification: false,
                paymentAccount: false,
                walletIntegration: false
            },
            user_details: {
                user_id: 0
            },
            veriftPaymentStatus: false
        }
    }

    componentDidMount() {
        ValidatorForm.addValidationRule('isTruthy', value => value);
        this.props.clearAlerts();
    }


    componentWillUnmount() {
        ValidatorForm.removeValidationRule('isTruthy');
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { users } = nextProps;
        const { profile_data } = users;
        if (profile_data && profile_data !== prevState.user_details) {
            return {
                ...prevState,
                investment_details: {
                    ...prevState.investment_details,
                    _user_id: profile_data.user_id,
                },
                verificationState: {
                    ...prevState.verificationState,
                    generalInformation: (profile_data.address !== '' && profile_data.address !== null) ? true : false,
                    verification: profile_data.is_kyc_verified === 1 ? true : false,
                    paymentAccount: (profile_data.iban_number !== '' && profile_data.iban_number !== null) ? true : false,
                    walletIntegration: (profile_data.wallet_address !== '' && profile_data.wallet_address !== null) ? true : false,
                },
                user_details: profile_data
            }
        }

        if (nextProps.property) {
            return {
                ...prevState,
                property: nextProps.property,
                investment_details: {
                    ...prevState.investment_details,
                    _property_id: nextProps.property.property_id,
                    price_per_share: nextProps.property.price_per_share,
                }
            }
        }
        return null;
    }

    isValidAmount = (fieldValue) => {
        if (isNaN(fieldValue) || fieldValue % 1 !== 0 || fieldValue.indexOf('.') !== -1) {
            return false;
        }
        return true;
    }

    isNotEmptyOrSpaces = (str) => {
        if (typeof str !== 'string') {
            return !isNaN(parseFloat(str)) && isFinite(str);
        } else {
            return !isNaN(parseFloat(str)) && isFinite(str) && (typeof str === 'string');
        }
    }


    getInvestmentAmount = (minInvestment, amount) => {
        if (minInvestment && amount) {
            return (minInvestment * amount).toFixed(2);
        }
        return 0;
    }

    handleInputChange = (event) => {
        var fieldName = event.target.name;
        var fieldValue = event.target.value;
        var amount = parseInt(this.isNotEmptyOrSpaces(fieldValue) ? fieldValue : 0);
        if (this.isValidAmount(fieldValue)) {
            this.setState(prevState => ({
                investment_details: {
                    ...prevState.investment_details,
                    [fieldName]: amount,
                    'investment_amount': parseFloat(this.getInvestmentAmount(prevState.investment_details.price_per_share, amount))
                }
            }));
        } else {
            this.setState(prevState => ({
                investment_details: {
                    ...prevState.investment_details,
                    [fieldName]: parseInt(prevState.investment_details.amount ? prevState.investment_details.amount : 0),
                    'investment_amount': parseFloat(this.getInvestmentAmount(prevState.investment_details.price_per_share, prevState.investment_details.amount))
                }
            }));
        }
    };

    closeModal = () => {
        this.setState(prevState => ({
            ...prevState,
            activeStep: 1,
            tncCheck: false,
            factsheetCheck: false,
            riskCheck: false,
            investment_details: {
                _property_id: 0,
                _user_id: 0,
                amount: '',
                price_per_share: 0,
                investment_amount: 0,
                payment_method: "account balance"
            },
        }));
        this.props.handleClose();
    }


    verifyPaymentDetails() {
        console.log('test')
        const { propertyId } = this.props;
        const { investment_details } = this.state;
        this.setState({ veriftPaymentStatus: true })
        this.props.verifyPaymentDetails(propertyId, investment_details).then(paymentDetailVerificationRes => {
            this.setState({ veriftPaymentStatus: false })
            if (paymentDetailVerificationRes && paymentDetailVerificationRes.status) {
                const userStepCompleteStatus = this.checkVerificationState();
                this.setState(prevState => ({
                    activeStep: userStepCompleteStatus ? prevState.activeStep + 2 : prevState.activeStep + 1
                }));
            } else {
                MySwal.fire({
                    title: <div>{i18n.t('Error')}</div>,
                    icon: 'error',
                    html: <div>
                        <h4>{paymentDetailVerificationRes.message}</h4>
                    </div>,
                })
                return false;
            }
        })
    }


    goToNextStep = (stepType) => {
        this.form.isFormValid(false).then((isValid) => {
            if (isValid) {
                if (stepType === 'Payment method') {
                    this.verifyPaymentDetails()
                } else {
                    this.setState(prevState => ({
                        activeStep: prevState.activeStep + 1
                    }));
                }
            }
        })
    }

    handleChange = (name) => (event) => {
        this.setState({ [name]: event.target.checked });
    };

    formatePrice = (val) => {
        if (val !== undefined && val !== null && !isNaN(val)) {
            var parts = val.toString().split(".");
            if (parts.length > 1) {
                val = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".") + (parts[1] ? "," + parts[1] : "");
            }
            val = this.numberWithCommas(val);
            return val;
        }
    }

    numberWithCommas = (x) => {
        if (x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        }
        return x;
    }

    checkVerificationState = () => {
        const { generalInformation, verification, paymentAccount, walletIntegration } = this.state.verificationState;
        if (generalInformation && verification && paymentAccount && walletIntegration) {
            return true;
        }
        return false;
    }

    submit = () => {
        this.form.isFormValid(false).then((isValid) => {
            if (isValid) {
                this.props.addUserInvestment(this.state.investment_details).then(value => {
                    this.goToNextStep("Contract & conditions");
                    this.props.getUserCommittedInvestment();
                    this.props.getUserInvestment();
                    setTimeout(() => {
                        this.setState({ user_details: { user_id: 0 } })
                        this.props.clearAlerts()
                    }, 2000);
                })

            }
        })
    }

    handleSubmit = () => { }


    render() {
        const { property, investment_details, activeStep, verificationState, veriftPaymentStatus } = this.state;
        const { alert } = this.props;
        return (

            <Dialog className='investment-modal' onClose={this.closeModal} open={this.props.open}>
                <DialogContent>
                    {alert.message && <NotificationBox open={true} variant={alert.type} message={alert.message} />}
                    <Button className="btn-modal-close" onClick={this.closeModal}><CloseIcon /></Button>
                    <ValidatorForm
                        className='property-inverstment-form'
                        onSubmit={this.handleSubmit}
                        ref={(r) => { this.form = r; }}
                        autoComplete="off"
                        instantValidate
                    >
                        <h2 className="modal-title">{i18n.t('Investment')} </h2>
                        {property && <div className="title-box">
                            <div>
                                {property.property_images && property.property_images.length > 0 && (
                                    <img src={property.property_images[0].image_path} className="property-img" alt="Property" />
                                )}
                            </div>
                            <div className="assets-box">
                                <p className="txt-asset-title">{property.title}</p>
                                <p className="txt-asset-symbol">{property.token_name}</p>
                            </div>
                        </div>}
                        {property && <div className="content-box">
                            {activeStep === 1 && (<div className="step-wizard block-1">
                                <Card className="card-box">
                                    <CardContent>
                                        <p>{i18n.t('How many shares would you like to draw?')}</p>

                                        <TextValidator
                                            fullWidth
                                            value={investment_details.amount}
                                            onChange={this.handleInputChange}
                                            id="text-amount"
                                            name="amount"
                                            className="form-control"
                                            label={i18n.t("Enter amount *")}
                                            color="secondary"
                                            validators={['required', 'isNumber', 'minNumber:1']}
                                            variant="outlined"
                                            errorMessages={[
                                                i18n.t('This field is required!'),
                                                i18n.t('This field only contains numbers'),
                                                i18n.t('Amount must be greater than 0'),
                                            ]}
                                        />

                                        <table className="table">
                                            <tbody>
                                                <tr>
                                                    <td>{i18n.t('Price per share')}</td>
                                                    <td><strong>&#36; {this.formatePrice(investment_details.price_per_share)}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td>{i18n.t('Your Investment amount')}</td>
                                                    <td><strong>&#36; {this.formatePrice(investment_details.investment_amount)}</strong></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </CardContent>
                                </Card>

                                <div className="payment-method-box">
                                    <p className="title-text">{i18n.t('Payment method')}</p>

                                    <Grid container spacing={4}>
                                        <Grid item md={4} xs={12}>
                                            <Card className="payment-type active">
                                                <AccountBalanceIcon />
                                                <p>{i18n.t('Account balance')}</p>
                                            </Card>
                                        </Grid>
                                        <Grid item md={4} xs={12}>
                                            <Card className="payment-type disabled">
                                                <div className="coming-soon-title">{i18n.t('Coming soon')}</div>
                                                <p>{i18n.t("Bank transfer")}</p>
                                            </Card>
                                        </Grid>
                                        <Grid item md={4} xs={12}>
                                            <Card className="payment-type disabled">
                                                <div className="coming-soon-title">{i18n.t('Coming soon')}</div>
                                                <p>{i18n.t("Sofortüberweisung")}</p>
                                            </Card>
                                        </Grid>
                                        <Grid item md={4} xs={12}>
                                            <Card className="payment-type disabled">
                                                <div className="coming-soon-title">{i18n.t('Coming soon')}</div>
                                                <p>{i18n.t("Direct debit")}</p>
                                            </Card>
                                        </Grid>
                                        <Grid item md={4} xs={12}>
                                            <Card className="payment-type disabled">
                                                <div className="coming-soon-title">{i18n.t('Coming soon')}</div>
                                                <p>{i18n.t("Google pay")}</p>
                                            </Card>
                                        </Grid>
                                        <Grid item md={4} xs={12}>
                                            <Card className="payment-type disabled">
                                                <div className="coming-soon-title">{i18n.t('Coming soon')}</div>
                                                <p>{i18n.t("Apple pay")}</p>
                                            </Card>
                                        </Grid>
                                    </Grid>
                                </div>

                                <ProgressiveButton
                                    className="btn-action"
                                    variant="contained"
                                    loading={veriftPaymentStatus}
                                    handleButtonClick={() => this.goToNextStep("Payment method")}
                                >
                                    {i18n.t("Next")}
                                </ProgressiveButton>
                            </div>)}

                            {activeStep === 2 && (<div className="step-wizard block-2">
                                <Card className="card-box">
                                    <CardContent>
                                        <p>{i18n.t('Before you can invest successfully, you must complete the following information')} :</p>

                                        <table className="table">
                                            <tbody>
                                                <tr>
                                                    <td width="40">
                                                        <div className={"icon " + (verificationState.generalInformation === true ? 'success' : 'pending')}>
                                                            {verificationState.generalInformation === true ? <CheckIcon /> : <SpeedIcon />}
                                                        </div>
                                                    </td>
                                                    <td>{i18n.t('General Information')}</td>
                                                </tr>
                                                <tr>
                                                    <td width="40">
                                                        <div className={"icon " + (verificationState.verification === true ? 'success' : 'pending')}>
                                                            {verificationState.verification === true ? <CheckIcon /> : <SpeedIcon />}
                                                        </div>
                                                    </td>
                                                    <td>{i18n.t('Verification')}</td>
                                                </tr>
                                                <tr>
                                                    <td width="40">
                                                        <div className={"icon " + (verificationState.paymentAccount === true ? 'success' : 'pending')}>
                                                            {verificationState.paymentAccount === true ? <CheckIcon /> : <SpeedIcon />}
                                                        </div>
                                                    </td>
                                                    <td>{i18n.t('Payout Account')}</td>
                                                </tr>
                                                <tr>
                                                    <td width="40">
                                                        <div className={"icon " + (verificationState.walletIntegration === true ? 'success' : 'pending')}>
                                                            {verificationState.walletIntegration === true ? <CheckIcon /> : <SpeedIcon />}
                                                        </div>
                                                    </td>
                                                    <td>{i18n.t('Wallet Integration')}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </CardContent>
                                </Card>
                                {
                                    this.checkVerificationState() === true ?
                                        (<Button fullWidth className="btn-action" variant="contained" onClick={() => this.goToNextStep("Verification")}>{i18n.t('Next')}</Button>)
                                        :
                                        (<Link to="/dashboard/profile"><Button fullWidth className="btn-action" variant="contained">{i18n.t('Go To Profile')}</Button></Link>)
                                }
                            </div>)}

                            {activeStep === 3 && (<div className="step-wizard block-3">
                                <Card className="card-box">
                                    <CardContent>
                                        <p>{i18n.t('Review details of your investment')}</p>

                                        <table className="table details-table">
                                            <tbody>
                                                <tr>
                                                    <td>{i18n.t('Amount of shares')}</td>
                                                    <td>{this.formatePrice(investment_details.amount)}</td>
                                                </tr>
                                                <tr>
                                                    <td>{i18n.t('Price per share')}</td>
                                                    <td>&#36; {this.formatePrice(investment_details.price_per_share)}</td>
                                                </tr>
                                                <tr>
                                                    <td>{i18n.t('Your Investment amount')}</td>
                                                    <td>&#36; {this.formatePrice(investment_details.investment_amount)}</td>
                                                </tr>
                                                <tr>
                                                    <td>{i18n.t('Issuer')}</td>
                                                    <td>
                                                        {(property.issuer_details && property.issuer_details.first_name && property.issuer_details.last_name) ?
                                                            property.issuer_details.first_name + " " + property.issuer_details.last_name
                                                            : ''}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>{i18n.t('Payment method')}</td>
                                                    <td>{i18n.t('Account balance')}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </CardContent>
                                </Card>

                                <Card className="card-box">
                                    <CardContent>
                                        <p>{i18n.t('Contract Documents & Conditions')}</p>
                                        {(property && property.property_documents && property.property_documents.length > 0) && (
                                            <table className="table downloads-table">
                                                <tbody>
                                                    {property.property_documents.map((document, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td width="30"><GetAppIcon /></td>
                                                                <td>
                                                                    <a href={`${process.env.REACT_APP_API_URL}/properties/download/${document.file_path}`} download
                                                                    ><span className="doc-name">{document.description}</span></a>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>)}

                                        <div className="acceptance-box">
                                            <FormControlLabel
                                                control={
                                                    <CheckboxValidatorElement
                                                        onChange={this.handleChange('tncCheck')}
                                                        validators={['isTruthy']}
                                                        errorMessages={[i18n.t('Please accept Terms and Conditions to continue')]}
                                                        checked={this.state.tncCheck}
                                                        value={this.state.tncCheck}
                                                    />
                                                }
                                                label={<TnCLabel />}
                                            />

                                            <FormControlLabel
                                                control={
                                                    <CheckboxValidatorElement
                                                        checked={this.state.factsheetCheck}
                                                        onChange={this.handleChange('factsheetCheck')}
                                                        validators={['isTruthy']}
                                                        errorMessages={[i18n.t('Please accept contract / cancellation instruction to continue')]}
                                                        value={this.state.factsheetCheck}
                                                    />
                                                }
                                                label={<FactSheetLabel />}
                                            />

                                            <FormControlLabel
                                                control={
                                                    <CheckboxValidatorElement
                                                        checked={this.state.riskCheck}
                                                        onChange={this.handleChange('riskCheck')}
                                                        validators={['isTruthy']}
                                                        errorMessages={[i18n.t('Please risk to continue')]}
                                                        value={this.state.riskCheck}
                                                    />
                                                }
                                                label={<RiskLabel />}
                                            />
                                        </div>
                                    </CardContent>
                                </Card>

                                <Button fullWidth className="btn-action" variant="contained" onClick={() => this.submit()}>{i18n.t('Invest')}</Button>
                            </div>)}

                            {activeStep === 4 && (<div className="step-wizard block-4">
                                <Card className="card-box">
                                    <CardContent>
                                        <p className="txt-style-1">{i18n.t('Congratulations on your new investment!')}</p>
                                        <p className="txt-style-2">{i18n.t('You can track all your investments your dashboard.')}</p>
                                    </CardContent>
                                </Card>

                                <Link to="/dashboard"><Button fullWidth className="btn-action" variant="contained">{i18n.t('Go to dashboard')}</Button></Link>
                            </div>)}
                        </div>}
                    </ValidatorForm>
                </DialogContent>
            </Dialog>
        )
    }
}

const TnCLabel = () => {
    return (
        <p>{i18n.t(' have read the')} <a href="http://www.africau.edu/images/default/sample.pdf" target="_blank" rel="noopener noreferrer">{i18n.t('terms and conditions')}</a> {i18n.t('of ABCD1234 GmBH and accept them hereby.')}</p>
    )
}

const FactSheetLabel = () => {
    return (
        <p>{i18n.t('I am taking note of that')} <a href="http://www.africau.edu/images/default/sample.pdf" target="_blank" rel="noopener noreferrer">{i18n.t('contract / cancellation')}</a> {i18n.t('instruction and the')} <a href="http://www.africau.edu/images/default/sample.pdf" target="_blank" rel="noopener noreferrer">{i18n.t('Fact Sheet')}</a> {i18n.t('and accept them.')}</p>
    )
}

const RiskLabel = () => {
    return (
        <p>{i18n.t('I am aware of the')} <a href="http://www.africau.edu/images/default/sample.pdf" target="_blank" rel="noopener noreferrer">{i18n.t('risk')}</a>.</p>
    )
}

function mapState(state) {
    const { alert, users, general } = state;
    return { alert, users, general };
}


const actionCreators = {
    addUserInvestment: userActions.addUserInvestment,
    getUserCommittedInvestment: userActions.getUserCommittedInvestment,
    getUserInvestment: userActions.getUserInvestment,
    verifyPaymentDetails: generalActions.verifyPaymentDetails,
    clearAlerts: alertActions.clear,
}

export default connect(mapState, actionCreators)(InvestmentModal);
