import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, FormControlLabel } from '@material-ui/core';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import CheckboxValidatorElement from './../../components/CheckboxValidatorElement';
import RegistrationSuccessModal from './RegistrationSuccessModal';
import NotificationBox from './../../components/GeneralComponent/NotificationBox';
import { userActions, alertActions } from '../../_actions';
import i18n from "../../i18n";
import "./styles.scss";

class RegistrationPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            registrationStatus: false,
            user: {
                first_name: "",
                last_name: "",
                email_id: "",
                password: "",
                confirm_password: "",
                tnc: false,
            }
        }
        this.props.clearAlerts();
    }

    closeSuccessModal = () => {
        this.setState({ registrationStatus: false });
    }

    checkTnc = () => {
        const { user } = this.state;
        user['tnc'] = !user.tnc;
        this.setState({ user });
    }

    handleChange = (event) => {
        const { user } = this.state;
        user[event.target.name] = event.target.value;
        this.setState({ user });
    }

    componentDidMount() {
        ValidatorForm.addValidationRule('isTruthy', value => value);
        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            if (value !== this.state.user.password) {
                return false;
            }
            return true;
        });
    }

    componentWillUnmount() {
        ValidatorForm.removeValidationRule('isPasswordMatch');
        ValidatorForm.removeValidationRule('isTruthy');
    }

    handleSubmit = () => {
        this.props.clearAlerts();

        const { user } = this.state;
        const { first_name, email_id, password } = user;

        if (first_name && email_id && password) {
            this.props.register(user);
        }
    }

    static getDerivedStateFromProps(props, prevState) {
        if (props.alert && props.alert.type === "success" && !prevState.registrationStatus) {
            return {
                registrationStatus: true
            }
        }
        else {
            return {
                registrationStatus: false
            }
        }
    }


    render() {
        const { user } = this.state;
        const { alert } = this.props;

        return (
            <div className='registration-box'>
                {/* <div className='information-block'>
                    <Typography align='center' variant="body2">
                        * {i18n.t('Please complete the registration below to proceed.')}
                    </Typography>

                    <Typography align='center' variant="body2">
                        {i18n.t('Simply register with your Google Account or create your Immobilium account by filling in the information in the form below')}
                    </Typography>
                </div>

                <Button variant="outlined" color="default" className="btn-primary btn-social-login">{i18n.t('Login with Google')}</Button>

                <Typography align='center' variant="body2">
                    {i18n.t('or enter your information below')} :
                </Typography> */}

                <ValidatorForm
                    className='form'
                    onSubmit={this.handleSubmit}
                    ref="form"
                    autoComplete="off">


                    {alert.message && alert.type === "error" &&
                        <NotificationBox open={true} variant={alert.type} message={alert.message} />
                    }

                    <TextValidator
                        fullWidth
                        value={user.first_name}
                        onChange={this.handleChange}
                        name="first_name"
                        className="form-control"
                        label={i18n.t('First Name')}
                        margin="normal"
                        variant="outlined"
                        validators={['required']}
                        errorMessages={[i18n.t('this field is required')]}
                    />

                    <TextValidator
                        fullWidth
                        value={user.last_name}
                        onChange={this.handleChange}
                        name="last_name"
                        className="form-control"
                        label={i18n.t('Last Name')}
                        margin="normal"
                        variant="outlined"
                    />

                    <TextValidator
                        fullWidth
                        value={user.email_id}
                        onChange={this.handleChange}
                        name="email_id"
                        className="form-control"
                        label={i18n.t('Email Address')}
                        margin="normal"
                        variant="outlined"
                        validators={['required', 'isEmail']}
                        errorMessages={[i18n.t('this field is required'), i18n.t('Email address is not valid')]}
                    />
                    <TextValidator
                        fullWidth
                        autoComplete="off"
                        value={user.password}
                        onChange={this.handleChange}
                        name="password"
                        type="password"
                        className="form-control"
                        label={i18n.t('Password')}
                        margin="normal"
                        variant="outlined"
                        validators={['required']}
                        errorMessages={[i18n.t('this field is required')]}
                    />

                    <TextValidator
                        fullWidth
                        autoComplete="off"
                        value={user.confirm_password}
                        onChange={this.handleChange}
                        name="confirm_password"
                        type="password"
                        label={i18n.t('Confirm Password')}
                        margin="normal"
                        variant="outlined"
                        validators={['isPasswordMatch', 'required']}
                        errorMessages={[i18n.t('Both Password not match!'), i18n.t('this field is required')]}
                    />

                    <FormControlLabel
                        className='text-left'
                        control={
                            <CheckboxValidatorElement
                                onChange={this.checkTnc}
                                name="tnc"
                                validators={['isTruthy']}
                                errorMessages={[i18n.t('this field is required')]}
                                checked={user.tnc}
                                value={user.tnc}
                            />
                        }
                        label={i18n.t("I hereby agree to receive information about the current investments and agree to the GTC and the privacy policy.")}
                    />
                    <Button type="submit" variant="outlined" color="default" className="btn-primary btn-filled-out btn-submit">{i18n.t('Save')}</Button>

                </ValidatorForm>

                <RegistrationSuccessModal open={this.state.registrationStatus} handleClose={this.closeSuccessModal} />
            </div>
        )
    }
}

function mapState(state) {
    const { alert } = state;
    return { alert };
}

const actionCreators = {
    clearAlerts: alertActions.clear,
    register: userActions.register
};

const connectedComponent = connect(mapState, actionCreators)(RegistrationPage);
export { connectedComponent as RegistrationPage };
