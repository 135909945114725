import React, { useEffect, useState } from 'react';
import i18n from '../../i18n';
import { Button, Dialog, DialogContent } from '@material-ui/core';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import './styles.scss';

const WalletSeedBackupModal = ({
  seed,
  open,
  handleClose,
}) => {
  const [seeds, setSeeds] = useState([]);
  const [step, setStep] = useState(0);
  const [randSeeds, setRandSeeds] = useState([]);
  const [confirmSeeds, setConfirmSeeds] = useState([]);
  const [confirmedSeeds, setConfirmedSeeds] = useState([]);

  useEffect(() => {
    setSeeds((seed || '').split(' '))
  }, [seed])

  useEffect(() => {
    const array = [...seeds];
    let currentIndex = array.length,  randomIndex;
    while (currentIndex != 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
    setRandSeeds(array);
    setConfirmSeeds([...array]);
    setConfirmedSeeds([])
  }, [seeds])

  const infoPage = () => {
    return (
      <>
        <h1>{i18n.t(`No Backup, No Money`)}</h1>
        <div className='detail'>
          {i18n.t(`You must save the secret phrase. It is crucial for accessing your account`)}
        </div>
        <div className='action-sheet'>
          <Button
            className='btn-secondary'
            variant="contained"
            onClick={() => setStep(1)}
          >
            {i18n.t(`Back Up Now`)}
          </Button>
          <div className='divider'><div>{i18n.t(`or`)}</div></div>
          <a onClick={handleClose}>{i18n.t(`Do it later`)}</a>
        </div>
      </>
    )
  }

  const backupPage = () => {
    return (
      <>
        <h1>{i18n.t(`Save backup phrase`)}</h1>
        <div className='detail'>
          {
            i18n.t(`Since only you control your money, you'll need to save your backup phrase in case this app is deleted or`)
          }
          &nbsp;
          <a onClick={() => setStep(0)}>{i18n.t(`go back`)}</a>
        </div>
        <div>
          {i18n.t(`Please carefully write down these {{length}} words or`, { length: seeds.length })}
          &nbsp;
          <CopyToClipboard text={seed}>
            <a>{i18n.t(`copy them`)}</a>
          </CopyToClipboard>
        </div>
        <div className='seed-box'>
          {seeds.map(sd => <div key={sd}>{sd}</div>)}
        </div>
        <div>
          {i18n.t(`You will confirm this phrase on the next screen`)}
        </div>
        <Button
          className='btn-secondary'
          variant="contained"
          onClick={() => {
            resetConfirm();
            setStep(2);
          }}
        >
          {i18n.t(`I've written it down`)}
        </Button>
      </>
    )
  }

  const putConfirmSeed = (idx) => {
    const sd = confirmSeeds[idx];
    confirmSeeds.splice(idx, 1);
    setConfirmedSeeds([...confirmedSeeds, sd]);
    setConfirmSeeds([...confirmSeeds]);
  }

  const popConfirmSeed = (idx) => {
    const sd = confirmedSeeds[idx];
    confirmedSeeds.splice(idx, 1);
    setConfirmedSeeds([...confirmedSeeds]);
    setConfirmSeeds([...confirmSeeds, sd]);
  }

  const resetConfirm = () => {
    setConfirmedSeeds([]);
    setConfirmSeeds([...randSeeds]);
  }

  const confirmPage = () => {
    return (
      <>
        <h1>{i18n.t(`Confirm backup`)}</h1>
        <div className='detail'>
          {i18n.t(`Verify your backup phrase or`)}
          &nbsp;
          <a onClick={() => setStep(1)}>{i18n.t(`go back`)}</a>
        </div>        
        <div className='seed-box seed-box-selector seed-box-confirm'>
          {confirmedSeeds.map((sd, idx) => <div key={sd} onClick={() => popConfirmSeed(idx)}>{sd}</div>)}
        </div>
        <div>
          {i18n.t(`Please tap each word in the correct order`)}
        </div>
        <div className='seed-box seed-box-selector'>
          {confirmSeeds.map((sd, idx) => <div key={sd} onClick={() => putConfirmSeed(idx)}>{sd}</div>)}
        </div>
        <div className='action-sheet'>
          <Button
            className='btn-secondary'
            variant="contained"
            onClick={handleClose}
            disabled={confirmedSeeds.join(' ') != seed}
          >
            {i18n.t(`Confirm`)}
          </Button>
          <div className='divider'><div>{i18n.t(`or`)}</div></div>
          <div>
            <a onClick={resetConfirm}>{i18n.t(`Clear`)}</a>
            &nbsp;
            {i18n.t(`and tap again`)}
          </div>
        </div>
      </>
    )
  }

  const pages = [
    infoPage,
    backupPage,
    confirmPage,
  ];

  return (
    <Dialog
      open={open}
      className="wallet-seedbackup-container"
    >
      <DialogContent>
        {pages[step]()}
      </DialogContent>
    </Dialog>
  )
}

export default WalletSeedBackupModal;
export { WalletSeedBackupModal };
