import React, { Component } from 'react';
import { LinearProgress, Grid } from '@material-ui/core';
import "./styles.scss";

const PropertyPledgedBlock = (props) => {
    return (
        <>
            <p className='pledged-info'>50% pledged (&euro; {props.property.financing_volume / 2})</p>
            <LinearProgress
                variant="determinate"
                value={50}
            />
        </>
    )
};

class PropertyFinancialInformation extends Component {
    formatePrice = (val) => {
        if (val !== undefined && val !== null && !isNaN(val)) {
            var parts = val.toString().split(".");
            if (parts.length > 1) {
                val = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".") + (parts[1] ? "," + parts[1] : "");
            }
            val = this.numberWithCommas(val);
            return val;
        }
    }

    numberWithCommas = (x) => {
        if (x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        }
        return x;
    }

    calcuteDividendYield = (distribution_surplus, financing_volume) => {
        distribution_surplus = Number(distribution_surplus);
        financing_volume = Number(financing_volume);
        if (!isNaN(distribution_surplus / financing_volume)) {
            return (distribution_surplus / financing_volume).toFixed(2);
        }
        return 0;
    }


    render() {
        const { property } = this.props;
        return (
            property && (<div className='property-financial-information'>
                {property.property_type === "primary" ? (
                    <div className="financial-information-primary">
                        <p className='title-text'>Open for Investments</p>
                        <PropertyPledgedBlock property={property} />

                        <table className="financial-table">
                            <tbody>
                                <tr>
                                    <td>
                                        <p className='txt-title'>Funding Goal</p>
                                        <p className='txt-value'>&euro; {this.formatePrice(property.financing_volume)}</p>
                                    </td>
                                    <td>
                                        <p className='txt-title'>Shares available</p>
                                        <p className='txt-value'>{this.formatePrice(property.total_share_count / 2)}</p>
                                    </td>
                                    <td>
                                        <p className='txt-title'>Usable area</p>
                                        <p className='txt-value'>75m2</p>
                                    </td>
                                    <td>
                                        <p className='txt-title'>Year of construction</p>
                                        <p className='txt-value'>1955</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                ) : (
                        <div className="financial-information-secondary">
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={4} md={4}>
                                    <div className="value-block">
                                        <p className="txt-value block-1">
                                            <span>€ 104,90</span>
                                            <span className="txt-percentage">+ 4,9 %</span>
                                        </p>
                                        <p className="txt-title block-1">
                                            <span>Last Price Traded</span>
                                        </p>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={4} md={4}>
                                    <div className="value-block">
                                        <p className="txt-value block-2">
                                            <span>€ 104.900,00</span>
                                        </p>
                                        <p className="txt-title block-2">
                                            <span>Last Market Value</span>
                                        </p>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={4} md={4}>
                                    <div className="value-block">
                                        <p className="txt-value block-2">
                                            <span>€ {this.formatePrice(this.calcuteDividendYield(property.distribution_surplus, property.financing_volume))}</span>
                                        </p>
                                        <p className="txt-title block-2">
                                            <span>Net rental profit *</span>
                                        </p>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={4} md={4}>
                                    <div className="value-block">
                                        <p className="txt-value block-3">
                                            <span>€ 100,00</span>
                                        </p>
                                        <p className="txt-title block-3">
                                            <span>Initial Share Price (Jan 2020)</span>
                                        </p>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={4} md={4}>
                                    <div className="value-block">
                                        <p className="txt-value block-3">
                                            <span>1000</span>
                                        </p>
                                        <p className="txt-title block-3">
                                            <span>Issued Shares</span>
                                        </p>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={4} md={4}>
                                    <div className="value-block">
                                        <p className="txt-value block-3">
                                            <span>3,90 %</span>
                                        </p>
                                        <p className="txt-title block-3">
                                            <span>Return *</span>
                                        </p>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    )}
            </div>)
        )
    }
}

export default PropertyFinancialInformation;
