import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import ReactHtmlParser from 'react-html-parser';
import RoomIcon from '@material-ui/icons/Room';
import { initialState } from '../../_reducers/general.reducer';
import "./styles.scss";

const PropertyMarker = ({ text }) => <div className="marker"><RoomIcon /></div>;

class LocationDetails extends Component {
    getPropertyLocation = (property) => {
        return {
            lat: parseFloat(property.property_location.lat),
            lng: parseFloat(property.property_location.lng)
        }
    }

    getLanguageBasedMacroLocation = (property) => {
        var macrolocation_heading = initialState.languageCode === 'en' ? property.macrolocation_heading_en : property.macrolocation_heading_de;
        var macrolocation_description = initialState.languageCode === 'en' ? property.macrolocation_description_en : property.macrolocation_description_de;
        return {
            macrolocation_heading,
            macrolocation_description
        }
    }

    render() {
        const { property } = this.props;

        return (
            <div className='location-details'>
                {property && <div>
                    <h2 className="txt-title">Macro Location</h2>
                    <p className="title-text">{this.getLanguageBasedMacroLocation(property).macrolocation_heading}</p>
                    <div className="content-text">{ReactHtmlParser(this.getLanguageBasedMacroLocation(property).macrolocation_description)}</div>
                </div>}

                {property && property.property_location && <div style={{ height: '250px', width: '100%' }}>
                    <GoogleMapReact
                        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API_KEY }}
                        defaultZoom={parseInt(process.env.REACT_APP_MAP_ZOOM)}
                        center={this.getPropertyLocation(property)}
                    >
                        <PropertyMarker lat={property.property_location.lat} lng={property.property_location.lng} />
                    </GoogleMapReact>
                </div>}
            </div>
        )
    }
}

export default LocationDetails;