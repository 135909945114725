import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import { Button, Grid, LinearProgress, InputAdornment, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import CreditCardOutlinedIcon from '@material-ui/icons/CreditCardOutlined';
import i18n from "../../i18n";
import { NavBarComponent } from '../../components/DashboardComponent/common/NavBarComponent';
import PaymentDetailModal from '../../components/DashboardComponent/modals/PaymentDetailModal';
import DepositModal from '../../components/DashboardComponent/modals/DepositModal';
import WithdrawModal from '../../components/DashboardComponent/modals/WithdrawModal';
import DepositInfoModal from '../../components/DashboardComponent/modals/DepositInfoModal';
import WithdrawInfoModal from '../../components/DashboardComponent/modals/WithdrawInfoModal';
import { userActions } from '../../_actions';
import 'react-table/react-table.css';
import "./styles.scss";
import CloseIcon from "@material-ui/icons/Close";
import FooterComponent from "../../components/FooterComponent";

function checkProfitLoss(amount, transaction_mode, conversion) {
    var profitLossclassName = "";
    if (transaction_mode) {
        profitLossclassName = transaction_mode === "credit" ? 'txt-amount-profit' : 'txt-amount-loss';
    }
    if (conversion) {
        var parts = amount.toString().split(".");
        amount = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".") + (parts[1] ? "," + parts[1] : "");
    }
    return <span className={profitLossclassName}>{amount}</span>;
}


function formatePrice(n) {
    var val = Math.round(Number(n) * 100) / 100;
    var parts = val.toString().split(".");
    var num = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".") + (parts[1] ? "," + parts[1] : "");

    return num;
}

class PaymentsPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            maxProgress: 60000,
            paymentModalStatus: false,
            deposit: {
                amount: "0",
                modalStatus: false,
                confirmModalStatus: false,
            },
            withdraw: {
                amount: "0",
                modalStatus: false,
                confirmModalStatus: false,
            },
            paymentRefNum: "",
            profile_data: {},
            user_payments: {},
            isFooterOpen: false,
        }
    }

    componentDidMount() {
        this.props.getUserPayments()
    }


    static getDerivedStateFromProps(nextProps, prevState) {
        const { user_payments, profile_data } = nextProps.users;
        var update = {};
        if (profile_data && profile_data !== prevState.profile_data) {
            update = {
                ...update,
                profile_data: profile_data
            };
        }

        if (user_payments && user_payments.status === true && user_payments !== prevState.user_payments) {
            update = {
                ...update,
                user_payments: user_payments.data
            };
        }

        return update;
    }

    openPaymentModal = () => {
        this.setState({ paymentModalStatus: true });
    }

    closePaymentModal = () => {
        this.setState({ paymentModalStatus: false });
    }

    openDepositModal = () => {
        this.setState({ 
            deposit: { 
                ...this.state.deposit, 
                modalStatus: true, 
            }, 
        });
    }

    closeDepositModal = () => {
        this.setState({ 
            deposit: { 
                ...this.state.deposit, 
                modalStatus: false, 
            }, 
        });
    }

    openWithdrawModal = () => {
        this.setState({ 
            withdraw: { 
                ...this.state.withdraw, 
                modalStatus: true, 
            }, 
        });
    }

    closeWithdrawModal = () => {
        this.setState({ 
            withdraw: { 
                ...this.state.withdraw, 
                modalStatus: false, 
            }, 
        });
    }

    openDepositConfirmModal = () => {
        this.setState({ 
            deposit: {
                ...this.state.deposit, 
                confirmModalStatus: true 
            }
        });
    }

    closeDepositConfirmModal = () => {
        this.setState({ 
            deposit: { 
                ...this.state.deposit, 
                confirmModalStatus: false 
            }
        });
    }

    openWithdrawConfirmModal = () => {
        this.setState({ 
            withdraw: {
                ...this.state.withdraw, 
                confirmModalStatus: true 
            }
        });
    }

    closeWithdrawConfirmModal = () => {
        this.setState({ 
            withdraw: { 
                ...this.state.withdraw, 
                confirmModalStatus: false 
            }
        });
    }

    handleDepositReq = () => {
        this.props.depositMoneyRequest({
            deposit: this.state.deposit.amount,
            payment_ref_number: this.state.paymentRefNum,
        });
        this.closeDepositConfirmModal();
    }

    handleWithdrawReq = () => {
        this.props.depositMoneyRequest({
            withdraw: this.state.withdraw.amount,
            payment_ref_number: this.state.paymentRefNum,
        });
        this.closeWithdrawConfirmModal();
    }

    handleChange = (event) => {
        const amount = event.target.value;
        let prevState = this.state.deposit;
        if (event.target.name === "withdraw") {
            prevState = this.state.withdraw;
        }
        this.setState({ 
            [event.target.name]: { ...prevState, amount }, 
        });
    }

    nextDepositModal = () => {
        const paymentRefNum = this.generateReferenceNum();
        this.setState({
            paymentRefNum,
            deposit: {
                ...this.state.deposit,
                modalStatus: false,
                confirmModalStatus: true,
            }
        });
    }

    previousDepositModal = () => {
        this.setState({
            deposit: {
                ...this.state.deposit,
                modalStatus: true,
                confirmModalStatus: false,
            }
        });
    }

    nextWithdrawModal = () => {
        const paymentRefNum = this.generateReferenceNum();
        this.setState({
            paymentRefNum,
            withdraw: {
                ...this.state.withdraw,
                modalStatus: false,
                confirmModalStatus: true,
            }
        });
    }

    previousWithdrawModal = () => {
        this.setState({
            withdraw: {
                ...this.state.withdraw,
                modalStatus: true,
                confirmModalStatus: false,
            }
        });
    }

    generateReferenceNum = () => {
        const options = [
            'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
            '0123456789',
        ];
        const refNumberLen = 6;

        let paymentRefNum = '';
        for(let i = 0; i < refNumberLen; i++) {
            const possibleChars = options[i < 3 ? 0 : 1];
            paymentRefNum += possibleChars[Math.floor(Math.random() * possibleChars.length)];
        }
        
        return paymentRefNum;
    }

    calcProgress(amount) {
        var progress = (amount * 100) / this.state.maxProgress;
        return progress;
    }

    toggleFooter = () => {
        this.setState({ isFooterOpen: !this.state.isFooterOpen })
    }

    render() {
        const { profile_data, user_payments } = this.state;
        const isUserProfile = profile_data && Object.keys(profile_data).length > 0;

        return (
            <div className='dashboard-content'>
                <NavBarComponent pageName="payments" />

                <main className="main-content payments-page">
                    <Grid container spacing={3}>
                        <Grid item md={6} xs={12}>
                            {isUserProfile && <div className="widget-block">
                                <div className="widget-block-title">
                                    <div className="txt-icon"></div>
                                    <span className="txt-title">{i18n.t('Settlement Account')}</span>
                                    <span className="txt-value">&#36; {formatePrice(profile_data.balance + profile_data.committed_funds)}</span>
                                    <div className="clear"></div>
                                </div>
                                <div className="widget-block-content">
                                    <div className="account-card">
                                        <div className="funds-box">
                                            <p className="txt-amount-value">&#36; {formatePrice(profile_data.balance)}</p>
                                            <p className="txt-title">{i18n.t('Available Funds')}</p>
                                        </div>

                                        <div className="funds-box">
                                            <p className="txt-amount-value">&#36; {formatePrice(profile_data.committed_funds)}</p>
                                            <p className="txt-title">{i18n.t('Blocked Funds')}</p>
                                        </div>

                                        <div className="account-number-box">
                                            <p className="txt-value txt-upper">{profile_data.wallet_address.substring(0, 7)}</p>
                                            <p className="txt-title">{i18n.t('Account number')}</p>
                                        </div>

                                        <div className="name-box">
                                            <p className="txt-value">{profile_data.first_name + " " + profile_data.last_name}</p>
                                            <p className="txt-title">{i18n.t('Name')}</p>
                                        </div>

                                        <div className="name-box">
                                            <p className="txt-value">{profile_data.status}</p>
                                            <p className="txt-title">{i18n.t('Status')}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="widget-block-action">
                                    <Button className="btn-deposit" onClick={this.openDepositModal}>{i18n.t('Deposit')}</Button>
                                </div>
                                <div className="widget-block-action">
                                    <Button className="btn-deposit" onClick={this.openWithdrawModal}>{i18n.t('Withdraw')}</Button>
                                </div>
                                <div className="clear"></div>
                            </div>}

                            {/* <div className="widget-block accounts-spendings-block">
                                <div className="title-block">
                                    <span className="txt-title">{i18n.t('Your accounts spendings')}</span>
                                    <span className="txt-date"><span>{i18n.t("Date")} :</span> AUGUST 2018</span>
                                    <div className="clear"></div>
                                </div>
                                <div className="content-block">
                                    <AccountsSpendingsBlock title={i18n.t('Investment amount')} progress={this.calcProgress(48215)} amount={48215} />
                                    <AccountsSpendingsBlock title={i18n.t('Dividend payouts')} progress={this.calcProgress(24350)} amount={24350} />
                                    <AccountsSpendingsBlock title={i18n.t('Sold Investments')} progress={this.calcProgress(31000)} amount={31000} />
                                    <AccountsSpendingsBlock title={i18n.t('Deposits')} progress={this.calcProgress(3700)} amount={3700} />
                                    <AccountsSpendingsBlock title={i18n.t('Withdrawals')} progress={this.calcProgress(100)} amount={100} />
                                </div>
                                <div className="content-footer">
                                    <div style={{ textAlign: 'left' }}>0</div>
                                    <div>10k</div>
                                    <div>20k</div>
                                    <div>30k</div>
                                    <div>40k</div>
                                    <div>50k</div>
                                    <div>60k</div>
                                </div>
                            </div> */}
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <div className="widget-block payment-lists-block">
                                <div className="table-widget">
                                    <div className="table-header" style={{ display: 'none' }}>
                                        <div className="left-block">
                                            <TextField
                                                fullWidth
                                                InputProps={{
                                                    className: "search-box",
                                                    placeholder: i18n.t("Find Payment Type to search"),
                                                    disableUnderline: true,
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <SearchIcon />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </div>
                                        <div className="right-block">
                                            <span className="txt-title">{i18n.t('Date')}: </span> <span className="txt-value">{i18n.t('August')} 2018</span>
                                            <span className="txt-title">{i18n.t('Type')} </span> <span className="txt-value">{i18n.t("ALL")}</span>
                                        </div>
                                    </div>
                                    <Scrollbars style={{ height: 370 }} autoHide autoHideTimeout={1000}>
                                        <table className={`table ${user_payments.length === 0 ? 'has-no-payment ' : ''}`}>
                                            <tbody>
                                                {(user_payments && user_payments.length) ? (
                                                    user_payments.map((payment, index) => {
                                                        return (
                                                            <PaymentListItem
                                                                key={index}
                                                                viewDetail={this.openPaymentModal}
                                                                type={payment.transaction_data.transaction_message}
                                                                name={"Crowd Estate"}
                                                                property={payment.property_master}
                                                                transaction_mode={payment.transaction_mode}
                                                                amount={payment.transaction_amount}
                                                                date={payment.transaction_data.transaction_date}
                                                            />
                                                        )
                                                    })
                                                ) : (
                                                        <tr>
                                                            <td colSpan="5" style={{
                                                                textAlign: 'center',
                                                                fontSize: '14px',
                                                                borderBottom: 'none',
                                                                verticalAlign: 'center'
                                                            }}>
                                                                {i18n.t('No any payments yet')}
                                                            </td>
                                                        </tr>
                                                    )}
                                            </tbody>
                                        </table>
                                    </Scrollbars>

                                    <div className="table-box-shadow"></div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>

                    <PaymentDetailModal handleClose={this.closePaymentModal} open={this.state.paymentModalStatus} />
                    {this.state.deposit.modalStatus && <DepositModal 
                        handleClose={this.closeDepositModal} 
                        open={this.state.deposit.modalStatus} 
                        depositAmount={this.state.deposit.amount} 
                        handleChange={this.handleChange} 
                        nextDepositModal={this.nextDepositModal} 
                    />}
                    {this.state.deposit.confirmModalStatus && <DepositInfoModal
                        handleClose={this.closeDepositConfirmModal}
                        open={this.state.deposit.confirmModalStatus}
                        depositAmount={this.state.deposit.amount} 
                        paymentRefNum={this.state.paymentRefNum} 
                        handleBack={this.previousDepositModal} 
                        handleFinish={this.handleDepositReq} 
                    />}
                    {this.state.withdraw.modalStatus && <WithdrawModal 
                        handleClose={this.closeWithdrawModal} 
                        open={this.state.withdraw.modalStatus} 
                        withdrawAmount={this.state.withdraw.amount} 
                        handleChange={this.handleChange} 
                        nextWithdrawModal={this.nextWithdrawModal} 
                    />}
                    {this.state.withdraw.confirmModalStatus && <WithdrawInfoModal
                        handleClose={this.closeWithdrawConfirmModal}
                        open={this.state.withdraw.confirmModalStatus}
                        withdrawAmount={this.state.withdraw.amount} 
                        paymentRefNum={this.state.paymentRefNum} 
                        handleBack={this.previousWithdrawModal} 
                        handleFinish={this.handleWithdrawReq} 
                    />}
                    {
                        this.state.isFooterOpen
                            ? <Button variant="contained" className="btn-footer-action" onClick={this.toggleFooter}><CloseIcon /> {i18n.t('Close')}</Button>
                            : <Button variant="contained" className="btn-footer-action" onClick={this.toggleFooter}>{i18n.t('GTC, Privacy policy & more')}</Button>
                    }
                </main>

                {
                    this.state.isFooterOpen ?
                        <div className="footer-block">
                            <FooterComponent />
                        </div>
                        : null
                }
            </div>
        )
    }
}

const AccountsSpendingsBlock = (props) => {
    return (
        <div className="list-group-item">
            <p>
                <span className="txt-title">{props.title}</span>
                <span className="txt-value">&#36; {formatePrice(props.amount)}</span>
            </p>
            <CustomLinearProgress progress={props.progress} />
        </div>
    );
}

const PaymentListItem = (props) => {
    return (
        props.property !== null ?
            <tr onClick={props.viewDetail}>
                <td width={60}>
                    <div className="payment-icon"><CreditCardOutlinedIcon /></div>
                </td>
                <td>
                    <p className="txt-type">{props.property.title}</p>
                    <p className="txt-name">{props.name}</p>
                </td>
                <td>
                    <p className="txt-value">{checkProfitLoss(props.amount, props.transaction_mode, true)}</p>
                    <p className="txt-date">{props.date}</p>
                </td>
            </tr>
            :
            <tr onClick={props.viewDetail}>
                <td width={60}>
                    <div className="payment-icon"><CreditCardOutlinedIcon /></div>
                </td>
                <td>
                    <p className="txt-type">{props.type}</p>
                    <p className="txt-name">{props.name}</p>
                </td>
                <td>
                    <p className="txt-value">{checkProfitLoss(props.amount, props.transaction_mode, true)}</p>
                    <p className="txt-date">{props.date}</p>
                </td>
            </tr>
    );
}

const CustomLinearProgress = (props) => {
    var progressClassName = (props.progress > 50) ? "funding-progress progress-success" : (props.progress > 20) ? "funding-progress progress-info" : "funding-progress progress-danger";

    return (
        <LinearProgress className={progressClassName} variant="determinate" value={props.progress} />
    );
}

function mapState(state) {
    const { users } = state;
    return { users };
}

const actionCreators = {
    getUserPayments: userActions.getUserPayments,
    depositMoneyRequest: userActions.depositMoneyRequest,
}

export default connect(mapState, actionCreators)(PaymentsPage);
