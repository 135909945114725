import React, { useState } from 'react';
import { Dialog, DialogContent, Box, Button, Typography, Divider, Tooltip } from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import i18n from "../../../i18n";
import "./styles.scss";

const TokenPayModal = ({
    action,
    propertyTitle,
    content,
    immoTokenBalance,
    payAmount,
    open,
    handleClose,
    handleFinish,
}) => {
    const formatAmount = (amount) => {
        const decimal = amount.split(".")[1];
        amount = amount.split(".")[0];
        amount = amount.split("").reverse().join("");
        if (amount) {
            const digits = amount.length;
            amount = amount.replace(/.{3}/g, '$&,');
            if (digits % 3 === 0)
                amount = amount.substring(0, amount.length-1);
        }
        let res = amount.split("").reverse().join("");
        if (decimal) {
            res += `.${decimal}`;
        }
        return res;
    }

    const getPromoteButton = () => {
        if(immoTokenBalance - payAmount < 0) {
            return (<Tooltip title={`You don't have enough tokens!`} arrow>
                <Button className="btn-print">{i18n.t('Promote')}</Button>
            </Tooltip>);
        } else {
            return (<Button className="btn-print" onClick={handleFinish}>{i18n.t('Promote')}</Button>);
        }
    }

    return (
        <Dialog className="token-pay-modal wider-modal" fullWidth={true} maxWidth={"xs"} onClose={handleClose} open={open}>
            <DialogContent>
                <Box className="block-header">{`${i18n.t(action)}`.toUpperCase()}</Box>
                <Box className="payment-icon"><StarIcon /></Box>

                <Box className="block-content">
                    <Box sx={{ mb: 0.5, display: "flex", justifyContent: "center" }}>
                        <Typography variant="h5">
                            <strong>{propertyTitle}</strong>
                        </Typography>
                    </Box>
                    <Box sx={{ mb: 2, display: "flex", justifyContent: "center" }}>
                        <Typography className="text" variant="button">
                            {content}
                        </Typography>
                    </Box>
                    <Divider />
                    <Box sx={{ my: 1, display: "flex", justifyContent: "space-between" }}>
                        <Typography>Your current balance</Typography>
                        <Typography><strong>{formatAmount(`${immoTokenBalance}`)} immo tokens</strong></Typography>
                    </Box>
                    <Divider />
                    <Box sx={{ my: 1, display: "flex", justifyContent: "space-between" }}>
                        <Typography>Promote property cost</Typography>
                        <Typography className="txt-cost"><strong>- {formatAmount(`${payAmount}`)} immo tokens</strong></Typography>
                    </Box>
                    <Divider />
                    <Divider />
                    <Divider />
                    <Box sx={{ my: 1, display: "flex", justifyContent: "space-between" }}>
                        <Typography>Your balance after promoting this property</Typography>
                        <Typography><strong>{(immoTokenBalance - payAmount) >= 0 ? formatAmount(`${(immoTokenBalance - payAmount)}`) : 0} immo tokens</strong></Typography>
                    </Box>
                    <Divider />
                </Box>

                <Box my={1} mr={3} sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Button className="btn-print" onClick={handleClose}>{i18n.t('Close')}</Button>
                    {getPromoteButton()}
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default TokenPayModal;
