import React, { Component } from 'react';
import RoomIcon from '@material-ui/icons/Room';
import { initialState } from '../../_reducers/general.reducer';

export default class PropertyTitle extends Component {
    render() {
        const { property } = this.props;
        return (
            <div className='property-title-box'>
                <p className='property-name'>{property.title}</p>
                <p className='property-location'><RoomIcon />
                    {
                     <span>{(initialState.languageCode === 'en') ? property.macrolocation_heading_en : property.macrolocation_heading_de}, {property.address_country}</span>
                    }
                </p>
            </div>
        )
    }
}
