import React, { Component, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { generalActions, alertActions } from '../../_actions';
import InfiniteScroll from 'react-infinite-scroll-component';
import { StickyContainer } from 'react-sticky';
import { Container, Grid, Button, Hidden, CircularProgress } from '@material-ui/core';
import MapIcon from '@material-ui/icons/Map';
import ListIcon from '@material-ui/icons/List';
import CloseIcon from '@material-ui/icons/Close';
import i18n from "../../i18n";
import Loader from '../../components/GeneralComponent/Loader';
import FooterComponent from '../../components/FooterComponent';
import PropertyMapView from '../../components/PropertyMapView';
import SearchBar from './SearchBar';
import SearchResultsListings from './SearchResultsListings';
import NotificationBox from '../../components/GeneralComponent/NotificationBox';
import { withRouter } from '../../_helpers';
import { generalService } from '../../_services';
import "./styles.scss";


const SearchPage = ({
  location,
  alert,
  clearAlerts,
}) => {
  const [isFooterOpen, setIsFooterOpen] = useState(false);
  const [list_mode, setListMode] = useState(true);
  const [initialDataLimit, setInitialDataLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [properties, setProperties] = useState({
    total: 0,
    data: [],
    max_property_value: 0,
    max_price_per_share: 0,
    max_dividend_yield: 0,
  });
  const [search, setSearch] = useState({});

  useEffect(() => {
    clearAlerts();

    const { searchInvestment } = location.state || {};
    if (searchInvestment) {
      updateFilters(searchInvestment);
    } else {
      updateFilters({});
    }
  }, []);

  const toggleFooter = () => {
    setIsFooterOpen(!isFooterOpen);
  }

  const toggleView = () => {
    setListMode(!list_mode);
  }

  const isBottom = (el) => {
    return Math.round(el.getBoundingClientRect().bottom) <= window.innerHeight;
  }

  const updateFilters = async (searchFilters) => {
    setSearch(searchFilters);
    setPage(0);
    if (JSON.stringify(searchFilters) === JSON.stringify({})) {
      const fetched = await generalService.getProperties(initialDataLimit, 0);
      setProperties(fetched);
    } else {
      const fetched = await generalService.filterProperty(initialDataLimit, searchFilters, 0);
      setProperties(fetched);
    }
  }

  const fetchMoreProperties = async (page) => {
    setPage(page);
    if (JSON.stringify(search) === JSON.stringify({})) {
      const fetched = await generalService.getProperties(initialDataLimit, page);
      setProperties({
        ...fetched,
        data: [...properties.data, ...fetched.data],
      });
    } else {
      const fetched = await generalService.filterProperty(initialDataLimit, search, page);
      setProperties({
        ...fetched,
        data: [...properties.data, ...fetched.data],
      });
    }
  }

  return (
    <>
      {(!properties || Object.keys(properties).length === 0) && (
        <Loader />
      )}
      {(properties && properties.data) && (
        <Container className="search-page" maxWidth={false}>
          <div className="search-bar">
            <SearchBar
                properties={properties}
                onUpdateFilters={(searchFilters) => updateFilters(searchFilters)}
            />
          </div>
          {/* <div onScroll={trackScrolling} ref={myRef}> */}
            {alert.message &&
              <NotificationBox open={true} variant={alert.type} message={alert.message} />
            }
            <StickyContainer>
              {(properties.data.length > 0) && (
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6} md={8} className='property-list-view'>
                    <InfiniteScroll
                      dataLength={properties.data.length}
                      next={() => {
                        fetchMoreProperties(page + 1)
                      }}
                      hasMore={properties.data.length < properties.total}
                      loader={<CircularProgress className='fetch-loader' size={40} variant="indeterminate" />}
                    >
                      <SearchResultsListings properties={properties.data} />
                    </InfiniteScroll>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} className='property-map-view'>
                    <Hidden xsDown>
                      <PropertyMapView properties={properties.data} />
                    </Hidden>
                    {

                      !list_mode &&
                      <Hidden smUp>
                        <PropertyMapView properties={properties.data} />
                      </Hidden>
                    }
                  </Grid>
                </Grid>
              )}

              {
                isFooterOpen
                  ? <Button variant="contained" className="btn-footer-action" onClick={toggleFooter}><CloseIcon /> {i18n.t('Close')}</Button>
                  : <Button variant="contained" className="btn-footer-action" onClick={toggleFooter}>{i18n.t('GTC, Privacy policy & more')}</Button>
              }
            </StickyContainer>

            <Button variant="contained" className="btn-toggle-view" onClick={toggleView}>
              {list_mode ? <span>{i18n.t('Map')} <MapIcon /></span> : <span>{i18n.t('List')} <ListIcon /></span>}
            </Button>
          {/* </div> */}
        </Container>
      )}
      {
        isFooterOpen ?
          <div className="footer-block">
            <FooterComponent />
          </div>
          : null
      }
    </>
  );
}


function mapState(state) {
  const { general, alert } = state;
  return { alert };
}

const actionCreators = {
  clearAlerts: alertActions.clear,
}

const connectedSearchPage = withRouter(connect(mapState, actionCreators)(SearchPage));
export { connectedSearchPage as SearchPage };

