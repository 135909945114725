import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import {
  Container, Grid, Card, CardContent, Box, CardHeader, Stepper, Step, StepLabel, Button,
  InputLabel, MenuItem, FormControl, Select, TextField, Tabs, Tab, Paper, Avatar, Typography
} from '@material-ui/core';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { Add, Close, Today, Clear } from '@material-ui/icons';
import TinyMCE from 'react-tinymce';
import moment from 'moment';
import {
  LanguageTabEn,
  LanguageTabGn,
  a11yProps,
  TabPanel
} from '../../components/GeneralComponent/LanguageTabs';
import { generalActions, alertActions } from '../../_actions';
import NotificationBox from '../../components/GeneralComponent/NotificationBox';
import PropertyReview from '../../components/PropertyComponent';
import DatePicker from 'react-date-picker';
import PropertyMapView from './PropertyMapview';
import './styles.scss';
import { history, withRouter } from '../../_helpers';
import PropertyDetailEdit from './components/PropertyDetailEdit';
import { NavBarComponent as DasboardNavBarComponent } from '../../components/DashboardComponent/common/NavBarComponent';
const fileIcon = require('../../assets/images/file_icon.png');
import { generalService, blockchainService } from '../../_services';
import LinearProgress from '@material-ui/core/LinearProgress';
import TokenPayModal from "../../components/DashboardComponent/modals/TokenPayModal";

class AddPropertyPage extends Component {
  constructor(props) {
    super(props);
    this.mapComponentRef = createRef()

    this.state = {
      activeStep: 0,
      disabledButton: false,
      user: {},
      property: {
        title: '',
        property_type: 'direct',
        property_category_type: 'apartment',
        token_name: '',
        address_line1: '',
        address_city: 'None',
        address_postcode: '',
        address_country: '',
        financial_instrument: 0,
        dividend_distribution: 0,
        macrolocation_heading_en: '',
        macrolocation_heading_de: '',
        macrolocation_description_en: '',
        macrolocation_description_de: '',
        microlocation_heading_en: '',
        microlocation_heading_de: '',
        microlocation_description_en: '',
        microlocation_description_de: '',
        macro_location_image: '',
        macro_location_image_preview: '',
        micro_location_image: '',
        micro_location_image_preview: '',
        key_figures: [],
        sections: [],
        partners: [],
        property_location: {
          lat: parseFloat(process.env.REACT_APP_MAP_LAT),
          lng: parseFloat(process.env.REACT_APP_MAP_LNG)
        },
        net_rental_income: '',
        management_costs: '',
        property_management_cost: '',
        rent_protection_cost: '',
        maintenance_cost: '',
        distribution_surplus: '',
        predicted_value_increase_en: '',
        predicted_value_increase_de: '',
        predicted_value_increase_p_a: '',
        property_picture: [],
        property_documents: [],
        property_price: 0,
        financing_volume: '',
        soft_cap: '',
        total_share_count: '',
        end_date: new Date(),
        start_date: new Date(),
        property_floor_plans: [],
        legal_warnings_en: '',
        legal_warnings_de: '',
        forecasted_distribution_en: '',
        forecasted_distribution_de: '',
        upload_transaction_id: '',
      },
      propertyPricing: {},
      uploadModalStatus: false,
      modalAction: 'upload',
      predicted_value_increase_tabIndex: 0,
      legal_warnings_tabIndex: 0,
      forecasted_distribution_tabIndex: 0,
      available_partners_list: [],
      available_financial_instruments: [],
      available_dividend_distributions: [],
      available_locations: [],
      the_highlights_error: '',
      the_property_error: '',
      the_investment_error: '',
    };
  }

  async componentDidMount() {
    this.props.clearAlerts();
    this.props.getPartners();
    this.props.getPropertyPricing(this.state.modalAction);
    this.props.getFinancialInstruments();
    this.props.getDividendDistributions();
    this.props.getAvailableLocations();
    this.handleAddSectionsRow('Highlights', 'the_highlights');
    this.handleAddSectionsRow('The Property', 'the_property');

    const balanceObj = await blockchainService.getBalances();
    this.setState({
      user: {
        immoTokenBalance: balanceObj.immo,
        zbsTokenBalance: balanceObj.zbs,
      }      
    });

    if (this.props.params?.id) {
      // this.props.getPropertyById(this.props.params.id);
      this.loadPropertyById(this.props.params?.id);
    }
  }

  async loadPropertyById(id) {
    try {
      const property = await generalService.getPropertyById(id)
      const prevState = this.state;
      let update = {};

      if (property && prevState.property.property_id !== property.property_id) {
        property.macrolocation_heading_en = property.macrolocation_heading?.en || '';
        property.macrolocation_heading_de = property.macrolocation_heading?.de || '';
        property.macrolocation_description_en = property.macrolocation_description?.en || '';
        property.macrolocation_description_de = property.macrolocation_description?.de || '';
        delete property.macrolocation_heading;
        delete property.macrolocation_description;
  
        property.microlocation_heading_en = property.microlocation_heading?.en || '';
        property.microlocation_heading_de = property.microlocation_heading?.de || '';
        property.microlocation_description_en = property.microlocation_description?.en || '';
        property.microlocation_description_de = property.microlocation_description?.de || '';
        delete property.microlocation_heading;
        delete property.microlocation_description;
  
        property.predicted_value_increase_en = property.predicted_value_increase?.en || '';
        property.predicted_value_increase_de = property.predicted_value_increase?.de || '';
        delete property.predicted_value_increase;
  
        property.forecasted_distribution_en = property.forecasted_distribution?.en || '';
        property.forecasted_distribution_de = property.forecasted_distribution?.de || '';
        delete property.forecasted_distribution;
  
        property.legal_warnings_en = property.legal_warnings?.en || '';
        property.legal_warnings_de = property.legal_warnings?.de || '';
        delete property.legal_warnings;
  
        property.macro_location_image_preview = property.macrolocation_image_path;
        property.micro_location_image_preview = property.microlocation_image_path
        // @TODO
        property.property_floor_plans = (property.property_floor_plans || []).map(fp => ({
          ...fp,
          floor_plan_image_preview: fp.image_path
        }));

        property.property_price = property.property_price || 0;
  
        if (property.location_coords && property.location_coords.lat) {
          property.location_coords.lat = parseFloat(property.location_coords.lat) || 0
          property.location_coords.lng = parseFloat(property.location_coords.lng) || 0
          var location_coords = {
            position: {
              lat: property.location_coords.lat,
              lng: property.location_coords.lng
            }
          };
          this.onUpdatePropertyLocation(location_coords);
        }
  
        property.sections = (property.property_sections || []).map(s => ({
          id: s.id,
          section_heading_en: s.heading.en,
          section_heading_de: s.heading.de,
          section_description_en: s.description.en,
          section_description_de: s.description.de,
          heading_tab_value: 0,
          description_tab_value: 0,
          section_key: s.section_key
        }));
  
        property.key_figures = (property.property_key_figures || []).map(f => ({
          id: f.id,
          property_key_en: f.property_key.en,
          property_key_de: f.property_key.de,
          property_value_en: f.property_value.en,
          property_value_de: f.property_value.de,
          tab_value_key: 0,
          tab_value_val: 0
        }));
  
        property.partners = (property.property_project_partners || []).map(p => ({
          id: p.id,
          partner_id: p._partner_id,
          involvement: p.involvement
        }));
  
        property.property_picture = (property.property_images || []).map(p => ({
          id: p.id,
          property_picture_preview: p.image_path,
          property_images: null,
          image_description_en: p.description.en,
          image_description_de: p.description.de,
          tab_value: 0
        }));
  
        property.property_documents = (property.property_documents || []).map(d => ({
          id: d.id,
          property_doc_file: null,
          property_doc_filename: d.file_path?.toLowerCase(),
          file_description_en: d.description?.en,
          file_description_de: d.description?.de,
          tab_value: 0
        }));
  
        property.start_date = moment(property.start_date, 'DD-MM-YYYY').toDate();
        property.end_date = moment(property.end_date, 'DD-MM-YYYY').toDate();
  
        update = {
          ...update,
          property: property,
        };
      } else {
        update.property = prevState.property;
      }
  
  
      this.setState(prevState => {
        return {
          ...prevState,
          ...update,
          property: update.property,
        }
      });
    } catch (e) {
      console.log(e);
    }
  }

  componentWillReceiveProps(nextProps) {
    const {
      partners,
      financial_instruments,
      dividend_distributions,
      available_locations,
    } = nextProps.general;

    const prevState = this.state;

    var update = {};

    if (partners && partners.status === true) {
      update = {
        ...update,
        available_partners_list: partners.data
      };
    }

    if (financial_instruments && financial_instruments.status === true) {
      update = {
        ...update,
        available_financial_instruments: financial_instruments.data
      };
    }

    if (dividend_distributions && dividend_distributions.status === true) {
      update = {
        ...update,
        available_dividend_distributions: dividend_distributions.data
      };
    }

    if (available_locations && Object.keys(available_locations).length && available_locations !== prevState.available_locations) {
      update = {
        ...update,
        available_locations: available_locations
      }
    }

    this.setState(prevState => {
      return {
        ...prevState,
        ...update
      }
    });
  }

  static getDerivedStateFromProps(props, state) {
    const { propertyPricing } = props.general;
    if(propertyPricing && propertyPricing.pricing.amount !== state.propertyPricing.amount) {
      return {
        propertyPricing: { ...propertyPricing.pricing }
      }
    }
    return null;
  }

  getSteps() {
    const map = {
      direct: [
        { name: 'Property Details', render: this.renderDetailPart },
        { name: 'Picture Upload', render: this.renderPicturePart },
        { name: 'Financial Details', render: this.renderSTOFactsPart },
        { name: 'Document Upload', render: this.renderFileUploadPart },
        { name: 'Review Page', render: this.renderReviewPart },
      ],
      default: [
        { name: 'Property Details', render: this.renderDetailPart },
        { name: 'Financial Details', render: this.renderFinancialPart },
        { name: 'Picture Upload', render: this.renderPicturePart },
        { name: 'STO Facts', render: this.renderSTOFactsPart },
        { name: 'File Upload', render: this.renderFileUploadPart },
        { name: 'Review Page', render: this.renderReviewPart },
      ]
    }
    const steps = map[this.state.property.property_type] || map.default;
    return steps;
  }

  handleEditorValidation = () => {
    const { property } = this.state;
    var isAllSectionsFilled = property.sections.every(section => section.section_description_en !== '');
    if (isAllSectionsFilled) {
      return true;
    } else {
      property.sections.map(section => {
        var errorName = section.section_key + '_error';
        var errorMsg = section.section_heading_en + ' description is required field';
        this.setState({ [errorName]: section.section_description_en === '' ? errorMsg : '' })
        return null;
      })
      return false;
    }
  }

  handleNext = () => {
    this.form.isFormValid(false).then(isValid => {
      const { activeStep } = this.state;
      var validAllEditorsVal = activeStep === 0 ? this.handleEditorValidation() : true;
      if (isValid && validAllEditorsVal) {
        this.setState(prevState => ({
          activeStep: prevState.activeStep + 1,
          the_highlights_error: '',
          the_property_error: '',
          the_investment_error: '',
        }));
      }
    });
  };

  handleBack = () => {
    this.setState(prevState => ({
      activeStep: prevState.activeStep - 1
    }));
  };

  handleLocationTabChange = locationInputName => (event, newValue) => {
    this.setState({
      [locationInputName]: newValue
    });
  }

  handlePredictedValueTabChange = (event, newValue) => {
    this.setState({
      predicted_value_increase_tabIndex: newValue
    });
  };

  handleChange = event => {
    var fieldName = event.target.name;
    var fieldValue = event.target.value;
    this.setState(prevState => ({
      property: {
        ...prevState.property,
        [fieldName]: fieldValue
      }
    }));
  };

  handleChangePropertyType = event => {
    const fieldValue = event.target.value;

    if (fieldValue === 'direct') {
      this.setState(prevState => {
        const sections = prevState.property.sections;
        sections.splice(2, 1);
        return {
          property: {
            ...prevState.property,
            property_type: fieldValue,
            sections,
          }
        };
      });
    } else {
      if (this.state.property.sections.length < 3) {
        this.handleAddSectionsRow('The Investment', 'the_investment');
        this.setState(prevState => ({
          property: {
            ...prevState.property,
            property_type: fieldValue,
          }
        }));
      }
    }
  };


  onUpdateProperty = (update) => {
    this.setState(prevState => ({
      property: {
        ...prevState.property,
        ...update,
      }
    }));
  }

  onUpdatePropertyLocation = (location_cords) => {
    this.mapComponentRef.current.setLocationMarkerOnChangePlaceFromParent(location_cords.position)
    this.handleAddPropertyLocation(location_cords);
  }

  handleStartDateChange = date => {
    this.setState(prevState => ({
      property: {
        ...prevState.property,
        start_date: date
      }
    }));
  };

  handleEndDateChange = date => {
    this.setState(prevState => ({
      property: {
        ...prevState.property,
        end_date: date
      }
    }));
  };

  handleEditorChange = event => {
    var fieldName = event.target.id.split('-')[1];
    var fieldValue = event.target.getContent();
    this.setState(prevState => ({
      property: {
        ...prevState.property,
        [fieldName]: fieldValue
      }
    }));
  };

  handleKeyFiguresTabValue = (index, tabFlag) => (event, newValue) => {
    const key_figures = [...this.state.property.key_figures];
    key_figures[index] = {
      ...key_figures[index],
      [tabFlag]: newValue
    };
    this.setState(prevState => ({
      property: {
        ...prevState.property,
        key_figures: key_figures
      }
    }));
  };

  handleAddKeyFigureRow = () => {
    const property = {
      property_key_en: '',
      property_key_de: '',
      property_value_en: '',
      property_value_de: '',
      tab_value_key: 0,
      tab_value_val: 0
    };
    this.setState(prevState => ({
      property: {
        ...prevState.property,
        key_figures: [...prevState.property.key_figures, property]
      }
    }));
  };

  handleRemoveKeyFigureRow = index => () => {
    const key_figures = [...this.state.property.key_figures];
    key_figures.splice(index, 1);
    this.setState(prevState => ({
      property: {
        ...prevState.property,
        key_figures: key_figures
      }
    }));
  };

  handleKeyFigureChange = index => event => {
    const { name, value } = event.target;
    const key_figures = [...this.state.property.key_figures];
    key_figures[index] = {
      ...key_figures[index],
      [name]: value
    };
    this.setState(prevState => ({
      property: {
        ...prevState.property,
        key_figures: key_figures
      }
    }));
  };

  handleSectionsTabValue = (index, tabFlag) => (event, newValue) => {
    const sections = [...this.state.property.sections];
    sections[index] = {
      ...sections[index],
      [tabFlag]: newValue
    };
    this.setState(prevState => ({
      property: {
        ...prevState.property,
        sections: sections
      }
    }));
  };

  handleAddSectionsRow = (heading = '', key = '') => {
    const section = {
      section_heading_en: heading,
      section_heading_de: '',
      section_description_en: '',
      section_description_de: '',
      heading_tab_value: 0,
      description_tab_value: 0,
      section_key: key
    };
    this.setState(prevState => ({
      property: {
        ...prevState.property,
        sections: [...prevState.property.sections, section]
      }
    }));
  };

  handleRemoveSectionsRow = index => () => {
    const sections = [...this.state.property.sections];
    sections.splice(index, 1);
    this.setState(prevState => ({
      property: {
        ...prevState.property,
        sections: sections
      }
    }));
  };

  handleSectionsChange = (index, inputType) => event => {
    const sections = [...this.state.property.sections];
    if (inputType === 'heading-input') {
      const { name, value } = event.target;
      sections[index] = { ...sections[index], [name]: value };
      this.setState(prevState => ({
        property: { ...prevState.property, sections: sections }
      }));
    } else {
      var name = event.target.id.split('-')[1].substring(0, event.target.id.split('-')[1].lastIndexOf('_'));
      var value = event.target.getContent();
      sections[index] = { ...sections[index], [name]: value };
      this.setState(prevState => ({
        property: { ...prevState.property, sections: sections }
      }));
    }
  };

  handleAddPartnerRow = () => {
    const partner = {
      partner_id: 0,
      involvement: ''
    };
    this.setState(prevState => ({
      property: {
        ...prevState.property,
        partners: [...prevState.property.partners, partner]
      }
    }));
  };

  handleRemovePartnerRow = index => () => {
    const partners = [...this.state.property.partners];
    partners.splice(index, 1);
    this.setState(prevState => ({
      property: {
        ...prevState.property,
        partners: partners
      }
    }));
  };

  handlePartnerChange = index => event => {
    const { name, value } = event.target;
    const partners = [...this.state.property.partners];
    partners[index] = {
      ...partners[index],
      [name]: value
    };
    this.setState(prevState => ({
      property: {
        ...prevState.property,
        partners: partners
      }
    }));
  };

  handleAddPropertyLocation = location_props => {
    const { position } = location_props;
    this.setState(prevState => ({
      property: {
        ...prevState.property,
        property_location: position
      }
    }));
  };

  handlePropertyPictureTabValue = index => (event, newValue) => {
    const property_picture = [...this.state.property.property_picture];
    property_picture[index] = {
      ...property_picture[index],
      tab_value: newValue
    };
    this.setState(prevState => ({
      property: {
        ...prevState.property,
        property_picture: property_picture
      }
    }));
  };

  handleAddPropertyPicture = (preview, logo) => {
    const pictureObj = {
      property_picture_preview: preview,
      property_images: logo,
      image_description_en: '',
      image_description_de: '',
      tab_value: 0
    };
    this.setState(prevState => ({
      property: {
        ...prevState.property,
        property_picture: [...prevState.property.property_picture, pictureObj]
      }
    }));
  };

  handleRemovePropertyPicture = picture => () => {
    const property_picture = [...this.state.property.property_picture];
    const index = property_picture.findIndex(p => p === picture);
    if (property_picture[index].id) {
      property_picture[index] = {
        ...property_picture[index],
        is_delete: 1,
      }
    } else {
      property_picture.splice(index, 1);
    }
    this.setState(prevState => ({
      property: {
        ...prevState.property,
        property_picture: property_picture
      }
    }));
};

  handlePropertyPictureDescChange = index => event => {
    const { name, value } = event.target;
    const property_picture = [...this.state.property.property_picture];
    property_picture[index] = {
      ...property_picture[index],
      [name]: value
    };
    this.setState(prevState => ({
      property: {
        ...prevState.property,
        property_picture: property_picture
      }
    }));
  };

  handlePropertyPictureCapture = e => {
    e.preventDefault();

    const files = Array.from(e.target.files);
    files.forEach((file) => {
      let reader = new FileReader();
      reader.onloadend = () => {
        this.handleAddPropertyPicture(reader.result, file);
      };
      reader.readAsDataURL(file);  
    });
  };

  handlePropertyDocTabValue = index => (event, newValue) => {
    const property_documents = [...this.state.property.property_documents];
    property_documents[index] = {
      ...property_documents[index],
      tab_value: newValue
    };
    this.setState(prevState => ({
      property: {
        ...prevState.property,
        property_documents: property_documents
      }
    }));
  };

  handlePropertyDocCapture = e => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      this.handleAddPropertyDoc(file);
    };
    reader.readAsDataURL(file);
  };

  handleAddPropertyDoc = file => {
    const fileObj = {
      property_doc_file: file,
      property_doc_filename: file.name.toLowerCase(),
      file_description_en: '',
      file_description_de: '',
      tab_value: 0
    };
    this.setState(prevState => ({
      property: {
        ...prevState.property,
        property_documents: [...prevState.property.property_documents, fileObj]
      }
    }));
  };

  handlePropertyDocDescChange = index => event => {
    const { name, value } = event.target;
    const property_documents = [...this.state.property.property_documents];
    property_documents[index] = {
      ...property_documents[index],
      [name]: value
    };
    this.setState(prevState => ({
      property: {
        ...prevState.property,
        property_documents: property_documents
      }
    }));
  };

  handleRemovePropertyDoc = doc => () => {
    const property_documents = [...this.state.property.property_documents];
    const index = property_documents.findIndex(d => d === doc);
    if (property_documents[index].id) {
      property_documents[index] = {
        ...property_documents[index],
        is_delete: 1,
      }
    } else {
      property_documents.splice(index, 1);
    }
    this.setState(prevState => ({
      property: {
        ...prevState.property,
        property_documents: property_documents
      }
    }));
  };

  handleFloorPlanImageCapture = e => {
    e.preventDefault();
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        this.setState(prevState => ({
          property: {
            ...prevState.property,
            property_floor_plans: [
              ...prevState.property.property_floor_plans,
              {
                floor_plan_image: file,
                floor_plan_image_preview: reader.result
              }
            ]
          }
        }));
      };
      reader.readAsDataURL(file);
    });
  };

  handleRemoveFloorPlanPicture = (floorPlan) => () => {
    const floor_plan_pictures = [...this.state.property.property_floor_plans];
    const index = floor_plan_pictures.findIndex(fp => fp === floorPlan);
    if (floor_plan_pictures[index].id) {
      floor_plan_pictures[index] = {
        ...floor_plan_pictures[index],
        is_delete: 1,
      }
    } else {
      floor_plan_pictures.splice(index, 1);
    }
    this.setState(prevState => ({
      property: {
        ...prevState.property,
        property_floor_plans: floor_plan_pictures
      }
    }));
  }

  handleReset = () => {
    this.setState({ activeStep: 0 });
  };
  
  closeUploadModal = () => {
    this.setState({ 
      uploadModalStatus: false, 
      disabledButton: false, 
    });
  }

  handlePropertyUpload = async () => {
    const res = await blockchainService.spendTokens(this.state.propertyPricing.amount);
    if (res) {
      this.setState({ 
        uploadModalStatus: false, 
        property: {
          ...this.state.property,
          upload_transaction_id: res.id,
        }
      });
      this.form.submit();
    }
  }

  submit = () => {
    if (this.state.property.property_id) {
      // update property part 
      this.form.submit();
    } else {
      this.setState({ 
        disabledButton: true, 
        uploadModalStatus: true,
      });
    }
  };

  handleSubmit = () => {
    const property = {...this.state.property};
    delete property.macro_location_image_preview;
    delete property.micro_location_image_preview;

    if (this.state.property.property_id) {
      delete property.upload_transaction_id;
      this.props.updateProperty(property, history).then(res => {
        setTimeout(() => {
          this.props.clearAlerts()
          this.props.navigate('/dashboard/properties')
        }, 2000);
        // hash uploaded documents
        const db_property = res.property;
        this.props.hashDocuments(db_property.property_id, db_property.property_documents);
      });
    } else {
      this.props.addProperty(property).then(db_property => {
        setTimeout(() => {
          this.props.clearAlerts()
          this.setState({ disabledButton: false });
          this.props.navigate('/dashboard/properties')
        }, 2000);
        // hash uploaded proprety documents
        this.props.hashDocuments(db_property.property_id, db_property.property_documents);
      });
    }
  };

  renderDetailPart = () => {
    const {
      property,
      legal_warnings_tabIndex,
      forecasted_distribution_tabIndex,
      available_partners_list,
    } = this.state;
    return (
      <div className="step-wizard-block block-1" id="step_1">
        <Grid container spacing={1}>
          <Grid item md={8} xs={12}>
            <Grid item md={12} xs={12}>
              <FormControl fullWidth>
                <InputLabel shrink>Property Category</InputLabel>
                <Select
                  id="text-property_type"
                  name="property_type"
                  value={property.property_type}
                  onChange={this.handleChangePropertyType}
                  fullWidth
                >
                  <MenuItem value="direct">
                    <em>Direct Sale</em>
                  </MenuItem>
                  <MenuItem value="secondary">Sale</MenuItem>
                  <MenuItem value="primary">Crowdfunding</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <PropertyDetailEdit
              property={property}
              onUpdate={this.onUpdateProperty}
              onUpdateLocation={this.onUpdatePropertyLocation}
            />
          </Grid>

          <Grid item md={4} xs={12}>
            <Grid container spacing={1}>
              <Grid item md={12} xs={12}>
                <Card className="default-card">
                  <CardHeader
                    title="Property Location"
                    subheader="&#9736; Select place by dragging location marker"
                  />
                  <CardContent>
                    <PropertyMapView
                      ref={this.mapComponentRef}
                      handleAddPropertyLocation={
                        this.handleAddPropertyLocation
                      }
                    />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>

          <Grid item md={12} xs={12}>
            <Grid container spacing={1}>
              <Grid item md={12} xs={12}>
                <Card className="default-card">
                  <CardHeader
                    title={
                      property.property_type === 'direct'
                        ? 'Key Features'
                        : 'Key Figures'
                    }
                  />
                  <CardContent>
                    <table className="table table-striped table-bordered">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Key</th>
                          <th>Value</th>
                          <th>
                            <Button
                              variant="outlined"
                              size="small"
                              color="primary"
                              onClick={this.handleAddKeyFigureRow}
                              className="action-button"
                            >
                              <Add />
                            </Button>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {property.key_figures.length > 0 && (
                          <>
                            {property.key_figures.map(
                              (key_figure_item, index) => (
                                <tr key={index}>
                                  <td align="center">{index + 1}</td>
                                  <td align="center">
                                    <Paper square>
                                      <Tabs
                                        value={
                                          key_figure_item.tab_value_key
                                        }
                                        className="tabs-container"
                                        onChange={this.handleKeyFiguresTabValue(
                                          index,
                                          "tab_value_key"
                                        )}
                                        indicatorColor="primary"
                                        textColor="primary"
                                        variant="fullWidth"
                                        aria-label="full width tabs example"
                                      >
                                        <Tab
                                          label={<LanguageTabEn />}
                                          {...a11yProps(0)}
                                        />
                                        <Tab
                                          label={<LanguageTabGn />}
                                          {...a11yProps(1)}
                                        />
                                      </Tabs>
                                    </Paper>
                                    <TabPanel
                                      value={
                                        key_figure_item.tab_value_key
                                      }
                                      index={0}
                                    >
                                      <TextValidator
                                        fullWidth
                                        value={
                                          key_figure_item.property_key_en
                                        }
                                        onChange={this.handleKeyFigureChange(
                                          index
                                        )}
                                        name="property_key_en"
                                        className="form-control"
                                        label="Key"
                                      />
                                    </TabPanel>
                                    <TabPanel
                                      value={
                                        key_figure_item.tab_value_key
                                      }
                                      index={1}
                                    >
                                      <TextValidator
                                        fullWidth
                                        value={
                                          key_figure_item.property_key_de
                                        }
                                        onChange={this.handleKeyFigureChange(
                                          index
                                        )}
                                        name="property_key_de"
                                        className="form-control"
                                        label="Key"
                                      />
                                    </TabPanel>
                                  </td>
                                  <td align="center">
                                    <Paper square>
                                      <Tabs
                                        value={
                                          key_figure_item.tab_value_val
                                        }
                                        className="tabs-container"
                                        onChange={this.handleKeyFiguresTabValue(
                                          index,
                                          "tab_value_val"
                                        )}
                                        indicatorColor="primary"
                                        textColor="primary"
                                        variant="fullWidth"
                                        aria-label="full width tabs example"
                                      >
                                        <Tab
                                          label={<LanguageTabEn />}
                                          {...a11yProps(0)}
                                        />
                                        <Tab
                                          label={<LanguageTabGn />}
                                          {...a11yProps(1)}
                                        />
                                      </Tabs>
                                    </Paper>
                                    <TabPanel
                                      value={
                                        key_figure_item.tab_value_val
                                      }
                                      index={0}
                                    >
                                      <TextValidator
                                        fullWidth
                                        value={
                                          key_figure_item.property_value_en
                                        }
                                        onChange={this.handleKeyFigureChange(
                                          index
                                        )}
                                        name="property_value_en"
                                        className="form-control"
                                        label="Value"
                                      />
                                    </TabPanel>
                                    <TabPanel
                                      value={
                                        key_figure_item.tab_value_val
                                      }
                                      index={1}
                                    >
                                      <TextValidator
                                        fullWidth
                                        value={
                                          key_figure_item.property_value_de
                                        }
                                        onChange={this.handleKeyFigureChange(
                                          index
                                        )}
                                        name="property_value_de"
                                        className="form-control"
                                        label="Value"
                                      />
                                    </TabPanel>
                                  </td>
                                  <td align="center">
                                    <Button
                                      onClick={this.handleRemoveKeyFigureRow(
                                        index
                                      )}
                                      className="action-button"
                                      variant="outlined"
                                      size="small"
                                      color="secondary"
                                    >
                                      <Close />
                                    </Button>
                                  </td>
                                </tr>
                              )
                            )}
                          </>
                        )}
                      </tbody>
                    </table>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item md={12} xs={12}>
                <Card className="default-card">
                  <CardHeader title="Sections" />
                  <CardContent>
                    <table className="table table-striped table-bordered">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Heading</th>
                          <th>Description</th>
                          {/* <th>
                                                              <Button
                                                                  variant="outlined"
                                                                  size="small"
                                                                  color="primary"
                                                                  onClick={this.handleAddSectionsRow}
                                                                  className="action-button"
                                                              >
                                                                  <Add />
                                                              </Button>
                                                          </th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {property.sections.length > 0 && (
                          <>
                            {property.sections.map(
                              (section, index) => (
                                <tr key={index}>
                                  <td align="center">{index + 1}</td>
                                  <td align="center">
                                    <Paper square>
                                      <Tabs
                                        value={
                                          section.heading_tab_value
                                        }
                                        className="tabs-container"
                                        onChange={this.handleSectionsTabValue(
                                          index,
                                          "heading_tab_value"
                                        )}
                                        indicatorColor="primary"
                                        textColor="primary"
                                        variant="fullWidth"
                                        aria-label="full width tabs example"
                                      >
                                        <Tab
                                          label={<LanguageTabEn />}
                                          {...a11yProps(0)}
                                        />
                                        <Tab
                                          label={<LanguageTabGn />}
                                          {...a11yProps(1)}
                                        />
                                      </Tabs>
                                    </Paper>
                                    <TabPanel
                                      value={
                                        section.heading_tab_value
                                      }
                                      index={0}
                                    >
                                      <TextValidator
                                        fullWidth
                                        value={
                                          section.section_heading_en
                                        }
                                        onChange={this.handleSectionsChange(
                                          index,
                                          "heading-input"
                                        )}
                                        name="section_heading_en"
                                        className="form-control"
                                        label="Heading *"
                                        validators={["required"]}
                                        errorMessages={["This field is required"]}
                                      />
                                    </TabPanel>
                                    <TabPanel
                                      value={
                                        section.heading_tab_value
                                      }
                                      index={1}
                                    >
                                      <TextValidator
                                        fullWidth
                                        value={
                                          section.section_heading_de
                                        }
                                        onChange={this.handleSectionsChange(
                                          index,
                                          "heading-input"
                                        )}
                                        name="section_heading_de"
                                        className="form-control"
                                        label="Heading"
                                      />
                                    </TabPanel>
                                  </td>
                                  <td align="center">
                                    <Paper square>
                                      <Tabs
                                        value={
                                          section.description_tab_value
                                        }
                                        className="tabs-container"
                                        onChange={this.handleSectionsTabValue(
                                          index,
                                          "description_tab_value"
                                        )}
                                        indicatorColor="primary"
                                        textColor="primary"
                                        variant="fullWidth"
                                        aria-label="full width tabs example"
                                      >
                                        <Tab
                                          label={<LanguageTabEn />}
                                          {...a11yProps(0)}
                                        />
                                        <Tab
                                          label={<LanguageTabGn />}
                                          {...a11yProps(1)}
                                        />
                                      </Tabs>
                                    </Paper>
                                    <TabPanel
                                      value={
                                        section.description_tab_value
                                      }
                                      index={0}
                                    >
                                      <TinyMCE
                                        id={
                                          "text-section_description_en_" +
                                          index
                                        }
                                        name="section_description_en"
                                        content={section.section_description_en}
                                        config={{
                                          plugins:
                                            "autolink link image lists print preview",
                                          toolbar:
                                            "undo redo | bold italic | alignleft aligncenter alignright | styleselect",
                                          // menubar: 'file edit insert view format table tools help'
                                          menubar: false,
                                          preview_styles: false,
                                          branding: false,

                                        }}
                                        onChange={this.handleSectionsChange(
                                          index,
                                          "description-editor"
                                        )}
                                      />
                                      {this.state[section.section_key + '_error'] !== '' && (
                                        <p className="editor-error">{this.state[section.section_key + '_error']}</p>
                                      )}
                                    </TabPanel>
                                    <TabPanel
                                      value={
                                        section.description_tab_value
                                      }
                                      index={1}
                                    >
                                      <TinyMCE
                                        id={
                                          "text-section_description_de_" +
                                          index
                                        }
                                        name="section_description_de"
                                        content={section.section_description_de}
                                        config={{
                                          plugins:
                                            "autolink link image lists print preview",
                                          toolbar:
                                            "undo redo | bold italic | alignleft aligncenter alignright | styleselect",
                                          // menubar: 'file edit insert view format table tools help'
                                          menubar: false,
                                          preview_styles: false,
                                          branding: false
                                        }}
                                        onChange={this.handleSectionsChange(
                                          index,
                                          "description-editor"
                                        )}
                                      />
                                    </TabPanel>
                                  </td>
                                  {/* <td align="center">
                                                                          <Button
                                                                              onClick={this.handleRemoveSectionsRow(index)}
                                                                              className="action-button"
                                                                              variant="outlined" size="small" color="secondary">
                                                                              <Close />
                                                                          </Button>
                                                                      </td> */}
                                </tr>
                              )
                            )}
                          </>
                        )}
                      </tbody>
                    </table>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item md={12} xs={12}>
                <Card className="default-card">
                  <CardHeader title="Partners" />
                  <CardContent>
                    <table className="table table-striped table-bordered">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Partner Name</th>
                          <th>Involvement</th>
                          <th>
                            <Button
                              variant="outlined"
                              size="small"
                              color="primary"
                              onClick={this.handleAddPartnerRow}
                              className="action-button"
                            >
                              <Add />
                            </Button>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {property.partners.length > 0 && (
                          <>
                            {property.partners.map((partner, index) => (
                              <tr key={index}>
                                <td align="center">{index + 1}</td>
                                <td align="center">
                                  <FormControl fullWidth>
                                    <InputLabel shrink>
                                      {" "}
                                      Select Partner
                                    </InputLabel>
                                    <Select
                                      name="partner_id"
                                      value={partner.partner_id}
                                      onChange={this.handlePartnerChange(
                                        index
                                      )}
                                    >
                                      <MenuItem value={0}>
                                        <em>None</em>
                                      </MenuItem>
                                      {available_partners_list.length >
                                        0 &&
                                        available_partners_list.map(
                                          (p, index) => (
                                            <MenuItem
                                              key={index}
                                              value={p.id}
                                            >
                                              {p.name}
                                            </MenuItem>
                                          )
                                        )}
                                    </Select>
                                  </FormControl>
                                </td>
                                <td align="center">
                                  <TextValidator
                                    fullWidth
                                    value={partner.involvement}
                                    onChange={this.handlePartnerChange(
                                      index
                                    )}
                                    name="involvement"
                                    className="form-control"
                                    label="Involvement"
                                  />
                                </td>
                                <td align="center">
                                  <Button
                                    onClick={this.handleRemovePartnerRow(
                                      index
                                    )}
                                    className="action-button"
                                    variant="outlined"
                                    size="small"
                                    color="secondary"
                                  >
                                    <Close />
                                  </Button>
                                </td>
                              </tr>
                            ))}
                          </>
                        )}
                      </tbody>
                    </table>
                  </CardContent>
                </Card>
              </Grid>
              {property.property_type !== 'direct' && (
                <Grid item md={12} xs={12}>
                  <p className="forecasted-distribution-lable">Forecasted Distribution</p>
                  <Paper square>
                    <Tabs
                      value={forecasted_distribution_tabIndex}
                      className="tabs-container"
                      onChange={this.handleLocationTabChange('forecasted_distribution_tabIndex')}
                      indicatorColor="primary"
                      textColor="primary"
                      variant="fullWidth"
                      aria-label="full width tabs example"
                    >
                      <Tab label={<LanguageTabEn />} {...a11yProps(0)} />
                      <Tab label={<LanguageTabGn />} {...a11yProps(1)} />
                    </Tabs>
                  </Paper>

                  <TabPanel value={forecasted_distribution_tabIndex} index={0}>
                    <TinyMCE
                      id="text-forecasted_distribution_en"
                      name="forecasted_distribution_en"
                      content={property.forecasted_distribution_en}
                      config={{
                        plugins: "autolink link image lists print preview",
                        toolbar: "undo redo | bold italic | alignleft aligncenter alignright | styleselect",
                        menubar: false,
                        preview_styles: false,
                        branding: false,
                      }}
                      onChange={this.handleEditorChange}
                    />
                  </TabPanel>
                  <TabPanel value={forecasted_distribution_tabIndex} index={1}>
                    <TinyMCE
                      id="text-forecasted_distribution_de"
                      name="forecasted_distribution_de"
                      content={property.forecasted_distribution_de}
                      config={{
                        plugins: "autolink link image lists print preview",
                        toolbar: "undo redo | bold italic | alignleft aligncenter alignright | styleselect",
                        menubar: false,
                        preview_styles: false,
                        branding: false,
                      }}
                      onChange={this.handleEditorChange}
                    />
                  </TabPanel>
                </Grid>
              )}
              <Grid item md={12} xs={12}>
                <p className="legal-warnings-lable">
                  {
                    property.property_type === 'direct'
                      ? 'Legal Information'
                      : 'Legal Warnings'
                  }
                </p>
                <Paper square>
                  <Tabs
                    value={legal_warnings_tabIndex}
                    className="tabs-container"
                    onChange={this.handleLocationTabChange('legal_warnings_tabIndex')}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                  >
                    <Tab label={<LanguageTabEn />} {...a11yProps(0)} />
                    <Tab label={<LanguageTabGn />} {...a11yProps(1)} />
                  </Tabs>
                </Paper>

                <TabPanel value={legal_warnings_tabIndex} index={0}>
                  <TinyMCE
                    id="text-legal_warnings_en"
                    name="legal_warnings_en"
                    content={property.legal_warnings_en}
                    config={{
                      plugins: "autolink link image lists print preview",
                      toolbar: "undo redo | bold italic | alignleft aligncenter alignright | styleselect",
                      menubar: false,
                      preview_styles: false,
                      branding: false,
                    }}
                    onChange={this.handleEditorChange}
                  />
                </TabPanel>
                <TabPanel value={legal_warnings_tabIndex} index={1}>
                  <TinyMCE
                    id="text-legal_warnings_de"
                    name="legal_warnings_de"
                    content={property.legal_warnings_de}
                    config={{
                      plugins: "autolink link image lists print preview",
                      toolbar: "undo redo | bold italic | alignleft aligncenter alignright | styleselect",
                      menubar: false,
                      preview_styles: false,
                      branding: false,
                    }}
                    onChange={this.handleEditorChange}
                  />
                </TabPanel>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }

  renderFinancialPart = () => {
    const {
      property,
      predicted_value_increase_tabIndex,
    } = this.state;

    return (
      <div className="step-wizard-block block-2" id="step_2">
        <Grid container spacing={1}>
          <Grid item md={8} xs={12}>
            <Grid container spacing={1}>
              <Grid item md={6} xs={12}>
                <TextValidator
                  fullWidth
                  value={property.net_rental_income}
                  onChange={this.handleChange}
                  id="text-net_rental_income"
                  name="net_rental_income"
                  className="form-control"
                  label="Net Rental Income *"
                  validators={["required", "isFloat"]}
                  errorMessages={["This field is required", "This field only contains numbers"]}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextValidator
                  fullWidth
                  label="Management Costs *"
                  value={property.management_costs}
                  name="management_costs"
                  id="text-management_costs"
                  onChange={this.handleChange}
                  className="form-control"
                  validators={["required", "isFloat"]}
                  errorMessages={["This field is required", "This field only contains numbers"]}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item md={6} xs={12}>
                <TextValidator
                  fullWidth
                  value={property.property_management_cost}
                  onChange={this.handleChange}
                  id="text-property_management_cost"
                  name="property_management_cost"
                  className="form-control"
                  label="Property Management Cost *"
                  validators={["required", "isFloat"]}
                  errorMessages={["This field is required", "This field only contains numbers"]}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextValidator
                  fullWidth
                  label="Rent Protection Cost *"
                  value={property.rent_protection_cost}
                  name="rent_protection_cost"
                  id="text-rent_protection_cost"
                  onChange={this.handleChange}
                  className="form-control"
                  validators={["required", "isFloat"]}
                  errorMessages={["This field is required", "This field only contains numbers"]}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item md={6} xs={12}>
                <TextValidator
                  fullWidth
                  value={property.maintenance_cost}
                  onChange={this.handleChange}
                  id="text-maintenance_cost"
                  name="maintenance_cost"
                  className="form-control"
                  label="Maintenance Cost *"
                  validators={["required", "isFloat"]}
                  errorMessages={["This field is required", "This field only contains numbers"]}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextValidator
                  fullWidth
                  label="Distribution Surplus *"
                  value={property.distribution_surplus}
                  name="distribution_surplus"
                  id="text-distribution_surplus"
                  onChange={this.handleChange}
                  className="form-control"
                  validators={["required", "isFloat"]}
                  errorMessages={["This field is required", "This field only contains numbers"]}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item md={6} xs={12}>
                <TextValidator
                  fullWidth
                  value={property.predicted_value_increase_p_a}
                  onChange={this.handleChange}
                  id="text-predicted_value_increase_p_a"
                  name="predicted_value_increase_p_a"
                  className="form-control"
                  label="Predicted Value Increase PA *"
                  validators={["required", "isFloat"]}
                  errorMessages={["This field is required", "This field only contains numbers"]}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item md={12} xs={12}>
                <Box component="div" mt={2}>
                  <Paper square>
                    <Tabs
                      value={predicted_value_increase_tabIndex}
                      className="tabs-container"
                      onChange={this.handlePredictedValueTabChange}
                      indicatorColor="primary"
                      textColor="primary"
                      variant="fullWidth"
                      aria-label="full width tabs example"
                    >
                      <Tab
                        label={<LanguageTabEn />}
                        {...a11yProps(0)}
                      />
                      <Tab
                        label={<LanguageTabGn />}
                        {...a11yProps(1)}
                      />
                    </Tabs>
                  </Paper>
                  <TabPanel
                    value={predicted_value_increase_tabIndex}
                    index={0}
                  >
                    <TextField
                      fullWidth
                      id="text-predicted_value_increase_en"
                      name="predicted_value_increase_en"
                      label="Predicted Value Increase"
                      multiline
                      minRows={3}
                      value={property.predicted_value_increase_en}
                      onChange={this.handleChange}
                      variant="outlined"
                    />
                  </TabPanel>
                  <TabPanel
                    value={predicted_value_increase_tabIndex}
                    index={1}
                  >
                    <TextField
                      fullWidth
                      id="text-predicted_value_increase_de"
                      name="predicted_value_increase_de"
                      label="Predicted Value Increase"
                      multiline
                      minRows={3}
                      value={property.predicted_value_increase_de}
                      onChange={this.handleChange}
                      variant="outlined"
                    />
                  </TabPanel>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }

  renderPicturePart = () => {
    const {
      property,
    } = this.state;

    return (
      <div className="step-wizard-block block-3" id="step_3">
        <Grid container spacing={1}>
          <Grid item md={12} xs={12}>
            <Box component="div" m={2} ml={0}>
              <div>
                <h3>Floor Plan</h3>
              </div>
              <Button variant="contained" component="label">
                <span>Upload Floor Plan</span>
                <input
                  type="file"
                  id="floor_plan_images"
                  onChange={this.handleFloorPlanImageCapture}
                  className="floor-plan-image-file"
                  accept="image/*"
                  multiple="multiple"
                />
              </Button>
            </Box>
            <Box component="div" m={2} ml={0}>
              {property.property_floor_plans.filter(f => !f.is_delete).map((floor_plan, indx) => (
                <Box
                  key={indx}
                  className="floor_plan_box"
                  component="div"
                  m={2}
                  ml={0}
                  display="inline-block"
                  position="relative"
                >
                  <Avatar
                    className="floor-plan-image"
                    alt="Floor plan Image"
                    variant="square"
                    src={floor_plan.floor_plan_image_preview}
                  />
                  <Button
                    onClick={this.handleRemoveFloorPlanPicture(
                      floor_plan
                    )}
                    className="action-button"
                    variant="outlined"
                    size="small"
                    color="secondary"
                  >
                    <Close />
                  </Button>
                </Box>
              ))}
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item md={12} xs={12}>
            <div>
              <h3>Property Pictures</h3>
            </div>
            <Button variant="contained" component="label">
              <span>Upload Picture</span>
              <input
                type="file"
                id="property_logo"
                onChange={this.handlePropertyPictureCapture}
                className="upload-picture-file"
                accept="image/*"
                multiple="multiple"
              />
            </Button>
          </Grid>
          <Grid item md={12} xs={12}>
            {property.property_picture.length > 0 && (
              <>
                {property.property_picture.filter(p => !p.is_delete).map((picture, indx) => {
                  return (
                    <Paper
                      key={indx}
                      className="property-picture-item-container"
                    >
                      <Grid container spacing={1}>
                        <Grid item md={3} sm={12} xs={12}>
                          <Avatar
                            className="picture"
                            alt="Profile Picture"
                            variant="square"
                            src={picture.property_picture_preview}
                          />
                        </Grid>
                        <Grid item md={8} sm={10} xs={12}>
                          <Paper square>
                            <Tabs
                              value={picture.tab_value}
                              className="tabs-container"
                              onChange={this.handlePropertyPictureTabValue(
                                indx
                              )}
                              indicatorColor="primary"
                              textColor="primary"
                              variant="fullWidth"
                              aria-label="full width tabs example"
                            >
                              <Tab
                                label={<LanguageTabEn />}
                                {...a11yProps(0)}
                              />
                              <Tab
                                label={<LanguageTabGn />}
                                {...a11yProps(1)}
                              />
                            </Tabs>
                          </Paper>
                          <TabPanel
                            value={picture.tab_value}
                            index={0}
                          >
                            <TextField
                              fullWidth
                              multiline
                              minRows={3}
                              value={picture.image_description_en}
                              onChange={this.handlePropertyPictureDescChange(
                                indx
                              )}
                              name="image_description_en"
                              className="form-control"
                              label="Image Description"
                              variant="outlined"
                            />
                          </TabPanel>
                          <TabPanel
                            value={picture.tab_value}
                            index={1}
                          >
                            <TextField
                              fullWidth
                              multiline
                              minRows={3}
                              value={picture.image_description_de}
                              onChange={this.handlePropertyPictureDescChange(
                                indx
                              )}
                              name="image_description_de"
                              className="form-control"
                              label="Image Description"
                              variant="outlined"
                            />
                          </TabPanel>
                        </Grid>
                        <Grid item md={1} sm={2} xs={12}>
                          <Button
                            onClick={this.handleRemovePropertyPicture(
                              picture
                            )}
                            className="action-button"
                            variant="outlined"
                            size="small"
                            color="secondary"
                          >
                            <Close />
                          </Button>
                        </Grid>
                      </Grid>
                    </Paper>
                  );
                })}
              </>
            )}
          </Grid>
        </Grid>

      </div>
    );
  }

  renderSTOFactsPart = () => {
    const {
      property,
    } = this.state;

    return (
      <div className="step-wizard-block block-4" id="step_4">
        <Grid container spacing={1}>
          <Grid item md={10} xs={12}>
            {
              property.property_type === 'direct' ? (
                <Grid container spacing={1}>
                  <Grid item md={4} sm={6} xs={12}>
                    <TextValidator
                      fullWidth
                      value={property.property_price}
                      onChange={this.handleChange}
                      id="text-property_price"
                      name="property_price"
                      className="form-control"
                      label="Property Price *"
                      validators={["required", "isFloat"]}
                      errorMessages={["This field is required", "This field only contains numbers"]}
                    />
                  </Grid>
                </Grid>
              ) : (
                <Grid container spacing={1}>
                  <Grid item md={4} sm={6} xs={12}>
                    <TextValidator
                      fullWidth
                      value={property.financing_volume}
                      onChange={this.handleChange}
                      id="text-financing_volume"
                      name="financing_volume"
                      className="form-control"
                      label="Financing Volume *"
                      validators={["required", "isFloat"]}
                      errorMessages={["This field is required", "This field only contains numbers"]}
                    />
                  </Grid>
                  <Grid item md={4} sm={6} xs={12}>
                    <TextValidator
                      fullWidth
                      value={property.soft_cap}
                      onChange={this.handleChange}
                      id="text-soft_cap"
                      name="soft_cap"
                      className="form-control"
                      label="Soft Cap *"
                      validators={["required", "isFloat"]}
                      errorMessages={["This field is required", "This field only contains numbers"]}
                    />
                  </Grid>
                  <Grid item md={4} sm={6} xs={12}>
                    <TextValidator
                      fullWidth
                      value={property.total_share_count}
                      onChange={this.handleChange}
                      id="text-total_share_count"
                      name="total_share_count"
                      className="form-control"
                      label="Total Share Count *"
                      validators={["required", "isFloat"]}
                      errorMessages={["This field is required", "This field only contains numbers"]}
                    />
                  </Grid>
                </Grid>
              )
            }


            <Grid container spacing={1} />
            <Grid container spacing={1}>
              <Grid item lg={4} md={6} sm={6} xs={12}>
                <div>
                  <h3>
                    {property.property_type === 'direct' ? 'Available for Purchase From' : 'Start date'}
                  </h3>
                </div>
                <div>
                  <DatePicker
                    onChange={this.handleStartDateChange}
                    showLeadingZeros={true}
                    value={property.start_date}
                    Clear={<Clear />}
                    calendarIcon={<Today />}
                  />
                </div>
              </Grid>
              <Grid item lg={4} md={6} sm={6} xs={12}>
                <div>
                  <h3>
                    {property.property_type === 'direct' ? 'Available for Purchase Until' : 'End date'}
                  </h3>
                </div>
                <div>
                  <DatePicker
                    onChange={this.handleEndDateChange}
                    showLeadingZeros={true}
                    minDate={property.start_date}
                    value={property.end_date}
                    Clear={<Clear />}
                    calendarIcon={<Today />}
                  />
                </div>
              </Grid>
            </Grid>

          </Grid>
        </Grid>
      </div>
    );
  }

  renderFileUploadPart = () => {
    const {
      property,
    } = this.state;

    return (
      <div className="step-wizard-block block-5" id="step_5">
        <Grid container spacing={1}>
          <Grid item md={12} xs={12}>
            <Button variant="contained" component="label">
              <span>{property.property_type === 'direct' ? 'Upload Document' : 'Upload File'}</span>
              <input
                type="file"
                id="property_logo"
                onChange={this.handlePropertyDocCapture}
                className="upload-doc-file"
                accept=".xlsx,.xls,.doc,.docx,.ppt,.pptx,.txt,.pdf"
              />
            </Button>
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            {property.property_documents.length > 0 &&
              property.property_documents.filter(d => !d.is_delete).map((doc, index) => {
                return (
                  <Paper
                    key={index}
                    className="property-file-item-container"
                  >
                    <Grid container spacing={1}>
                      <Grid
                        item
                        md={2}
                        sm={12}
                        xs={12}
                        className="file-icon-container"
                      >
                        <img
                          alt="file-icon"
                          src={fileIcon}
                          className="file-icon"
                        />
                        <p className="file-name">
                          {doc.property_doc_filename}
                        </p>
                      </Grid>
                      <Grid item md={9} sm={10} xs={12}>
                        <Paper square>
                          <Tabs
                            value={doc.tab_value}
                            className="tabs-container"
                            onChange={this.handlePropertyDocTabValue(
                              index
                            )}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                            aria-label="full width tabs example"
                          >
                            <Tab
                              label={<LanguageTabEn />}
                              {...a11yProps(0)}
                            />
                            <Tab
                              label={<LanguageTabGn />}
                              {...a11yProps(1)}
                            />
                          </Tabs>
                        </Paper>
                        <TabPanel value={doc.tab_value} index={0}>
                          <TextValidator
                            fullWidth
                            label="File Description"
                            value={doc.file_description_en}
                            name="file_description_en"
                            id="text-file_description_en"
                            onChange={this.handlePropertyDocDescChange(
                              index
                            )}
                            className="form-control"
                            validators={["required"]}
                            errorMessages={["This field is required"]}
                          />
                        </TabPanel>
                        <TabPanel value={doc.tab_value} index={1}>
                          <TextValidator
                            fullWidth
                            label="File Description"
                            value={doc.file_description_de}
                            name="file_description_de"
                            id="text-file_description_de"
                            onChange={this.handlePropertyDocDescChange(
                              index
                            )}
                            className="form-control"
                          />
                        </TabPanel>
                      </Grid>
                      <Grid item md={1} sm={2} xs={12}>
                        <Button
                          onClick={this.handleRemovePropertyDoc(
                            doc
                          )}
                          className="action-button"
                          variant="outlined"
                          size="small"
                          color="secondary"
                        >
                          <Close />
                        </Button>
                      </Grid>
                    </Grid>
                  </Paper>
                );
              })}
          </Grid>
        </Grid>
      </div>
    )
  }

  renderReviewPart = () => {
    return (
      <div className="step-wizard-block block-6" id="step_6">
        <PropertyReview
          property={this.state.property}
        />
      </div>
    );
  }

  render() {
    const steps = this.getSteps();
    const { activeStep } = this.state;
    const { alert } = this.props;

    return (
      <div className="main-content add-property-page">
        <DasboardNavBarComponent pageName="manage-property" />
        <Card>
          <CardContent>
            {alert.message && (
              <NotificationBox
                open={true}
                variant={alert.type}
                message={alert.message}
              />
            )}
            <Stepper activeStep={activeStep}>
              {steps.map(({ name }) => {
                return (
                  <Step key={name}>
                    <StepLabel>{name}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>

            <ValidatorForm
              className="default-form property-form add-property-form"
              onSubmit={this.handleSubmit}
              ref={r => {
                this.form = r;
              }}
              autoComplete="off"
              instantValidate
            >
              {
                steps[activeStep] && steps[activeStep].render
                && steps[activeStep].render()
              }
              <Container>
                {
                  this.state.disabledButton && <>
                    <Typography variant="h6" gutterBottom>Uploading property <strong>{this.state.property.title}</strong></Typography>
                    <LinearProgress /><br /><br />
                  </>
                }
              </Container>
              <div class="action-buttons">
                <Button disabled={activeStep === 0 || this.state.disabledButton} onClick={this.handleBack}>
                  Back
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={this.state.disabledButton}
                  onClick={
                    activeStep < steps.length - 1
                      ? this.handleNext
                      : this.submit
                  }
                >
                  {activeStep === steps.length - 1 ? "Accept and Start Campaign" : "Next"}
                </Button>
              </div>
            </ValidatorForm>
          </CardContent>
        </Card>
        <TokenPayModal
          handleClose={this.closeUploadModal}
          open={this.state.uploadModalStatus}
          action={this.state.modalAction}
          propertyTitle={this.state.property.title}
          content={`You are about to ${this.state.modalAction} this property`}
          immoTokenBalance={this.state.user.immoTokenBalance}
          zbsTokenBalance={this.state.user.zbsTokenBalance}
          payAmount={this.state.propertyPricing.amount}
          handleFinish={this.handlePropertyUpload}
        />
      </div>
    );
  }
}

function mapState(state) {
  const { alert, general, location } = state;
  return { alert, general, location };
}

const actionCreators = {
  clearAlerts: alertActions.clear,
  addProperty: generalActions.addProperty,
  updateProperty: generalActions.updateProperty,
  getPartners: generalActions.getPartners,
  getPropertyPricing: generalActions.getPropertyPricing,
  getFinancialInstruments: generalActions.getFinancialInstruments,
  getDividendDistributions: generalActions.getDividendDistributions,
  getAvailableLocations: generalActions.getAvailableLocations,
  getPropertyById: generalActions.getPropertyById,
  hashDocuments: generalActions.hashDocuments,
};

const connectedPage = withRouter(connect(mapState, actionCreators)(AddPropertyPage));
export { connectedPage as AddPropertyPage };
