import React, { Component } from 'react';
import { Card, CardContent, Grid } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import Tooltip from '@material-ui/core/Tooltip';
import ReactHtmlParser from 'react-html-parser';
import i18n from "../../i18n";
import "./styles.scss";

class InvestmentDetails extends Component {
    formatePrice = (val) => {
        if (val !== undefined && val !== null && !isNaN(val)) {
            var parts = val.toString().split(".");
            if (parts.length > 1) {
                val = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".") + (parts[1] ? "," + parts[1] : "");
            }
            val = this.numberWithCommas(val);
            return val;
        }
    }

    numberWithCommas = (x) => {
        if (x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        }
        return x;
    }

    render() {
        const { property, showFinancialInfo } = this.props;
        const investmentSection = property.property_sections ? property.property_sections.find((section) => {
            return section.section_key === 'the_investment'
        }) : "";

        return (
            <div className='investment-details'>
                {investmentSection && (
                    <div className="section">
                        <h2 className="title-text">{i18n.t(investmentSection.heading)}</h2>
                        <div className="content-text">{ReactHtmlParser(investmentSection.description)}</div>
                    </div>
                )}

                {(property && showFinancialInfo)  && <Card className="financial-detail-box">
                    <CardContent>
                        <table className="table" style={{marginTop: '1.5em'}}>
                            <tbody>
                            {property.property_type === "primary" ? (
                                <tr>
                                    <td><strong>{i18n.t('Financing Volume')}</strong></td>
                                    <td><strong>{this.numberWithCommas(property.financing_volume)} &#36;</strong></td>
                                </tr>
                                ) : (
                                <tr>
                                    <td><strong>{i18n.t('Market Value')}</strong></td>
                                    <td><strong>{this.formatePrice((property.current_price_per_share * property.total_share_count).toFixed(2))} &#36;</strong></td>
                                </tr>
                                )}

                                <tr>
                                    <td><strong>{i18n.t('Forecasted Total Return')} p.a.*</strong></td>
                                    {property.property_type === "primary" ? (
                                    <td><strong>{this.formatePrice((property.profit_payout_percent*1 + property.predicted_value_increase_p_a*1).toFixed(2))} %</strong></td>
                                    ) : (
                                    <td><strong>{this.formatePrice((((property.distribution_surplus / (property.current_price_per_share * property.total_share_count)) * 100).toFixed(2)*1 + property.predicted_value_increase_p_a*1).toFixed(2))} %</strong></td>
                                    )}
                                </tr>





                                <tr>
                                    <td>{i18n.t('financial-detail-box_Expected net rental return')} p.a.*</td>
                                    {property.property_type === "primary" ? (
                                    <td>{this.formatePrice(property.profit_payout_percent)} %</td>
                                    ) : (
                                    <td>{this.formatePrice(((property.distribution_surplus / (property.current_price_per_share * property.total_share_count)) * 100).toFixed(2))} %</td>
                                    )}
                                </tr>



                                <tr>
                                    <td>{i18n.t('Expected value growth')} p.a.*</td>
                                    <td>{this.formatePrice(property.predicted_value_increase_p_a)} %</td>
                                </tr>
                            </tbody>
                        </table>
                    </CardContent>
                </Card>}

                {(property && showFinancialInfo) && <Grid container spacing={3}>
                    <Grid className="distribution-yield-block" item md={6} xs={12}>
                        <h2 className="txt-title txt-title-secondary">{i18n.t('Expected net rental profit')}*</h2>
                        <table className="table">
                            <tbody>
                                <tr>
                                    <td><strong>+ {i18n.t('Net Rental Income')}</strong></td>
                                    <td><strong>{this.formatePrice(property.net_rental_income)} &#36;</strong></td>
                                </tr>
                                <tr>
                                    <td><strong>- {i18n.t('Management Costs')}</strong></td>
                                    <td><strong> {this.formatePrice(property.property_management_cost + property.management_costs + property.rent_protection_cost + property.maintenance_cost)} &#36;</strong></td>
                                </tr>
                                <tr className="list-item">
                                    <td>{i18n.t('Property Management')}</td>
                                    <td>{this.formatePrice(property.property_management_cost)} &#36;</td>
                                </tr>
                                <tr className="list-item">
                                    <td>{i18n.t('Asset Management Fee')}</td>
                                    <td>{this.formatePrice(property.management_costs)} &#36;</td>
                                </tr>
                                <tr className="list-item">
                                    <td>{i18n.t('Loss of rent Reserve')}</td>
                                    <td>{this.formatePrice(property.rent_protection_cost)} &#36;</td>
                                </tr>
                                <tr className="list-item">
                                    <td>{i18n.t('Maintenance Reserve')}</td>
                                    <td>{this.formatePrice(property.maintenance_cost)} &#36;</td>
                                </tr>
                                <tr>
                                    <td><strong>= {i18n.t('Net rental profit')}</strong></td>
                                    <td><strong>{this.formatePrice(property.distribution_surplus)} &#36;</strong></td>
                                </tr>
                                <tr className="list-item">
                                    <td>{i18n.t('Net rental return')}</td>
                                    {property.property_type === "primary" ? (
                                    <td>{property.profit_payout_percent} %</td>
                                    ) :
                                    (
                                        <td>{this.formatePrice(((property.distribution_surplus / (property.current_price_per_share * property.total_share_count)) * 100).toFixed(2))} %</td>
                                    )}
                                </tr>
                            </tbody>
                        </table>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <h2 className="txt-title txt-title-secondary">{i18n.t('Forecasted Appreciation')}*</h2>
                        <p className="content-text">{property.predicted_value_increase}</p>
                    </Grid>
                </Grid>}

                <p className="content-text">
                    {ReactHtmlParser(property.forecasted_distribution)}
                </p>

                <h2 className="txt-title txt-title-secondary">{i18n.t("Documents")}</h2>
                {property.property_documents.length > 0 ? (
                    <table className="table table-striped table-bordered table-document">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Title</th>
                                <th>Description</th>
                                <th>Last Updated</th>
                                <th>Download Link</th>
                                <th>Transaction Link</th>
                            </tr>
                        </thead>
                        <tbody>
                            <>
                                {property.property_documents.map((document, index) => {
                                    return (
                                        <tr key={ index }>
                                            <td>{ index + 1 }</td>
                                            <td>{ document.file_path }</td>
                                            <td>{ document.description }</td>
                                            <td>{ document.updated_date }</td>
                                            <td>
                                                <a 
                                                    href={`${process.env.REACT_APP_API_URL}/properties/download/${document.file_path}`}
                                                >
                                                    Download
                                                </a>
                                            </td>
                                            <td>
                                                <Tooltip title={`This document is notarized in 0bsnetwork blockchain`} arrow>
                                                    <a 
                                                        href={`${process.env.REACT_APP_0BSNETWORK_EXPLORER}/${document.transaction_id}`}
                                                        target="_blank"
                                                    >
                                                        View in Blockchain Explorer
                                                    </a>
                                                </Tooltip>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </>
                        </tbody>
                    </table> ) : (
                        <p className="content-text">
                            {ReactHtmlParser("Nothing to show")}
                        </p>
                    )
                }

                <h2 className="txt-title txt-title-secondary">{i18n.t('Legal Warnings')}</h2>
                <p className="content-text">
                    {ReactHtmlParser(property.legal_warnings)}
                </p>
            </div>
        )
    }
}

export default InvestmentDetails;
