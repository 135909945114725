export const generalConstants = {
    GET_LOCATION_SUCCESS: 'GET_LOCATION_SUCCESS',
    GET_ALL_PROPERTIES_SUCCESS: 'GET_ALL_PROPERTIES_SUCCESS',
    GET_PROPERTIES_SUCCESS: 'GET_PROPERTIES_SUCCESS',
    GET_PARTNER_SUCCESS: 'GET_PARTNER_SUCCESS',
    GET_PARTNER_FAILURE: 'GET_PARTNER_FAILURE',
    GET_PARTNER_BY_ID_SUCCESS: 'GET_PARTNER_BY_ID_SUCCESS',
    GET_PARTNER_BY_ID_FAILURE: 'GET_PARTNER_BY_ID_FAILURE',
    GET_PROPERTY_SUCCESS: 'GET_PROPERTY_SUCCESS',
    GET_PRICING_SUCCESS: 'GET_PRICING_SUCCESS',
    GET_FINANCIAL_INSTRUMENTS_SUCCESS: 'GET_FINANCIAL_INSTRUMENTS_SUCCESS',
    GET_DIVIDENT_DISTRIBUTIONS_SUCCESS: 'GET_DIVIDENT_DISTRIBUTIONS_SUCCESS',
    GET_PROPERTIES_COUNT_SUCCESS: 'GET_PROPERTIES_COUNT_SUCCESS',
    GET_AVAILABLE_LOCATIONS_SUCCESS: 'GET_AVAILABLE_LOCATIONS_SUCCESS',
    UPDATE_COIN_RATES: 'UPDATE_COIN_RATES',
};
