import React from 'react';
import { NavLink } from 'react-router-dom';
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import SpeedOutlinedIcon from '@material-ui/icons/SpeedOutlined';
import AdminDashboardIcon from '@material-ui/icons/Dashboard';
import WorkOutlineOutlinedIcon from '@material-ui/icons/WorkOutlineOutlined';
import PaymentOutlinedIcon from '@material-ui/icons/PaymentOutlined';
import ListIcon from '@material-ui/icons/List';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import FaceOutlinedIcon from '@material-ui/icons/FaceOutlined';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import MamageUsersIcon from '@material-ui/icons/SupervisedUserCircle';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import '../styles.scss';
import i18n from '../../../i18n';
import { secureStorage } from '../../../_services';

export default function NavBarLinks(props) {
  const { navControl, page } = props;
  const is_admin = secureStorage.getItem('is_admin');

  function getActiveMenu(pageName) {
    return pageName === page ? 'nav-link active' : 'nav-link';
  }

  return (
    <List disablePadding className="nav-link-bar">
      <ListItem
        className={getActiveMenu('dashboard')}
        button
        onClick={navControl}
      >
        <ListItemIcon className="nav-icon">
          <SpeedOutlinedIcon />
        </ListItemIcon>
        <ListItemText>
          <NavLink to="/dashboard" className="nav-text">
            {i18n.t('Dashboard')}
          </NavLink>
        </ListItemText>
      </ListItem>
      {is_admin && (
        <ListItem
          className={getActiveMenu('admin-dashboard')}
          button
          onClick={navControl}
        >
          <ListItemIcon className="nav-icon">
            <AdminDashboardIcon />
          </ListItemIcon>
          <ListItemText>
            <NavLink to="/dashboard/admin" className="nav-text">
              {i18n.t('Admin Dashboard')}
            </NavLink>
          </ListItemText>
        </ListItem>
      )}
      {/* <ListItem
        className={getActiveMenu('investment-wallet')}
        button
        onClick={navControl}
      >
        <ListItemIcon className="nav-icon">
          <WorkOutlineOutlinedIcon />
        </ListItemIcon>
        <ListItemText>
          <NavLink to="/dashboard/wallet" className="nav-text">
            {i18n.t('Investment Wallet')}
          </NavLink>
        </ListItemText>
      </ListItem> */}
      <ListItem
        className={getActiveMenu('manage-property')}
        button
        onClick={navControl}
      >
        <ListItemIcon className="nav-icon">
          <HomeWorkIcon />
        </ListItemIcon>
        <ListItemText>
          <NavLink to="/dashboard/properties" className="nav-text">
            {i18n.t('Manage Properties')}
          </NavLink>
        </ListItemText>
      </ListItem>
      <ListItem
        className={getActiveMenu('payments')}
        button
        onClick={navControl}
      >
        <ListItemIcon className="nav-icon">
          <PaymentOutlinedIcon />
        </ListItemIcon>
        <ListItemText>
          <NavLink to="/dashboard/payments" className="nav-text">
            {i18n.t('Payments')}
          </NavLink>
        </ListItemText>
      </ListItem>
      {/* <ListItem className={getActiveMenu('orders')} button onClick={navControl}>
        <ListItemIcon className="nav-icon">
          <ListIcon />
        </ListItemIcon>
        <ListItemText>
          <NavLink to="/dashboard/orders" className="nav-text">
            {i18n.t('My Orders')}
          </NavLink>
        </ListItemText>
      </ListItem> */}
      {is_admin && (
        <>
          <ListItem
            className={getActiveMenu('manage-partner')}
            button
            onClick={navControl}
          >
            <ListItemIcon className="nav-icon">
              <SupervisorAccountIcon />
            </ListItemIcon>
            <ListItemText>
              <NavLink to="/dashboard/partners" className="nav-text">
                Manage Partners
              </NavLink>
            </ListItemText>
          </ListItem>

          <ListItem
            className={getActiveMenu('manage-location')}
            button
            onClick={navControl}
          >
            <ListItemIcon className="nav-icon">
              <LocationOnIcon />
            </ListItemIcon>
            <ListItemText>
              <NavLink to="/dashboard/locations" className="nav-text">
                Manage Locations
              </NavLink>
            </ListItemText>
          </ListItem>

          <ListItem
            className={getActiveMenu('manage-users')}
            button
            onClick={navControl}
          >
            <ListItemIcon className="nav-icon">
              <MamageUsersIcon />
            </ListItemIcon>
            <ListItemText>
              <NavLink to="/dashboard/users" className="nav-text">
                Manage Users
              </NavLink>
            </ListItemText>
          </ListItem>
        </>
      )}
      {/* <ListItem className={getActiveMenu("transactions")} button onClick={navControl}>
        <ListItemIcon className="nav-icon"><ReceiptOutlinedIcon /></ListItemIcon>
        <ListItemText>
          <NavLink to="/dashboard/transactions" className="nav-text">Transactions</NavLink>
        </ListItemText>
      </ListItem> */}
      {/* <ListItem className={getActiveMenu("reports")} button onClick={navControl}>
        <ListItemIcon className="nav-icon"><TrendingUpOutlinedIcon /></ListItemIcon>
        <ListItemText>
          <Link to="/dashboard" onClick={navControl} className="nav-text">Reports</Link>
        </ListItemText>
      </ListItem> */}
      <ListItem className={getActiveMenu("token-wallet")} button onClick={navControl}>
        <ListItemIcon className="nav-icon"><AccountBalanceWalletIcon /></ListItemIcon>
        <ListItemText>
          <NavLink to="/dashboard/tokenwallet" className="nav-text">{i18n.t('Token Wallet')}</NavLink>
        </ListItemText>
      </ListItem>
      <ListItem
        className={getActiveMenu('profile')}
        button
        onClick={navControl}
      >
        <ListItemIcon className="nav-icon">
          <FaceOutlinedIcon />
        </ListItemIcon>
        <ListItemText>
          <NavLink
            to="/dashboard/profile"
            onClick={navControl}
            className="nav-text"
          >
            {i18n.t('Profile')}
          </NavLink>
        </ListItemText>
      </ListItem>
    </List>
  );
}
