import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Card, CardContent, Button, Box, Typography, Divider, TablePagination,
        FormControlLabel, Checkbox, List, ListItem, ListItemText } from "@material-ui/core";
import { connect } from "react-redux";
import { generalActions, alertActions } from "../../_actions";
import NotificationBox from "./../../components/GeneralComponent/NotificationBox";
import { NavBarComponent as DasboardNavBarComponent } from '../../components/DashboardComponent/common/NavBarComponent';
import DeleteModal from "../../components/DeleteModal";
import TransferPropertyModal from "../../components/DashboardComponent/modals/TransferPropertyModal";
import TokenPayModal from "../../components/DashboardComponent/modals/TokenPayModal";
import { generalService, secureStorage, userManageService, blockchainService } from '../../_services';

import "./styles.scss";

class ManagePropertyPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      transferModalStatus: false,
      promoteModalStatus: false,
      deleteModalStatus: false,
      selectedProperty: {},
      immoTokenBalance: 0,
      zbsTokenBalance: 0,
      propertyPricing: {},
      modalAction: "promote",
      totalData: 0,
      properties: [],
      showPending: false,
      paymentRefNumber: "",
      transferPropId: 0,
      users: {
        owner: {},
        buyer: {},
      },
      page: {
        size: 10,
        page: 0,
        count: 0,
      }
    };
  }

  componentDidMount() {
    this.props.getPropertyPricing(this.state.modalAction);
    this.loadProperties();
  }

  static getDerivedStateFromProps(props, state) {
    const { dashboard_properties: properties, propertyPricing } = props.general;
    if(propertyPricing && propertyPricing.pricing.amount !== state.propertyPricing.amount) {
      return {
        propertyPricing: { ...propertyPricing.pricing }
      }
    }
    if (properties && properties.status === true) {
      return {
        properties: properties.data,
        totalData: properties.total,
        page: {
          ...state.page,
          count: properties.pagination.count,
        }
      };
    } else if (properties && !properties.status) {
      return {
        properties: [],
        totalData: 0,
      };
    }
    return null;
  }
  
  loadProperties = () => {
    this.props.clearAlerts();
    this.props.getAllProperties(this.state.page.page, this.state.page.size);
  }

  getPropertyById = (propertyId) => {
    const { properties } = this.state;
    return properties.find(
      property => property.property_id === propertyId
    );
  }

  handlePageChange = (page) => {
    this.setState({
      ...this.state,
      page,
    }, () => this.loadProperties())
  }

  handlePropertyPromote = async () => {
    const res = await blockchainService.spendTokens(this.state.propertyPricing.amount);
    const data = {
      property_id: this.state.selectedProperty.property_id,
      is_promoted: 1,
      promoted_transaction_id: res.id,
      promoted_date: new Date(res.timestamp),
    }
    await this.props.updatePropertyValues(data);
    this.setState({ promoteModalStatus: false });
    this.loadProperties();
  }

  handlePropertyDemote = async (propertyId) => {
    const data = {
      property_id: propertyId,
      is_promoted: 0,
      promoted_transaction_id: null,
      promoted_date: null,
    }
    await this.props.updatePropertyValues(data);
    this.loadProperties();
  }

  handlePropertyRemove = () => {
    this.props.deletePropertyById(this.state.selectedProperty.property_id).then((value) => {
      this.closeDeleteModal();
      this.loadProperties();
      setTimeout(() => {
        this.props.clearAlerts();
      }, 2000);
    });
  };

  handlePropertyStatus = id => {
    const { properties } = this.state;
    let property = properties.find(property => property.property_id === id);

    const data = {
      property_id: id,
      status: property["status"] === "active" ? "inactive" : "active"
    }

    this.props.updatePropertyValues(data).then(value => {
      this.loadProperties();
    });
  }

  openPromoteModal = async (propertyId) => {
    const balanceObj = await blockchainService.getBalances();
    this.setState({
      promoteModalStatus: true,
      selectedProperty: this.getPropertyById(propertyId),
      immoTokenBalance: balanceObj.immo,
      zbsTokenBalance: balanceObj.zbs,
    });
  }

  openDeleteModal = (propertyId) => {
    this.setState({ 
      deleteModalStatus: true,
      selectedProperty: this.getPropertyById(propertyId),
    });
  }

  getUser = async (id) => {
    let { error, data } = await userManageService.getUser(id);
    return data;
  }

  openTransferModal = async (property) => {
    const {
      _user_id: ownerId,
      _buyer_id: buyerId,
      property_id: transferPropId,
      purchase_ref_number: paymentRefNumber,
    } = property;

    if (ownerId && buyerId && paymentRefNumber) {
      const owner = await this.getUser(ownerId);
      const buyer = await this.getUser(buyerId);

      this.setState({
        paymentRefNumber, transferPropId,
        transferModalStatus: true,
        users: {
          owner, buyer
        },
      });
    }
  }

  closeTransferModal = () => {
    this.setState({
      paymentRefNum: "",
      transferModalStatus: false,
      users: { owner: {}, buyer: {} },
    });
    this.loadProperties();
  }

  handleFinishTransferModal = async () => {
    await generalService.transferProperty({
      property_id: this.state.transferPropId,
      _user_id: this.state.users.buyer.user_id,
      _buyer_id: null,
      purchase_ref_number: null,
      status: "inactive",
      is_promoted: 0,
      promoted_transaction_id: null,
      promoted_date: null,
    })
    this.closeTransferModal();
  }

  handleRejectTransferModal = async () => {
    await generalService.transferProperty({
      property_id: this.state.transferPropId,
      _buyer_id: null,
      purchase_ref_number: null,
      status: "active",
    })
    this.closeTransferModal();
  }

  closePromoteModal = () => {
    this.setState({ promoteModalStatus: false });
  }

  closeDeleteModal = () => {
    this.setState({ deleteModalStatus: false });
  }

  handleCheckboxChange = (e) => {
    if (e.target.checked) {
      this.props.getPurchasePendingProperties();
    } else {
      this.props.getAllProperties();
    }
    this.setState({ showPending: e.target.checked });
  }

  getPropertyRowClass = (status) => {
    let className = "";
    switch(status) {
      case "inactive":
        className = "inactive-property";
        break;
      case "pending-escrow":
        className = "pending-escrow-property";
        break;
      case "pending-utrust":
        className = "pending-utrust-property";
        break;
    }
    return className;
  }

  render() {
    const { alert } = this.props;
    const { properties, page } = this.state;

    return (
      <div className="main-content manage-property-page">
        <DasboardNavBarComponent pageName="manage-property" />
        <Box mb={3}>
          <Link to="/dashboard/properties/add">
            <Button variant="contained" color="primary">
              Upload Property
            </Button>
          </Link>
        </Box>
        <Box mb={1} ml={2}>
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.showPending}
                onChange={this.handleCheckboxChange}
                name="showPending"
              />
            }
            label="Show purchase pending properties"
          />
        </Box>

        {alert.message && (
          <NotificationBox
            open={true}
            variant={alert.type}
            message={alert.message}
          />
        )}
        {properties.length > 0 ? (
          <Card>
            <CardContent>
              <table className="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Title</th>
                    <th>Payment Reference Number</th>
                    <th>Address Line1</th>
                    <th>Address City</th>
                    <th>Address Postcode</th>
                    <th>Address Country</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <>
                    {properties.map((property, index) => {
                      if (this.state.showPending && !property.status.includes("pending")) {
                        return;
                      }
                      return (
                        <tr key={index} className={`${this.getPropertyRowClass(property.status)}`}>
                          <td>{page.page * page.size + index + 1}</td>
                          <td>{property.title}</td>
                          <td>{property.purchase_ref_number}</td>
                          <td>{property.address_line1}</td>
                          <td>{property.address_city}</td>
                          <td>{property.address_postcode}</td>
                          <td>{property.address_country}</td>
                          <td>
                            { property.status.includes("pending") ? (secureStorage.getItem("is_admin") &&
                              <Box component="span">
                                <Button
                                  variant="outlined"
                                  size="small"
                                  color="secondary"
                                  onClick={() =>
                                    this.openTransferModal(property)
                                  }
                                >
                                  Check request
                                </Button>
                              </Box> ) : (<>
                              <Box component="span" mr={1}>
                                <Link to={`/dashboard/properties/edit/${property.property_id}`}>
                                  <Button
                                      variant="outlined"
                                      size="small"
                                      color="primary"
                                    >
                                    Edit
                                  </Button>
                                </Link>
                              </Box>
                              { property.is_promoted ? (
                                  <Box component="span" mr={1}>
                                    <Button
                                      variant="outlined"
                                      size="small"
                                      color="primary"
                                      onClick={() =>
                                        this.handlePropertyDemote(property.property_id)
                                      }
                                    >
                                      Demote
                                    </Button>
                                  </Box>
                                ) : (
                                  <Box component="span" mr={1}>
                                    <Button
                                      variant="outlined"
                                      size="small"
                                      color="primary"
                                      onClick={() =>
                                        this.openPromoteModal(property.property_id)
                                      }
                                    >
                                      Promote
                                    </Button>
                                  </Box>
                                ) 
                              }
                              
                              <Box component="span" mr={1}>
                                <Button
                                  variant="outlined"
                                  size="small"
                                  color="secondary"
                                  onClick={() =>
                                    this.openDeleteModal(property.property_id)
                                  }
                                >
                                  Delete
                                </Button>
                              </Box>
                              <Box component="span">
                                <Button
                                  variant="outlined"
                                  size="small"
                                  color="secondary"
                                  onClick={() =>
                                    this.handlePropertyStatus(property.property_id)
                                  }
                                >
                                  { property.status === "active" ? "Deactivate" : "Activate" }
                                </Button>
                              </Box>
                            </>)}

                          </td>
                        </tr>
                      );
                    })}
                  </>
                  <TablePagination
                    rowsPerPageOptions={[10, 50]}
                    labelRowsPerPage="Items per page:"
                    page={page.page}
                    count={page.count}
                    rowsPerPage={page.size}
                    onRowsPerPageChange={(e) => {
                      this.handlePageChange({...page, page: 0, size: e.target.value});
                    }}
                    onPageChange={(e, idx) => {
                      this.handlePageChange({...page, page: idx});
                    }}
                  />
                </tbody>
              </table>
            </CardContent>
          </Card>
        ) : (
          <>
            <Divider /> <br />
            <Typography variant="h5">No properties found</Typography>
            <p>You can upload property or buy one on <strong><Link to="/marketplace">marketplace</Link></strong></p>
          </>
        )}
        {properties.length > 0 && (
          <Box sx={{ width: "100%", display: "flex" }}>
            <Box sx={{ my: 3, width: "100%", maxWidth: 550, }}>
              <Card>
                <CardContent>
                  <Typography variant="h5">Table Legend</Typography>
                  <Divider /> <Divider />
                  <List component="nav" aria-label="main mailbox folders">
                    <ListItem>
                      <Box
                        sx={{
                          py: 1,
                          px: 1.7,
                          mr: 2,
                          backgroundColor: "#f2c9c7",
                          borderRadius: 5
                        }}
                      ></Box>
                      <ListItemText primary="Inactive property" />
                    </ListItem>
                    <ListItem>
                      <Box
                        sx={{
                          py: 1,
                          px: 1.7,
                          mr: 2,
                          backgroundColor: "#cccddb",
                          borderRadius: 5
                        }}
                      ></Box>
                      <ListItemText primary="Utrust payment pending" />
                    </ListItem>
                    <ListItem>
                      <Box
                        sx={{
                          py: 1,
                          px: 1.7,
                          mr: 2,
                          backgroundColor: "#ccdbdb",
                          borderRadius: 5
                        }}
                      ></Box>
                      <ListItemText primary="Escrow payment pending" />
                    </ListItem>
                  </List>
                </CardContent>
              </Card>
            </Box>
          </Box>
        )}
        <DeleteModal
          open={this.state.deleteModalStatus}
          handleClose={this.closeDeleteModal}
          handleDelete={this.handlePropertyRemove}
          content={'property'}
        />
        <TransferPropertyModal
          handleClose={this.closeTransferModal}
          open={this.state.transferModalStatus}
          owner={this.state.users.owner}
          buyer={this.state.users.buyer}
          paymentRefNum={this.state.paymentRefNumber}
          handleReject={this.handleRejectTransferModal}
          handleFinish={this.handleFinishTransferModal}
        />
        <TokenPayModal
          handleClose={this.closePromoteModal}
          open={this.state.promoteModalStatus}
          action={this.state.modalAction}
          propertyTitle={this.state.selectedProperty.title}
          content={`You are about to ${this.state.modalAction} this property`}
          immoTokenBalance={this.state.immoTokenBalance}
          zbsTokenBalance={this.state.zbsTokenBalance}
          payAmount={this.state.propertyPricing.amount}
          handleFinish={this.handlePropertyPromote}
        />
      </div>
    );
  }
}

function mapState(state) {
  const { alert, general } = state;
  return { alert, general };
}
const actionCreators = {
  clearAlerts: alertActions.clear,
  getAllProperties: generalActions.getAllProperties,
  getPropertyPricing: generalActions.getPropertyPricing,
  getPurchasePendingProperties: generalActions.getPurchasePendingProperties,
  updatePropertyValues: generalActions.updatePropertyValues,
  transferProperty: generalActions.transferProperty,
  deletePropertyById: generalActions.deletePropertyById
};

const connectedPage = connect(mapState, actionCreators)(ManagePropertyPage);
export { connectedPage as ManagePropertyPage };