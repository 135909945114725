import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Paper, Popper, Grow, InputAdornment } from '@material-ui/core';
import InputRange from 'react-input-range';
import Switch from "react-switch";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import i18n from "../../i18n";
import { generalActions, alertActions } from '../../_actions';
import { history } from '../../_helpers';
import "react-input-range/lib/css/index.css";
import "./styles.scss";

class SearchBar extends Component {
  constructor(props) {
    super(props);

    this.filter1 = React.createRef();
    this.filter2 = React.createRef();
    this.filter3 = React.createRef();
    this.filter4 = React.createRef();

    this.state = {
      filterStatus: {
        filter1: false,
        filter2: false,
        filter3: false,
        filter4: false,
      },
      propertyValueRange: { min: 0, max: 800000 },
      pricePerShareRange: { min: 0, max: 1000 },
      dividendYieldRange: { min: 0, max: 10 },
      search: {
        location: [],
        type: [],
        status: "",
        order: "",
        availability: false,
        propertyValue: { min: 125000, max: 330000 },
        pricePerShareValue: { min: 100, max: 340 },
        dividendYieldValue: { min: 1.5, max: 4.2 },
      },
    }
    this.toggleAvailability = this.toggleAvailability.bind(this);
  }



  componentDidMount() {
    const { properties } = this.props;
    if (properties) {
      this.setState(prevState => ({
        ...prevState,
        search: {
          ...prevState.search,
          'propertyValue': {
            min: 0,
            max: properties.max_property_value ? properties.max_property_value : 0
          },
          'pricePerShareValue': {
            min: 0,
            max: properties.max_price_per_share ? properties.max_price_per_share : 0
          },
          'dividendYieldValue': {
            min: 0,
            max: properties.max_dividend_yield ? properties.max_dividend_yield : 0
          }
        },
        propertyValueRange: {
          min: 0,
          max: properties.max_property_value ? properties.max_property_value : 0
        },
        pricePerShareRange: {
          min: 0,
          max: properties.max_price_per_share ? properties.max_price_per_share : 0,
        },
        dividendYieldRange: {
          min: 0,
          max: properties.max_dividend_yield ? properties.max_dividend_yield : 0
        }
      }))
    }

    if (history.location.state && history.location.state.searchInvestment || history.location.state && history.location.state.location) {
      this.setState(prevState => ({
        ...prevState,
        search: {
          ...prevState.search,
          location: history.location.state.searchInvestment.location,
          'propertyValue': {
            min: 0,
            max: (history.location.state.searchInvestment && history.location.state.searchInvestment.propertyValue.max) ? history.location.state.searchInvestment.propertyValue.max : prevState.search.max_property_value
          },
          'pricePerShareValue': {
            min: 0,
            max: (history.location.state.searchInvestment && history.location.state.searchInvestment.pricePerShareValue.max) ? history.location.state.searchInvestment.pricePerShareValue.max : prevState.search.max_price_per_share
          },
          'dividendYieldValue': (history.location.state.searchInvestment && history.location.state.searchInvestment.dividendYieldValue) ? history.location.state.searchInvestment.dividendYieldValue : prevState.search.dividendYieldValue
        },
      }), () => {
        this.props.onUpdateFilters(this.state.search);
        history.replace({ state: {} });
      })
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { properties } = this.props;
    const { filterStatus, search } = this.state; if (properties && JSON.stringify(prevProps.properties) !== JSON.stringify(properties) &&
      properties.max_property_value) {
      let ppv_max = search.propertyValue.max
      let psv_max = search.pricePerShareValue.max
      let dyv_max = search.dividendYieldValue.max
      ppv_max = ppv_max ? Math.min(ppv_max, properties.max_property_value) : properties.max_property_value
      psv_max = psv_max ? Math.min(psv_max, properties.max_price_per_share) : properties.max_price_per_share
      dyv_max = dyv_max ? Math.min(dyv_max, properties.max_dividend_yield) : properties.max_dividend_yield
      this.setState(prevState => ({
        ...prevState,
        search: {
          ...prevState.search,
          'propertyValue': {
            min: search.propertyValue.min,
            max: ppv_max
          },
          'pricePerShareValue': {
            min: search.pricePerShareValue.min,
            max: psv_max
          },
          'dividendYieldValue': {
            min: search.dividendYieldValue.min,
            max: dyv_max
          }
        },
        propertyValueRange: {
          min: 0,
          max: properties.max_property_value > 0 ? properties.max_property_value : this.state.propertyValueRange.max
        },
        pricePerShareRange: {
          min: 0,
          max: properties.max_price_per_share > 0 ? properties.max_price_per_share : this.state.pricePerShareRange.max,
        },
        dividendYieldRange: {
          min: 0,
          max: properties.max_dividend_yield > 0 ? properties.max_dividend_yield : this.state.dividendYieldRange.max
        }
      }))
    }
  }


  componentWillUnmount() {
    // TODO: Check why this condition was set
    // history.replace({ state: {} });
  }

  handleChange = (event) => {
    const { search } = this.state;
    search[event.target.name] = event.target.value;
    this.setState({ search });
  }

  toggleAvailability(checked) {
    this.setState(prevState => ({
      search: {
        ...prevState.search,
        availability: checked
      }
    }));
    setTimeout(() => this.applyFilter(), 100);
  }


  setFilter = (value, type) => {
    this.setState(prevState => ({
      search: {
        ...prevState.search,
        [type]: value
      }
    }));
  }

  applyFilter = () => {
    this.props.clearAlerts();
    this.props.onUpdateFilters(this.state.search);
  }

  toggleFilter = (filter) => {
    this.setState(prevState => ({
      filterStatus: {
        ...prevState.filterStatus,
        filter1: false,
        filter2: false,
        filter3: false,
        filter4: false,
        [filter]: !prevState.filterStatus[filter]
      }
    }));

  }

  formateYeild(value) {
    return value.toFixed(1).toString().replace(".", ",")
  }

  formatePrice(price) {
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }


  render() {
    return (
      <div className='search-bar-block'>
        <div className="search-form">
          <Button variant="outlined" className="btn-filter" ref={this.filter1} onClick={() => { this.toggleFilter("filter1") }} >{i18n.t('Property value')}</Button>
          <Button variant="outlined" className="btn-filter" ref={this.filter2} onClick={() => { this.toggleFilter("filter2") }} >{i18n.t('Price per Share')}</Button>
          <Button variant="outlined" className="btn-filter" ref={this.filter3} onClick={() => { this.toggleFilter("filter3") }} >{i18n.t('Net rental return')}</Button>
          <Button variant="outlined" className="btn-filter" ref={this.filter4} onClick={() => { this.toggleFilter("filter4") }} >{i18n.t('Availability')}</Button>

          <Popper className="filter-box" open={this.state.filterStatus.filter1} anchorEl={this.filter1.current} role={undefined} transition disablePortal placement={"bottom-start"}>
            {({ TransitionProps }) => (
              <Grow
                {...TransitionProps}
              >
                <Paper className="filter-content">
                  <p className="txt-title">{i18n.t('Property values')}</p>

                  <ValidatorForm
                    autoComplete="off"
                    instantValidate
                  >
                    <TextValidator
                      fullWidth
                      value={this.state.search.propertyValue.min}
                      onChange={(e) => {
                        const min = parseInt(e.target.value) || this.state.propertyValueRange.min;
                        const max = this.state.search.propertyValue.max;
                        min >= this.state.propertyValueRange.min && min <= max && this.setFilter({ min, max }, 'propertyValue');
                      }}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                      min={this.state.propertyValueRange.min}
                      max={this.state.search.propertyValue.max}
                      type="number"
                      label="Min *"
                      validators={["required", "isNumber", "aboveThan"]}
                      errorMessages={["This field is required", ""]}
                    />
                    <TextValidator
                      fullWidth
                      value={this.state.search.propertyValue.max}
                      onChange={(e) => {
                        const min = this.state.search.propertyValue.min;
                        const max = parseInt(e.target.value) || 0;
                        max >= min && max <= this.state.propertyValueRange.max && this.setFilter({ min, max }, 'propertyValue');
                      }}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                      min={this.state.search.propertyValue.min}
                      max={this.state.propertyValueRange.max}
                      type="number"
                      label="Max *"
                      validators={["required", "isNumber", "belowThan"]}
                    />
                  </ValidatorForm>

                  <div className="action-footer">
                    <Button className="btn-delete" onClick={() => {
                      this.setFilter(this.state.propertyValueRange, 'propertyValue');
                      this.toggleFilter("filter1");
                      setTimeout(() => this.applyFilter(), 100);
                    }}>{i18n.t('Delete')}</Button>
                    <Button className="btn-save" onClick={() => {
                      this.toggleFilter("filter1");
                      this.applyFilter();
                    }}>{i18n.t('Save')}</Button>
                  </div>
                </Paper>
              </Grow>
            )}
          </Popper>

          <Popper className="filter-box" open={this.state.filterStatus.filter2} anchorEl={this.filter2.current} role={undefined} transition disablePortal placement={"bottom-start"}>
            {({ TransitionProps }) => (
              <Grow
                {...TransitionProps}
              >
                <Paper className="filter-content">
                  <p className="txt-title">{i18n.t('Price per Share')}</p>

                  <div className="range-slider">
                    <InputRange
                      maxValue={this.state.pricePerShareRange.max}
                      minValue={this.state.pricePerShareRange.min}
                      step={10}
                      value={this.state.search.pricePerShareValue}
                      onChange={value => this.setFilter(value, 'pricePerShareValue')}
                    />
                    <p className="range-value">
                      <span>&#36; {this.formatePrice(this.state.search.pricePerShareValue.min)}</span>
                      <span>-</span>
                      <span>&#36; {this.formatePrice(this.state.search.pricePerShareValue.max)}</span>
                    </p>
                  </div>

                  <div className="action-footer">
                    <Button className="btn-delete" onClick={() => {
                      this.setFilter(this.state.pricePerShareRange, 'pricePerShareValue');
                      this.toggleFilter("filter2");
                      setTimeout(() => this.applyFilter(), 100);
                    }}>{i18n.t('Delete')}</Button>
                    <Button className="btn-save" onClick={() => {
                      this.toggleFilter("filter2");
                      this.applyFilter();
                    }}>{i18n.t('Save')}</Button>
                  </div>
                </Paper>
              </Grow>
            )}
          </Popper>

          <Popper className="filter-box" open={this.state.filterStatus.filter3} anchorEl={this.filter3.current} role={undefined} transition disablePortal placement={"bottom-start"}>
            {({ TransitionProps }) => (
              <Grow
                {...TransitionProps}
              >
                <Paper className="filter-content">
                  <p className="txt-title">{i18n.t('Net rental return')}</p>

                  <div className="range-slider">
                    <InputRange
                      maxValue={this.state.dividendYieldRange.max}
                      minValue={this.state.dividendYieldRange.min}
                      step={0.1}
                      value={this.state.search.dividendYieldValue}
                      onChange={value => this.setFilter(value, 'dividendYieldValue')}
                    />
                    <p className="range-value">
                      <span>{this.formateYeild(this.state.search.dividendYieldValue.min)} %</span>
                      <span>-</span>
                      <span>{this.formateYeild(this.state.search.dividendYieldValue.max)} %</span>
                    </p>
                  </div>

                  <div className="action-footer">
                    <Button className="btn-delete" onClick={() => {
                      this.setFilter(this.state.dividendYieldRange, 'dividendYieldValue');
                      this.toggleFilter("filter3");
                      setTimeout(() => this.applyFilter(), 100);
                    }}>{i18n.t('Delete')}</Button>
                    <Button className="btn-save" onClick={() => {
                      this.toggleFilter("filter3");
                      this.applyFilter();
                    }}>{i18n.t('Save')}</Button>
                  </div>
                </Paper>
              </Grow>
            )}
          </Popper>

          <Popper className="filter-box" open={this.state.filterStatus.filter4} anchorEl={this.filter4.current} role={undefined} transition disablePortal placement={"bottom-start"}>
            {({ TransitionProps }) => (
              <Grow
                {...TransitionProps}
              >
                <Paper className="filter-content">
                  <div className="txt-title">
                    <span>{i18n.t('Availability')}</span>
                    <span>
                      <Switch
                        onColor="#25282a"
                        onHandleColor="#FFF"
                        handleDiameter={20}
                        height={30}
                        width={44}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        onChange={this.toggleAvailability}
                        checked={this.state.search.availability}
                        className='btn-toggle-switch' />
                    </span>
                  </div>
                  <p className="txt-content">{i18n.t('Only show Properties if Shares are for sale')}</p>

                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      </div>
    )
  }
}

function mapState(state) {
  const { alert } = state;
  return { alert };
}

const actionCreators = {
  filterProperty: generalActions.filterProperty,
  clearAlerts: alertActions.clear,
}

export default connect(mapState, actionCreators)(SearchBar);


