import React, { Component } from 'react';
import { connect } from 'react-redux';
import Switch from "react-switch";
import i18n from "../../../i18n";
import { FormControl, Select, MenuItem, Button } from '@material-ui/core';
import { ValidatorForm } from 'react-material-ui-form-validator'
import SelectValidator from '../../../components/SelectValidatorElement'
import { userActions, alertActions } from '../../../_actions';
import NotificationBox from '../../../components/GeneralComponent/NotificationBox';
import "./styles.scss";


class Settings extends Component {
    constructor(props) {
        super(props);

        this.state = {
            formData: {
                user_id: 0,
                allow_property_updates: false,
                allow_newsletter: false,
                allow_property_location: false,
                language: "en",
                theme: "light",
                measurement: "metric",
            }
        }
    }


    static getDerivedStateFromProps(props, state) {
        const { profile_data } = props.users;
        if (profile_data && profile_data.user_id !== state.formData.user_id) {
            return {
                formData: {
                    user_id: profile_data.user_id,
                    allow_property_updates: profile_data.allow_property_updates,
                    allow_newsletter: profile_data.allow_newsletter,
                    allow_property_location: profile_data.allow_property_location,
                    language: profile_data.language,
                    theme: profile_data.theme || "light",
                    measurement: profile_data.measurement || "metric",
                    update_form_type: "settings"
                }
            }
        }
        return null;
    }


    handleChange = (event) => {
        var fieldName = event.target.name;
        var fieldValue = event.target.value;
        this.setState(prevState => ({
            formData: {
                ...prevState.formData,
                [fieldName]: fieldValue
            }
        }));
    }

    togglePropertyUpdatesSwitch = (checked) => {
        this.setState(prevState => ({
            formData: {
                ...prevState.formData,
                allow_property_updates: checked
            }
        }));
    }

    toggleNewsletterSwitch = (checked) => {
        this.setState(prevState => ({
            formData: {
                ...prevState.formData,
                allow_newsletter: checked
            }
        }));
    }

    togglePropertyLocationSwitch = (checked) => {
        this.setState(prevState => ({
            formData: {
                ...prevState.formData,
                allow_property_location: checked
            }
        }));
    }

    measurementSwitch = (checked) => {
        const measurement = checked ? 'metric' : 'imperial'
        this.setState(prevState => ({
            formData: {
                ...prevState.formData,
                measurement
            }
        }));
    }

    handleUpdateSettings = () => {
        this.props.updateUserProfile(this.state.formData).then(value => {
            this.props.handleFetchUserProfile();
            setTimeout(() => this.props.clearAlerts(), 1000);
        });
    }

    render() {
        const { formData } = this.state;
        const { alert } = this.props;

        return (
            <ValidatorForm
                className='default-form'
                autoComplete="off"
                instantValidate
            >
                <div className='settings-tab'>
                    <p className="page-block-title">{i18n.t("Theme")}</p>
                    <div className="user-profile-form">
                        <FormControl variant="outlined" fullWidth>
                            <SelectValidator
                                name="theme"
                                value={formData.theme}
                                onChange={this.handleChange}
                                fullWidth
                                displayEmpty
                                className="form-control"
                                validators={['required']}
                            >
                                <MenuItem value="" disabled>{i18n.t('Select Theme')}</MenuItem>
                                <MenuItem value="light">Light</MenuItem>
                                <MenuItem value="sea">Sea</MenuItem>
                                <MenuItem value="cosmos">Cosmos</MenuItem>
                                <MenuItem value="spring">Springtime</MenuItem>
                                <MenuItem value="forest">Dark Forest</MenuItem>
                                <MenuItem value="butterfly">Butterflies</MenuItem>
                                <MenuItem value="sensual">Sensual</MenuItem>
                                <MenuItem value="dark">Dark</MenuItem>
                            </SelectValidator>
                        </FormControl>
                    </div>

                    <p className="page-block-title">{i18n.t("Measurement")}</p>
                    <div className="list-item">
                        <div className="item-type">
                            <span className='txt-info'>
                                {formData.measurement === 'metric' ? 'Metric measurement' : 'Imperial measurement'}
                            </span>
                        </div>
                        <div className="check-type">
                            <Switch
                                offColor="#f3f4f6"
                                onColor="#f3f4f6"
                                offHandleColor="#98a9bb"
                                onHandleColor="#98a9bb"
                                handleDiameter={18}
                                height={27}
                                width={50}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                onChange={this.measurementSwitch}
                                checked={formData.measurement === 'metric'}
                                className='btn-toggle-switch'
                            />
                        </div>
                    </div>

                    {alert.message && <NotificationBox open={true} variant={alert.type} message={alert.message} />}
                    <p className="page-block-title">{i18n.t("Notifications")}</p>

                    <div className="user-profile-form">
                        <div className="list-item">
                            <div className="item-type">
                                <p className="txt-type">{i18n.t('Property updates')}</p>
                                <p className="txt-info">{i18n.t('Allow property notifications to get updates straight into your inbox.')} </p>
                            </div>
                            <div className="check-type">
                                <Switch
                                    offColor="#f3f4f6"
                                    onColor="#e52800"
                                    offHandleColor="#98a9bb"
                                    onHandleColor="#FFF"
                                    handleDiameter={18}
                                    height={27}
                                    width={50}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    onChange={this.togglePropertyUpdatesSwitch}
                                    checked={formData.allow_property_updates}
                                    className='btn-toggle-switch' />
                            </div>
                        </div>

                        <div className="list-item">
                            <div className="item-type">
                                <p className="txt-type">{i18n.t('Newsletter')}</p>
                                <p className="txt-info">{i18n.t('Subscribe to the Immobilium newsletter and you\'ll stay up to date with our News and Updates.')}</p>
                            </div>
                            <div className="check-type">
                                <Switch
                                    offColor="#f3f4f6"
                                    onColor="#e52800"
                                    offHandleColor="#98a9bb"
                                    onHandleColor="#FFF"
                                    handleDiameter={18}
                                    height={27}
                                    width={50}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    onChange={this.toggleNewsletterSwitch}
                                    checked={formData.allow_newsletter}
                                    className='btn-toggle-switch' />
                            </div>
                        </div>

                        <div className="list-item">
                            <div className="item-type">
                                <p className="txt-type">{i18n.t('Property location')}</p>
                                <p className="txt-info">{i18n.t('Allow location notifications to get information if you are close to an available opportunity.')}</p>
                            </div>
                            <div className="check-type">
                                <Switch
                                    offColor="#f3f4f6"
                                    onColor="#e52800"
                                    offHandleColor="#98a9bb"
                                    onHandleColor="#FFF"
                                    handleDiameter={18}
                                    height={27}
                                    width={50}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    onChange={this.togglePropertyLocationSwitch}
                                    checked={formData.allow_property_location}
                                    className='btn-toggle-switch' />
                            </div>
                        </div>

                        {/* <div className="list-item">
                            <div className="select-item-type">
                                <p className="txt-type">{i18n.t('Language')}</p>
                                <p className="txt-info">{i18n.t('Select your preferred language.')}</p>
                            </div>
                            <div className="select-type">
                                <FormControl variant="outlined" fullWidth>
                                    <Select name="language" value={formData.language} onChange={this.handleChange} fullWidth displayEmpty>
                                        <MenuItem value="" disabled>Select language</MenuItem>
                                        <MenuItem value={"en"}>{i18n.t('English')}</MenuItem>
                                        <MenuItem value={"de"}>{i18n.t('German')}</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </div> */}

                        <div className="form-action">
                            <Button className="btn btn-primary btn-filled-out" onClick={this.handleUpdateSettings} variant={"contained"}>{i18n.t('Update Settings')}</Button>
                            <Button className="btn btn-primary" variant={"contained"}>{i18n.t('Cancel')}</Button>
                        </div>
                    </div>
                </div>
            </ValidatorForm>
        )
    }
}

function mapState(state) {
    const { users, alert } = state;
    return { users, alert };
}

const actionCreators = {
    updateUserProfile: userActions.updateUserProfile,
    clearAlerts: alertActions.clear,
}

export default connect(mapState, actionCreators)(Settings);
