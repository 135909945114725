import { locationConstants } from '../_constants';
import { locationService } from '../_services';
import { alertActions } from './';



const addLocation = (formData) => dispatch => {
    return new Promise(function (resolve, reject) {
        locationService.addLocation(formData)
            .then(
                addLocationResponse => {
                    if (addLocationResponse.status) {
                        dispatch(alertActions.success('Location add successfully'));
                        resolve('resolved');
                    }
                    else {
                        dispatch(alertActions.error(addLocationResponse.message.toString()));
                    }
                }
            );
    });
}


function getLocations() {
    return dispatch => {
        locationService.getLocations()
            .then(
                getLocationsResponse => {
                    if (getLocationsResponse.status) {
                        dispatch(success(getLocationsResponse));
                    }
                    else {
                        dispatch(alertActions.error(getLocationsResponse.message.toString()));
                    }
                }
            );
    };

    function success(getLocationsResponse) { return { type: locationConstants.GET_LOCATIONS_SUCCESS, payload: getLocationsResponse.data } }
}

function getLocationById(locationId) {
    return dispatch => {
        locationService.getLocationById(locationId)
            .then(
                locationResponse => {
                    if (locationResponse.status) {
                        dispatch(success(locationResponse));
                    }
                    else {
                        dispatch(alertActions.error(locationResponse.message.toString()));
                    }
                }
            );
    };

    function success(locationResponse) { return { type: locationConstants.GET_LOCATION_BY_ID_SUCCESS, payload: locationResponse.data } }
}

const updateLocationById = (updateLocationData) => dispatch => {
    return new Promise(function (resolve, reject) {
        locationService.updateLocationById(updateLocationData)
            .then(
                updateLocationResponse => {
                    if (updateLocationResponse.status) {
                        dispatch(alertActions.success('Location updated successfully'));
                        resolve('resolved');
                    }
                    else {
                        dispatch(alertActions.error(updateLocationResponse.message.toString()));
                    }
                }
            );
    });
}

const deleteLocationById = (locationId) => dispatch => {
    return new Promise(function (resolve, reject) {
        locationService.deleteLocationById(locationId)
            .then(
                removeLocationRes => {
                    if (removeLocationRes.status) {
                        dispatch(alertActions.success('Location removed successfully'));
                        resolve('resolved');
                    }
                    else {
                        dispatch(alertActions.error(removeLocationRes.message.toString()));
                    }
                }
            );
    })
}

export const locationActions = {
    addLocation,
    getLocations,
    getLocationById,
    updateLocationById,
    deleteLocationById
};
