import { useEffect, useState } from 'react';
import { userService } from '../_services/user.service';
import { Button, Chip, Divider } from '@material-ui/core';
import ReplayIcon from '@material-ui/icons/Replay';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import ErrorIcon from '@material-ui/icons/Error';
import Container from '@material-ui/core/Container';
import InfoIcon from '@material-ui/icons/Info';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LinearProgress from '@material-ui/core/LinearProgress';

export const VerificationPage = () => {
  const [ondatoLink, setOndatoLink] = useState('');
  const [ondatoValidationError, setOndatoValidationError] = useState('');
  const [pendingStatus, setPendingStatus] = useState(false);
  const [successStatus, setSuccessStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [newSessionNote, setNewSessionNote] = useState(`Please do not interrupt 
    the verification process when you start, otherwise you will have to start 
    the process from the beginning`);
  useEffect(() => {
    initiateKycValidation();
  }, []);

  const retryKycValidation = () => {
    setOndatoValidationError('');
    initiateKycValidation();
  };

  const initiateKycValidation = () => {
    setIsLoading(true);
    userService.checkUserKycValidation().then((res) => {
        console.log(res, "KYC-RESPONSE")
      if (res.status && res.redirect_url) {
        setNewSessionNote(res.kyc_session_fail_reason ? 
          res.kyc_session_fail_reason : newSessionNote
        );
        setOndatoLink(res.redirect_url);
      } else if (res.status && ['APPROVED', 'SUCCESS'].includes(res.kyc_session_status)) {
        setSuccessStatus(true);
      } else if (res.status == false && res.kyc_session_fail_reason) {
        setOndatoValidationError(res.kyc_fail_message ? 
          res.kyc_fail_message : res.kyc_session_fail_reason);
      } else {
        setPendingStatus(true);
      }
    }).catch(error => console.log(error)).finally(() => setIsLoading(false));   
  };
  const startKycValidation = () => {
    console.log(ondatoLink, 'ONDATO-LINK');
    window.location.href = ondatoLink;
  };

  const goToLoginPage = () => {
    userService.logout();
  };
  return (
    <Container>
      <h1>KYC VERIFICATION</h1>
      <Divider />
      { isLoading && <>
      <p>Loading...</p>
      <LinearProgress />
      </> }
      {successStatus && (
        <>
          <h3>
            <DoneAllIcon /> You have successfully passed the verification process.
            Log in again so you can use the platform
          </h3>
          <Button
            onClick={goToLoginPage}
            variant="outlined"
            startIcon={<ExitToAppIcon />}
          >
            Go to Login page
          </Button>
        </>
      )}

      {ondatoLink && (
        <>
          <h3>
            Before you can use all the features of this platform we need to verify your identity.{' '}
            <br />
            Click on the button bellow to start the identity verification process. <br />
            Please note, you will be taken to third party website for this process. <br />
          </h3>
          <Chip
            color="secondary"
            label={newSessionNote}
            variant="outlined"
            icon={<ErrorOutlineIcon />}
          ></Chip>
          <br />
          <br />
          <Button
            onClick={startKycValidation}
            variant="outlined"
            startIcon={<PlayCircleOutlineIcon />}
          >
            Start KYC verification
          </Button>
        </>
      )}

      {ondatoValidationError && (
        <>
          <h3>
            Verification process failed, please try again. <br />
            Reason:
          </h3>
          <Chip
            color="secondary"
            label={ondatoValidationError}
            variant="outlined"
            icon={<ErrorIcon />}
          ></Chip>
          <br />
          <br />
          <Button
            variant="outlined"
            onClick={retryKycValidation}
            startIcon={<ReplayIcon />}
          >
            Start Again
          </Button>
        </>
      )}

      {pendingStatus && (
        <>
          <h3>
            <InfoIcon /> Your application is currently being processed
          </h3>
          <br />
        </>
      )}
    </Container>
  );
};
