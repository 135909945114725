import { authHeader } from '../_helpers';
import { secureStorage } from '../_services';

export const userService = {
    login,
    logout,
    register,
    getUserDetails,
    getUserByEmail,
    sendResetEmail,
    resetPassword,
    depositMoneyRequest,
    depositMoney,
    withdrawMoney,
    checkUserKycValidation,
    updateUserProfile,
    updateProfile,
    getSecurityQuestion,
    addUserInvestment,
    getUserInvestment,
    getUserCommittedInvestment,
    getUserLatestInvestment,
    getUserPayments,
    getSettings,
    addUserOrder,
    getUserRecentTransactions,
    getUsersOrders,
    getUserOrders,
    deleteUserOrder,
    verifyUserShares,
    getUserUnrealisedProfit,
    getUserRealisedProfitData,
    getUserRealisedProfit
};

function login(email_id, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email_id, password })
    };

    return fetch(`${process.env.REACT_APP_API_URL}/login`, requestOptions)
        .then(handleResponse)
        .then(result => {
            if (result.success) {
                if (result.data.token && result.data.token.split('.').length > 1) {
                    var base64Url = result.data.token.split('.')[1];
                    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
                    var jsonPayload = decodeURIComponent(
                      atob(base64)
                        .split('')
                        .map(function (c) {
                          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                        })
                        .join('')
                    );
                    var tokenData = JSON.parse(jsonPayload);
                    if(Boolean(tokenData.is_kyc_verified)){
                        secureStorage.setItem('is_kyc_verified', true);
                    } else {
                        secureStorage.removeItem('is_kyc_verified');
                    }
                    if(tokenData.is_admin){
                        secureStorage.setItem('is_admin', true);
                    } else {
                        secureStorage.removeItem('is_admin');
                    }
                    if(tokenData.wallet_address) {
                        secureStorage.setItem('wallet_address', tokenData.wallet_address);
                    } else {
                        secureStorage.removeItem('wallet_address');
                    }
                  } 
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                secureStorage.setItem(process.env.REACT_APP_LOGIN_KEY, JSON.stringify(result.data.token));
                return result.data.token;
            }
            else {
                return false;
            }
        });
}


function register(user) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };

    return fetch(`${process.env.REACT_APP_API_URL}/register`, requestOptions).then(handleResponse);
}


function logout() {
    secureStorage.removeItem('is_admin');
    secureStorage.removeItem('is_kyc_verified');
    secureStorage.removeItem('wallet_address');
    secureStorage.removeItem(process.env.REACT_APP_LOGIN_KEY); // remove user from local storage to log user out
    window.location.href = "/";
}


function getUserDetails() {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader() },
    };

    return fetch(`${process.env.REACT_APP_API_URL}/users/profile`, requestOptions).then(handleResponse);
}

function getUserByEmail(email) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader() },
    };

    return fetch(`${process.env.REACT_APP_API_URL}/users/profile/email/${email}`, requestOptions).then(handleResponse);
}

function sendResetEmail(email) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
    };

    return fetch(`${process.env.REACT_APP_API_URL}/users/profile/request-reset-password`, requestOptions)
        .then(handleResponse);
}

function resetPassword(updateObj) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({ ...updateObj }),
    };

    return fetch(`${process.env.REACT_APP_API_URL}/users/profile/reset-password`, requestOptions).then(handleResponse);
}

function depositMoneyRequest(data) {
    const requestOptions = {
        method: 'PATCH',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({ ...data }),
    };

    return fetch(`${process.env.REACT_APP_API_URL}/users/deposit-request`, requestOptions).then(handleResponse);
}

function depositMoney(userId, updateObj) {
    const requestOptions = {
        method: 'PATCH',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({ ...updateObj }),
    };

    return fetch(`${process.env.REACT_APP_API_URL}/users/deposit/${userId}`, requestOptions).then(handleResponse);
}

function withdrawMoney(userId, updateObj) {
    const requestOptions = {
        method: 'PATCH',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({ ...updateObj }),
    };

    return fetch(`${process.env.REACT_APP_API_URL}/users/withdraw/${userId}`, requestOptions).then(handleResponse);
}

function checkUserKycValidation() {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader() },
    };

    return fetch(`${process.env.REACT_APP_API_URL}/users/kyc`, requestOptions).then(handleResponse);
}


function updateUserProfile(updateObj) {
    var formData = new FormData();
    if (updateObj) {
        Object.keys(updateObj).forEach(function (key) {
            if (key !== 'profile_preview') {
                if (key === 'security_questions') {
                    var security_questions = JSON.stringify(updateObj[key]);
                    formData.append(key, security_questions);
                } else {
                    formData.append(key, updateObj[key]);
                }
            }
        });
    }
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader() },
        body: formData
    };

    return fetch(`${process.env.REACT_APP_API_URL}/users/profile`, requestOptions).then(handleResponse)
}

function updateProfile(updateObj) {
    const formData = new FormData();
    if (updateObj) {
        Object.keys(updateObj).forEach(function (key) {
            if (key !== 'profile_preview') {
                if (key === 'security_questions') {
                    var security_questions = JSON.stringify(updateObj[key]);
                    formData.append(key, security_questions);
                } else {
                    formData.append(key, updateObj[key]);
                }
            }
        });
    }

    const requestOptions = {
        method: 'PATCH',
        headers: { ...authHeader() },
        body: formData
    };

    return fetch(`${process.env.REACT_APP_API_URL}/users/profile`, requestOptions).then(handleResponse);
}


function getSecurityQuestion() {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader() },
    };

    return fetch(`${process.env.REACT_APP_API_URL}/users/profile/security-question`, requestOptions).then(handleResponse);
}


function addUserInvestment(dataObj) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(dataObj)
    };

    return fetch(`${process.env.REACT_APP_API_URL}/users/investment`, requestOptions).then(handleResponse);
}


function getUserInvestment() {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader() },
    };

    return fetch(`${process.env.REACT_APP_API_URL}/users/investment`, requestOptions).then(handleResponse);
}

function getUserCommittedInvestment() {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader() },
    };

    return fetch(`${process.env.REACT_APP_API_URL}/users/committed-investment`, requestOptions).then(handleResponse);
}

function getUserLatestInvestment() {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader() }
    };

    return fetch(`${process.env.REACT_APP_API_URL}/users/latest-investment`, requestOptions).then(handleResponse);
}

function getUserPayments() {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader() }
    };

    return fetch(`${process.env.REACT_APP_API_URL}/users/payments`, requestOptions).then(handleResponse);
}


function getSettings() {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader() },
    };

    return fetch(`${process.env.REACT_APP_API_URL}/settings`, requestOptions).then(handleResponse);
}

function addUserOrder(orderDetails) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(orderDetails)
    };

    return fetch(`${process.env.REACT_APP_API_URL}/users/order`, requestOptions).then(handleResponse);
}

function getUserRecentTransactions(propertyId) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader() },
    };

    return fetch(`${process.env.REACT_APP_API_URL}/users/recent-transaction?propertyId=${propertyId}`, requestOptions).then(handleResponse);
}

function getUsersOrders(propertyId) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader() },
    };

    return fetch(`${process.env.REACT_APP_API_URL}/users/orders-modal?propertyId=${propertyId}`, requestOptions).then(handleResponse);
}

function getUserOrders() {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader() },
    };

    return fetch(`${process.env.REACT_APP_API_URL}/users/orders`, requestOptions).then(handleResponse);
}

function deleteUserOrder(orderDetails) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(orderDetails)
    };

    return fetch(`${process.env.REACT_APP_API_URL}/users/order-delete`, requestOptions).then(handleResponse)
}


function verifyUserShares(propertyId, investmentDetailObj) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({
            investment_detail: investmentDetailObj,
            propertyId
        })
    };

    return fetch(`${process.env.REACT_APP_API_URL}/users/verify-shares`, requestOptions).then(handleResponse);
}

function getUserUnrealisedProfit() {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader() },
    };

    return fetch(`${process.env.REACT_APP_API_URL}/users/unrealised-profit`, requestOptions).then(handleResponse);
}

function getUserRealisedProfit() {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader() },
    };

    return fetch(`${process.env.REACT_APP_API_URL}/users/realised-profit`, requestOptions).then(handleResponse);
}

function getUserRealisedProfitData() {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader() },
    };

    return fetch(`${process.env.REACT_APP_API_URL}/users/realised-profit-chart`, requestOptions).then(handleResponse);
}






function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if(response.status === 451) {
            window.location = "/users/kyc";
        }
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                window.location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

