import React from 'react';
import SmallStats from './../ChartComponent/SmallStats';
import "./styles.scss";

const OverviewWidgetBlock1 = (props) => {
    function formatePrice(n) {
        var val = Math.round(Number(n) * 100) / 100;
        var parts = val.toString().split(".");
        var num = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".") + (parts[1] ? "," + parts[1] : "");
        return num;
    }

    const { chartData, chartLabels, type, variation } = props;

    return (
        <div className='overview-widget-block-1'>
            <div className="value-block">
                {
                    type === 1 && (
                        <>
                            <p className="txt-value widget-block-2">
                                <span>&#36; {formatePrice(props.value)}</span>
                                <span className={props.percentage > 0 ? "txt-percentage percentage-profit" : " txt-percentage percentage-loss"}>
                                    {formatePrice(props.percentage)}
                                </span>
                            </p>
                            <p className="txt-title widget-block-2">
                                <span>{props.block_text}</span>
                                <span className={props.percentage_value > 0 ? "txt-portfolio-value portfolio-profit" : "txt-portfolio-value portfolio-loss"}>
                                    {formatePrice(props.percentage_value)}
                                </span>
                            </p>
                        </>
                    )
                }
                {
                    type === 2 && (
                        <>
                            <p className="txt-value widget-block-2">
                                <span>&#36; {formatePrice(props.value)}</span>
                                <span>{formatePrice(props.percentage)} %</span>
                            </p>
                            <p className="txt-title widget-block-2">
                                <span>{props.block_text}</span>
                                <span>{props.block_text_2}</span>
                            </p>
                        </>
                    )
                }
                {
                    type === 3 && (
                        <>
                            <p className="txt-value">&#36; {formatePrice(props.value)} <span className="profit-loss-percentage">{formatePrice(props.percentage)} %</span></p>
                            <p className="txt-title">{props.block_text}</p>
                        </>
                    )
                }
            </div>

            {/* <div className="chart-block">
                <SmallStats
                    id={`small-stats-${1}`}
                    variation={variation}
                    chartData={chartData}
                    chartLabels={chartLabels}
                />
            </div> */}
        </div>
    )

}

OverviewWidgetBlock1.defaultProps = {
    variation: "2"
}

export default OverviewWidgetBlock1;