import React, { useEffect, useRef, useState } from 'react';
import QRCode from 'qrcode';
import { Dialog, DialogContent, Box, Button } from '@material-ui/core';
import CreditCardOutlinedIcon from '@material-ui/icons/CreditCardOutlined';
import i18n from "../../../i18n";
import "./styles.scss";

const DepositInfoModal = ({
    paymentRefNum,
    depositAmount,
    open,
    handleClose,
    handleBack,
    handleFinish,
}) => {
    const canvasRef = useRef();
    const [state, setState] = useState({
        accountButton: {
            text: "Copy",
            disabled: false,
        },
        refNumberButton: {
            text: "Copy",
            disabled: false,
        },
    });
    const [bic, setBic] = useState('BPOTBEB1');
    const [receiverName, setReceiverName] = useState('Immobilium');
    const [iban, setIban] = useState('BE72000000001616');

    useEffect(() => {
        setTimeout(() => generateQRCode(), 100);
    })
    useEffect(() => {
        generateQRCode();
    }, [canvasRef.current]);

    const generateQRCode = () => {
        // https://www.qr-code-generator.com/solutions/epc-qr-code/
        // https://en.wikipedia.org/wiki/EPC_QR_code
        const epc_qr_code =
            `BCD` + "\n" +
            `001` + "\n" +
            `1` + "\n" +
            `SCT` + "\n" +
            `${bic}` + "\n" +
            `${receiverName}` + "\n" +
            `${iban}` + "\n" +
            `USD${depositAmount}` + "\n" +
            `CHAR` + "\n" +
            `${paymentRefNum}` + "\n" +
            ``;
        if (canvasRef.current) {
            QRCode.toCanvas(canvasRef.current, epc_qr_code, function (error) {
                if (error) console.error(error)
                console.log('success!');
            });
        }   
    }

    const copyToClipboard = (event) => {
        let element = "";
        const update = {};
        if (event.currentTarget.name === "btn-account") {
            element = document.getElementById("acc_iban");
            update["accountButton"] = {
                text: "Copied!",
                disabled: true,
            };
        } else if (event.currentTarget.name === "btn-number") {
            element = document.getElementById("ref_num");
            update["refNumberButton"] = {
                text: "Copied!",
                disabled: true,
            };
        }

        const range = document.createRange();
        range.selectNode(element);
        window.getSelection().removeAllRanges(); // clear current selection
        window.getSelection().addRange(range); // to select text
        document.execCommand("copy");
        window.getSelection().removeAllRanges();// to deselect

        setState({...state, ...update});
        Object.keys(update).forEach((key) => {
            update[key] = {
                text: "Copy",
                disabled: false,
            };
        });

        setTimeout(() => setState({...state, ...update }), 2000);
    }

    const formatDate = () => {
        const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

        const now = new Date();
        const day = now.getDate();
        const month = months[now.getMonth()].substring(0, 3);
        const hours = now.getHours().toString().length > 1 ? now.getHours() : `0${now.getHours()}`;
        const minutes = now.getMinutes().toString().length > 1 ? now.getMinutes() : `0${now.getMinutes()}`;

        return `${day} ${month} ${hours}:${minutes}`;
    }

    const formatAmount = (amount) => {
        amount = amount.split("").reverse().join("");
        if (amount) {
            const digits = amount.length;
            amount = amount.replace(/.{3}/g, '$&,');
            if (digits % 3 === 0)
                amount = amount.substring(0, amount.length-1);
        }
        return amount.split("").reverse().join("");
    }

    return (
        <Dialog className='deposit-modal wider-modal' fullWidth={true} maxWidth={"xs"} onClose={handleClose} open={open}>
            <DialogContent>
                <div className="block-header">{i18n.t('Deposit')}</div>
                <div className="payment-icon"><CreditCardOutlinedIcon /></div>

                <div className="block-content">
                    <p className="txt-status">+{formatAmount(depositAmount)} ($)</p>
                </div>

                <div className="block-content">
                    <p className="text">
                        To make payments in US Dollars, please transfer your funds to designated Escrow Service Provider - as per specification stated below.
                        <br /><br />
                        Please make sure to enter the Payment Reference exactly as it appears below, as that helps us automate the allocation of funds.
                        <br /><br />
                        In case of doubt or if you need more information, do reach out to our support team using either Telegram chat or Email: support@immobilium.io
                    </p>
                </div>

                <div className='qr-canvas'>
                    <canvas ref={canvasRef}></canvas>
                </div>                

                <div className="list-item">
                    <div className="left-block">
                        <p id="acc_iban" className="txt-value">{iban}</p>
                        <p className="txt-title">{i18n.t('IBAN')}</p>
                    </div>
                    <div className="right-block">
                        <Button 
                            name='btn-account' 
                            className='btn-copy' 
                            onClick={copyToClipboard} 
                            disabled={state.accountButton.disabled}
                        >
                            {i18n.t(state.accountButton.text)}
                        </Button>
                    </div>
                </div>
                
                <div className="list-item">
                    <div className="left-block">
                        <p id="ref_num" className="txt-value">{paymentRefNum}</p>
                        <p className="txt-title">{i18n.t('Payment Reference Number')}</p>
                    </div>
                    <div className="right-block"> 
                        <Button 
                            name='btn-number' 
                            className='btn-copy' 
                            onClick={copyToClipboard}
                            disabled={state.refNumberButton.disabled}
                        >
                            {i18n.t(state.refNumberButton.text)}
                        </Button>
                    </div>
                </div>

                <Box my={1}>
                    <Button className="btn-print" onClick={handleBack}>{i18n.t('Back')}</Button>
                    <Button className="btn-print" onClick={handleFinish}>{i18n.t('Finish deposit')}</Button>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default DepositInfoModal;
