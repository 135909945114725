import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { userActions } from "../../_actions";
import {
  Button,
  List,
  ListItem,
  ListItemText,
  Link,
  Popper,
  Paper,
  Grow,
  MenuItem,
  MenuList,
  ClickAwayListener,
} from "@material-ui/core";
import { withTranslation } from "react-i18next";
import PersonIcon from "@material-ui/icons/Person";
import "./styles.scss";
import LanguageSelect from "../LanguageSelect/LanguageSelect";

class HeaderLinks extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dropDownOpen: false,
      profile_data: {},
    };

    this.loggedUser = false;
    var tokenInfo = this.props.parseJwt();
    if (tokenInfo && tokenInfo.status) {
      this.loggedUser = tokenInfo.data;
      this.props.getUserDetails();
    }
    this.anchorRef = createRef();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    var update = {};
    if (
      nextProps.users &&
      nextProps.users.profile_data &&
      nextProps.users.profile_data !== prevState.profile_data
    ) {
      update = {
        profile_data: nextProps.users.profile_data,
      };
    }

    return update;
  }

  logOut = () => {
    this.props.logOut();
  };

  handleToggle = () => {
    this.setState({ dropDownOpen: !this.state.dropDownOpen });
  };

  handleClose = (event) => {
    if (
      this.anchorRef.current &&
      this.anchorRef.current.contains(event.target)
    ) {
      return;
    }

    this.setState({ dropDownOpen: false });
  };

  render() {
    return (
      <List className="header-links">
        <ListItem>
          <ListItemText>
            <NavLink to="/marketplace">{this.props.t("Buy")}</NavLink>
          </ListItemText>
        </ListItem>
        {this.loggedUser ? (
          <>
            <ListItem>
              <ListItemText>
                <NavLink to="/dashboard">{this.props.t("Dashboard")}</NavLink>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                {/* TODO add translation */}
                <NavLink to="/dashboard/properties">Sell</NavLink>
              </ListItemText>
            </ListItem>

            <ListItem>
              <ListItemText>
                <LanguageSelect />
              </ListItemText>
            </ListItem>

            <ListItem
              ref={this.anchorRef}
              className="user-avtar"
              aria-controls="menu-list-grow"
              aria-haspopup="true"
              onClick={this.handleToggle}
            >
              <div>
                {this.state.profile_data &&
                this.state.profile_data.profile_image ? (
                  <div>
                    <img
                      alt="user-avtar"
                      className="user-avtar-img"
                      src={this.state.profile_data.profile_image}
                    />
                  </div>
                ) : (
                  <div className="user-avtar-img">
                    <PersonIcon />
                  </div>
                )}
              </div>
              <span className="text-username">
                {this.loggedUser.first_name}
              </span>
              {/*<span className="caret down"></span>*/}

              <Popper
                className="custom-dropdown"
                open={this.state.dropDownOpen}
                anchorEl={this.anchorRef.current}
                transition
                disablePortal
              >
                {({ TransitionProps }) => (
                  <Grow {...TransitionProps}>
                    <Paper id="menu-list-grow">
                      <ClickAwayListener onClickAway={this.handleClose}>
                        <MenuList>
                          <MenuItem>
                            <NavLink to="/dashboard/profile">
                              <span>{this.props.t("Profile")}</span>
                            </NavLink>
                          </MenuItem>

                          {/*
                                                        <MenuItem>
                                                            <NavLink to="/dashboard/profile/?settings"><span>{i18n.t('Settings')}</span></NavLink>
                                                        </MenuItem>
                                                        <MenuItem>
                                                            <NavLink to="/dashboard/profile/?verification"><span>{i18n.t('Verification')}</span></NavLink>
                                                        </MenuItem>
                                                        <MenuItem>
                                                            <Link underline="none"><span>{i18n.t('Invite friends')}</span></Link>
                                                        </MenuItem> */}
                          <MenuItem onClick={this.logOut}>
                            <Link underline="none">
                              <span>{this.props.t("Logout")}</span>
                            </Link>
                          </MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </ListItem>

          </>
        ) : (
          <>
            {/* <ListItem>
                                    <ListItemText>
                                        <NavLink to="/">{i18n.t('Howto')}</NavLink>
                                    </ListItemText>
                                </ListItem> */}
            <ListItem>
              <ListItemText>
                <NavLink to="/login" onClick={this.props.navControl}>
                  {this.props.t("Login")}
                </NavLink>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                <NavLink to="/register" onClick={this.props.navControl}>
                  {this.props.t("Register")}
                </NavLink>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                <LanguageSelect />
              </ListItemText>
            </ListItem>
          </>
        )}
      </List>
    );
  }
}

function mapState(state) {
  const { users, authentication } = state;
  const { user } = authentication;
  return { user, users };
}

const actionCreators = {
  parseJwt: userActions.parseJwt,
  logOut: userActions.logout,
  getUserDetails: userActions.getUserDetails,
};

const connectedHeaderLinks = connect(mapState, actionCreators)(HeaderLinks);
export default withTranslation()(connectedHeaderLinks);
