import React from 'react';

const NotFound = () => {
  return (
    <div className='not-found'>Not found</div>
  );
};

export default NotFound;
export { NotFound };
