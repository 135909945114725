import React, { Component } from 'react';
import {
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import HeaderComponent from '../components/HeaderComponent';
import FooterComponent from '../components/FooterComponent';
import HomePage from '../pages/HomePage';
import NotFound from '../pages/NotFound';

import { SearchPage } from '../pages/SearchPage';
import { PropertyDetailPage } from '../pages/PropertyDetailsPage';
import { ManagePropertyPage } from '../pages/PropertyPage/ManagePropertyPage';
import { AddPropertyPage } from '../pages/PropertyPage/AddPropertyPage';
import DashboardPage from '../pages/Dashboard/DashboardPage';
import InvestmentWalletPage from '../pages/Dashboard/InvestmentWalletPage';
import ProfilePage from '../pages/Dashboard/ProfilePage';
import PaymentsPage from '../pages/Dashboard/PaymentsPage';
import OrdersPage from '../pages/Dashboard/Orders';
import TransactionsPage from '../pages/Dashboard/TransactionsPage';
import { VerificationPage } from '../pages/VerificationKycPage';
import TokenWalletPage from '../pages/Dashboard/TokenWalletPage';

import AuthLayout from './layouts/AuthLayout';

const UserRoute = ({
  logopath,
  pagebackground,
  logobackground,
}) => {
  return (
    <Routes>
      <Route
        path="/users/kyc"
        element={
          <AuthLayout kycPage={true}>
            <>
              <HeaderComponent
                logopath={logopath}
                logobackground={logobackground}
                stickyPosition={200}
                noStickyClass="homeNoStickedHeader"
              />
              <VerificationPage />
            </>
          </AuthLayout>
        }
      />
      <Route
        path="/dashboard/orders"
        element={
          <AuthLayout>
            <OrdersPage />
          </AuthLayout>
        }
      />
      <Route
        path="/dashboard/tokenwallet"
        element={<AuthLayout><TokenWalletPage /></AuthLayout>}
      />
      <Route
        path="/dashboard/payments"
        element={
          <AuthLayout>
            <PaymentsPage />
          </AuthLayout>
        }
      />
      <Route
        path="/dashboard/transactions"
        element={
          <AuthLayout>
            <TransactionsPage />
          </AuthLayout>
        }
      />
      <Route
        path="/dashboard/properties"
        element={
          <AuthLayout checkWallet={true}>
            <ManagePropertyPage />
          </AuthLayout>
        }
      />
      <Route
        path="/dashboard/properties/edit/:id"
        element={
          <AuthLayout>
            <AddPropertyPage />
          </AuthLayout>
        }
      />
      <Route
        path="/dashboard/properties/add"
        element={
          <AuthLayout>
            <AddPropertyPage />
          </AuthLayout>
        }
      />
      <Route
        path="/dashboard/wallet"
        element={
          <AuthLayout>
            <InvestmentWalletPage />
          </AuthLayout>
        }
      />
      <Route
        path="/dashboard/profile"
        element={
          <AuthLayout>
            <ProfilePage />
          </AuthLayout>
        }
      />
      <Route
        path="/dashboard"
        element={
          <AuthLayout>
            <DashboardPage />
          </AuthLayout>
        }
      />
      <Route
        path="/property/view/:id"
        element={
          <>
            <HeaderComponent
              logopath={logopath}
              logobackground={logobackground}
            />
            <PropertyDetailPage />
          </>
        }
      />
      <Route
        path="/marketplace"
        element={
          <>
            <div className="fixed">
              <HeaderComponent
                logopath={logopath}
                logobackground={logobackground}
              />
            </div>
            <SearchPage />
          </>
        }
      />

      <Route
        exact
        path="/"
        element={
          <>
            <HeaderComponent
              logopath={logopath}
              logobackground={logobackground}
              stickyPosition={200}
              noStickyClass="homeNoStickedHeader"
            />
            <HomePage background={pagebackground} />
            <FooterComponent />
          </>
        }
      />
      <Route path="*" element={
        <>
          <HeaderComponent
            logopath={logopath}
            logobackground={logobackground}
            stickyPosition={200}
            noStickyClass="homeNoStickedHeader"
          />
          <NotFound />
          <FooterComponent />
        </>
      } />
    </Routes >
  )
}

export default UserRoute;
export { UserRoute };
