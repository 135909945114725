import React, { createContext, useState } from 'react';

// export interface IConfirmContextState {
//   prompt:
//     | string
//     | {
//         title: string;
//         message: string | React.ReactNode;
//         okay?: string;
//         cancel?: string;
//       }
//     | React.ReactNode;
//   isOpen: boolean;
//   proceed: (() => void) | undefined;
//   cancel: (() => void) | undefined;
// }

// interface IConfirmContextType extends Array<any> { }

export const ConfirmContext = createContext([]);
// export interface IConfirmContextProps {
//   children?: any;
// }

const ConfirmContextProvider = ({ children }) => {
  const [confirm, setConfirm] = useState({
    prompt: '',
    isOpen: false,
    proceed: undefined,
    cancel: undefined,
    
    isLoading: false,
    waitingMessage: '',
  });

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <ConfirmContext.Provider value={[confirm, setConfirm]}>
      {children}
    </ConfirmContext.Provider>
  );
};

ConfirmContextProvider.defaultProps = {
  children: undefined,
};

export { ConfirmContextProvider };
export default ConfirmContextProvider;
