import React, { Component } from 'react';
import RoomIcon from '@material-ui/icons/Room';
import i18n from "../../i18n";
import { initialState } from '../../_reducers/general.reducer';


import "./styles.scss";

class PropertyTitle extends Component {

  render() {
    const { property } = this.props;
    return (
      <div className='property-title-box'>
        <p className='property-name'>{property.title}</p>
        <p className='property-location'><RoomIcon />
          {/* {property.address_city !== '' && property.address_country !== '' ?
                        (<span>{property.address_city + ', ' + property.address_country}</span>) :
                        <span>{i18n.t("Not provided")}</span>
                    } */}
          {
            <span>{(initialState.languageCode === 'en') ? property.macrolocation_heading : property.macrolocation_heading}, {i18n.t(property.address_country)}</span>
          }
        </p>
      </div>
    )
  }
}

export default PropertyTitle;
