import React, { Component } from 'react';
import { Card, CardContent, Grid } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import ReactHtmlParser from 'react-html-parser';
import { initialState } from '../../_reducers/general.reducer';
import "./styles.scss";

export default class InvestmentDetails extends Component {
    formatePrice = (val) => {
        if (val !== undefined && val !== null && !isNaN(val)) {
            var parts = val.toString().split(".");
            if (parts.length > 1) {
                val = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".") + (parts[1] ? "," + parts[1] : "");
            }
            val = this.numberWithCommas(val);
            return val;
        }
    }

    numberWithCommas = (x) => {
        if (x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        }
        return x;
    }

    getSectionLanguageBasedDetail = (sectionData) => {
        var heading = initialState.languageCode === 'en' ? sectionData.section_heading_en : sectionData.section_heading_de;
        var description = initialState.languageCode === 'en' ? sectionData.section_description_en : sectionData.section_description_de;
        return {
            heading,
            description
        }
    }

    calculateDistributionYield = (distribution_surplus, financing_volume) => {
        distribution_surplus = Number(distribution_surplus);
        financing_volume = Number(financing_volume);
        var distributionYield = (distribution_surplus / financing_volume) * 100;
        if (!isNaN(distributionYield)) {
            return distributionYield.toFixed(2);
        }
        return 0;
    }

    getLanguageBasedPredictValue = (property) => {
        if (property) {
            const predicted_value_increase = initialState.languageCode === 'en' ? property.predicted_value_increase_en : property.predicted_value_increase_de;
            return predicted_value_increase;
        }
        return "";
    }

    getLanguageBasedDocumentName = (doc) => {
        const doc_name = initialState.languageCode === 'en' ? doc.file_description_en : doc.file_description_de;
        return doc_name;
    }

    getLanguageBasedForecastedDistribution = (property) => {
        const forecastedDistributions = initialState.languageCode === 'en' ? property.forecasted_distribution_en : property.forecasted_distribution_de;
        return forecastedDistributions;
    }

    getLanguageBasedLegalWarnings = (property) => {
        const legalWarnings = initialState.languageCode === 'en' ? property.legal_warnings_en : property.legal_warnings_de;
        return legalWarnings;
    }

    render() {
        const { property } = this.props;
        const isPropertyDocuments = property && property.property_documents && property.property_documents.length > 0;
        const investmentSection = property.sections ? property.sections.find((section) => { return section.section_key === 'the_investment' }) : "";

        return (
            <div className='investment-details'>
                {investmentSection && (
                    <div className="section">
                        <h2 className="title-text">{this.getSectionLanguageBasedDetail(investmentSection).heading}</h2>
                        <div className="content-text">{ReactHtmlParser(this.getSectionLanguageBasedDetail(investmentSection).description)}</div>
                    </div>
                )}
                <p className="content-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vestibulum pellentesque nulla quis efficitur. Suspendisse varius ante justo, nec vulputate turpis lacinia at. Nunc aliquam ullamcorper magna, non tempor diam auctor eu. Mauris nec feugiat nunc. Suspendisse lorem ligula, pretium non eros in, aliquam dapibus turpis. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Duis viverra dictum ante et ornare. </p>

                {property && <Card className="financial-detail-box">
                    <CardContent>
                        <table className="table">
                            <tbody>
                                <tr>
                                    <td><strong>Financing Volume</strong></td>
                                    <td><strong>{this.formatePrice(property.financing_volume)} &euro;</strong></td>
                                </tr>
                                <tr>
                                    <td><strong>Forecasted Total Return p.a.*</strong></td>
                                    <td><strong>{this.formatePrice(Number(this.calculateDistributionYield(property.distribution_surplus, property.financing_volume)) + Number(property.predicted_value_increase_p_a))}</strong></td>
                                </tr>
                                <tr>
                                    <td>Forecasted Distribution Yield p.a.*</td>
                                    <td>{this.formatePrice(this.calculateDistributionYield(property.distribution_surplus, property.financing_volume))}</td>
                                </tr>
                                <tr>
                                    <td>Forecasted Appreciation p.a.*</td>
                                    <td>{this.formatePrice(property.predicted_value_increase_p_a)}</td>
                                </tr>
                            </tbody>
                        </table>
                    </CardContent>
                </Card>}

                {property && <Grid container spacing={3}>
                    <Grid className="distribution-yield-block" item md={6} xs={12}>
                        <h2 className="txt-title txt-title-secondary">Forecasted Distribution*</h2>
                        <table className="table">
                            <tbody>
                                <tr>
                                    <td><strong>+ Net Rental Income</strong></td>
                                    <td><strong>{this.formatePrice(Number(property.net_rental_income))} &euro;</strong></td>
                                </tr>
                                <tr>
                                    <td><strong>- Management Costs</strong></td>
                                    <td><strong> {this.formatePrice(Number(property.property_management_cost) + Number(property.management_costs) + Number(property.rent_protection_cost) + Number(property.maintenance_cost))} &euro;</strong></td>
                                </tr>
                                <tr className="list-item">
                                    <td>Property Management</td>
                                    <td>{this.formatePrice(Number(property.property_management_cost))} &euro;</td>
                                </tr>
                                <tr className="list-item">
                                    <td>Asset Management Fee</td>
                                    <td>{this.formatePrice(Number(property.management_costs))} &euro;</td>
                                </tr>
                                <tr className="list-item">
                                    <td>Loss of rent Reserve</td>
                                    <td>{this.formatePrice(Number(property.rent_protection_cost))} &euro;</td>
                                </tr>
                                <tr className="list-item">
                                    <td>Maintenance Reserve</td>
                                    <td>{this.formatePrice(Number(property.maintenance_cost))} &euro;</td>
                                </tr>
                                <tr>
                                    <td><strong>= Rent Surplus / Distribution</strong></td>
                                    <td><strong>{this.formatePrice(Number(property.distribution_surplus))} &euro;</strong></td>
                                </tr>
                                <tr className="list-item">
                                    <td>Distribution Yield</td>
                                    <td>{this.calculateDistributionYield(property.distribution_surplus, property.financing_volume)}</td>
                                </tr>
                            </tbody>
                        </table>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <h2 className="txt-title">Forecasted Appreciation*</h2>
                        <p className="content-text">{this.getLanguageBasedPredictValue(property)}</p>
                    </Grid>
                </Grid>}

                <p className="content-text">
                    {ReactHtmlParser(this.getLanguageBasedForecastedDistribution(property))}
                </p>

                <h2 className="txt-title txt-title-secondary">Documents</h2>
                <Grid className="download-block" container spacing={3}>
                    {isPropertyDocuments &&
                        property.property_documents.filter(d => !d.is_delete).map((document, index) => {
                            return (
                                <Grid key={index} item md={4} xs={12}>
                                    <a href="#"><GetAppIcon /> <span className="doc-name">{this.getLanguageBasedDocumentName(document)}</span></a>
                                </Grid>
                            )
                        })
                    }
                </Grid>

                <h2 className="txt-title txt-title-secondary">Legal Warnings</h2>
                <p className="content-text">
                    {ReactHtmlParser(this.getLanguageBasedLegalWarnings(property))}
                </p>
            </div>
        );
    }
}
