import React, { Suspense } from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';

import { store } from './_helpers';
import { ThemeProvider, createTheme } from "@material-ui/core/styles";

import { ConfirmContextProvider, ConfirmModal } from './components/Confirm';

import './assets/css/theme.scss';
import './assets/css/custom.scss';
import App from './App';
import registerServiceWorker from './registerServiceWorker';

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";


// const piwik = new ReactPiwik({
//     url: 'https://__/',
//     siteId: 2,
//     trackErrors: false,
//     jsFilename: 'matomo.js',
//     phpFilename: 'matomo.php'
// })

// ReactAppzi.initialize('0zhhn');

//piwik.connectToHistory(createHashHistory())

// Sentry.init({
//   dsn: "https://49bf369cc0a04d39878dbd5949654fb1@sentry.bixbit.me/12",
//   integrations: [new Integrations.BrowserTracing()],

//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });

const theme = createTheme({
  typography: {
    fontFamily: "'Open Sans' !important",
  },
  shape: {
    borderRadius: 8,
  },
});

render(
  <Suspense fallback=''>
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <ConfirmContextProvider>

        <App />

        <ConfirmModal />
      </ConfirmContextProvider>
    </ThemeProvider>
  </Provider>
  </Suspense>,

  document.getElementById('root')
);
registerServiceWorker();
