/* eslint-disable no-unused-expressions */
import React, { useContext, useEffect, useState } from 'react';
import { ConfirmContext } from './ConfirmContextProvider';

const useConfirm = () => {
  const [confirm, setConfirm] = useContext(ConfirmContext);
  const [needsCleanup, setNeedsCleanup] = useState(false);

  const isConfirmed = (
    // prompt type: string |
    //   {
    //     title: string,
    //     message: string | React.ReactNode,
    //     okay?: string,
    //     cancel?: string,
    //   } | React.ReactNode,
    prompt,    
    onOkay,
    onCancel,

  ) => {
    const promise = new Promise((resolve, reject) => {
      setConfirm({
        ...confirm,
        prompt, isOpen: true, proceed: resolve, cancel: reject,
      });
      setNeedsCleanup(true);
    });

    const reset = () => {
      setConfirm({
        ...confirm,
        prompt: '', proceed: null, cancel: null, isOpen: false,
      });
      setNeedsCleanup(false);
    };

    return promise.then(
      () => {
        onOkay && onOkay();
        reset();
        return true;
      },
      () => {
        onCancel && onCancel();
        reset();
        return false;
      },
    );
  };

  const showLoader = (waitingMessage) => {
    setConfirm({
      ...confirm,
      isLoading: true,
      waitingMessage,
    });

    const reset = () => {
      setConfirm({
        ...confirm,
        isLoading: false,
        waitingMessage: '',
      });
    };

    return reset;
  }

  // Call cancel in a cleanup func to avoid dangling confirm dialog
  useEffect(() => () => {
    if (confirm.cancel && needsCleanup) {
      confirm.cancel();
    }
  }, [confirm, needsCleanup]);

  return {
    ...confirm,
    isConfirmed,
    showLoader
  };
};

export { useConfirm };
export default useConfirm;
