import React, { Component } from 'react';
import { connect } from 'react-redux';
import i18n from "../../../i18n";
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { Dialog, DialogContent, Card, CardContent, Grid, Button } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { TabPanel, a11yProps } from '../../GeneralComponent/TabPanel';
import TradeModal from './TradeModal';
import CloseIcon from '@material-ui/icons/Close';
import 'react-circular-progressbar/dist/styles.css';
import "./styles.scss";



class OrderBookModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            property: {},
            tabValue: isNaN(props.tabIndex) ? 2 : props.tabIndex,
            order_details: {
                _property_id: 0,
                _order_reference_id: null,
                _user_reference_id: null,
                order_type: 'Buy',
                quantity: '',
                price: '',
                amount: 0,
                fee: 0,
                fee_amount: 0,
                total_amount: 0,
                order_complete: false
            },
            settings: [],
            recent_transactions: [],
            users_orders: [],
            tradeModalStatus: false,
            forwardStateToTradeModal: false,
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { users } = nextProps;
        let update = {};
        if (users.recent_transactions && users.recent_transactions.status === true && users.recent_transactions.data && users.recent_transactions.data !== prevState.recent_transactions) {
            update = {
                ...update,
                recent_transactions: users.recent_transactions.data
            }
        }

        if (users.users_orders && users.users_orders.status === true && users.users_orders.data && users.users_orders.data !== prevState.users_orders) {
            update = {
                ...update,
                users_orders: users.users_orders.data
            }
        }

        if (users.settings_data && users.settings_data.status === true && users.settings_data.data) {
            update = {
                ...update,
                settings: users.settings_data.data
            }
        }

        if (nextProps.property !== prevState.property) {
            update = {
                ...update,
                property: nextProps.property,
                order_details: {
                    ...prevState.order_details,
                    _property_id: nextProps.property.property_id
                }
            }
        }

        return update;
    }

    handleButtonAction = (orderType) => {
        this.setState(prevState => ({
            ...prevState,
            order_details: {
                ...prevState.order_details,
                'order_type': orderType,
                initialStep: (orderType === "Buy" || orderType === "Kaufe") ? 1 : 2
            }
        }), () => {
            const { order_details } = this.state;
            var tempObj = {
                target: {
                    name: "quantity",
                    value: order_details.quantity
                }
            };
            this.handleChange(tempObj)
        });
    }

    formatePrice = (val) => {
        if (val !== undefined && val !== null && !isNaN(val)) {
            var parts = val.toString().split(".");
            if (parts.length > 1) {
                val = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".") + (parts[1] ? "," + parts[1] : "");
            }
            val = this.numberWithCommas(val);
            return val;
        }
    }

    numberWithCommas = (x) => {
        if (x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        }
        return x;
    }

    getFeeAmount(investmentAmount, feeRate) {
        if (investmentAmount && feeRate) {
            return parseFloat((investmentAmount / 100) * feeRate).toFixed(2);
        }
        return 0;
    }

    getFeeRate = () => {
        const { settings } = this.state;
        var order_fee_rate = settings.find((i) => { return i.key === 'order_fee' });
        if (order_fee_rate) {
            return parseFloat(order_fee_rate.value);
        }
        return 0
    }

    getTotalAmount = (investmentAmount, feeAmount) => {
        const { order_details } = this.state;
        if (investmentAmount && feeAmount) {
            var orderType = order_details.order_type;
            if (orderType === "Buy" || orderType === "Kaufe") {
                return parseFloat(parseFloat(investmentAmount) + parseFloat(feeAmount)).toFixed(2);
            }
            return parseFloat(parseFloat(investmentAmount) - parseFloat(feeAmount)).toFixed(2);
        }
        return 0;
    }

    getInvestmentOrSalesPrice = (quantity, price) => {
        if (quantity && price) {
            return (quantity * price).toFixed(2);
        }
        return 0;
    }


    handlePlaceOrderFromBookingOrder = (order, orderType) => {
        if (order) {
            if (orderType === "Buy" || orderType === "Kaufe") {
                order.total_amount = order.amount + order.fee_amount;
                order.initialStep = 1;
            } else {
                order.total_amount = order.amount - order.fee_amount;
                order.initialStep = 2;
            }
            this.setState(prevState => ({
                order_details: {
                    ...prevState.order_details,
                    _order_reference_id: order.id,
                    _user_reference_id: order._user_id,
                    order_type: orderType,
                    quantity: order.quantity,
                    price: order.price,
                    amount: order.amount,
                    fee: order.fee,
                    fee_amount: order.fee_amount,
                    total_amount: order.total_amount,
                    initialStep: order.initialStep,
                    order_complete: true
                }
            }), () => {
                this.props.handleClose();
                this.setState({ forwardStateToTradeModal: true }, () => {
                    this.setState({ tradeModalStatus: true })
                });
            })
        }
    }



    handleChange = (event) => {
        var fieldName = event.target.name;
        var fieldValue = event.target.value;
        if (fieldName === 'quantity') {
            this.setState((prevState) => {
                var amount = this.getInvestmentOrSalesPrice(fieldValue, prevState.order_details.price);
                return {
                    order_details: {
                        ...prevState.order_details,
                        [fieldName]: fieldValue,
                        'amount': parseFloat(amount),
                        'fee': parseFloat(this.getFeeRate()),
                        'fee_amount': parseFloat(this.getFeeAmount(amount, this.getFeeRate())),
                        'total_amount': parseFloat(this.getTotalAmount(amount, this.getFeeAmount(amount, this.getFeeRate())))
                    }
                }
            });
        } else if (fieldName === 'price') {
            this.setState((prevState) => {
                var amount2 = this.getInvestmentOrSalesPrice(prevState.order_details.quantity, fieldValue);
                return {
                    order_details: {
                        ...prevState.order_details,
                        [fieldName]: fieldValue,
                        'amount': parseFloat(amount2),
                        'fee': parseFloat(this.getFeeRate()),
                        'fee_amount': parseFloat(this.getFeeAmount(amount2, this.getFeeRate())),
                        'total_amount': parseFloat(this.getTotalAmount(amount2, this.getFeeAmount(amount2, this.getFeeRate())))
                    }
                }
            });
        }
    };

    closeModal = () => {
        this.props.handleClose();
        if (!isNaN(this.props.tabIndex)) {
            this.setState({
                ...this.state,
                tabValue: this.props.tabIndex
            });
        }
    }

    BuyTrade = () => {
        this.form.isFormValid(false).then((isValid) => {
            if (isValid) {
                this.props.handleClose();
                this.setState(prevState => ({
                    forwardStateToTradeModal: true,
                    order_details: {
                        ...prevState.order_details,
                        order_complete: false
                    }
                }), () => {
                    this.setState({ tradeModalStatus: true })
                })
            }
        })
    }

    handleTradeModalClose = () => {
        this.setState({ forwardStateToTradeModal: false }, () => {
            this.setState({ tradeModalStatus: false })
        });
    }

    handleTabChange = (event, newValue) => {

        this.setState({ tabValue: newValue })
        this.resetOrderTransaction();
    };

    placeOrder = () => {
        this.form.isFormValid(false).then((isValid) => {
            if (isValid) {
                this.props.handleClose();
                this.setState(prevState => ({
                    forwardStateToTradeModal: true,
                    order_details: {
                        ...prevState.order_details,
                        order_complete: false
                    }
                }), () => {
                    this.setState({ tradeModalStatus: true })
                });
            }
        })
    }

    resetOrderTransaction = () => {
        this.setState(prevState => ({
            order_details: {
                ...prevState.order_details,
                _order_reference_id: null,
                _user_reference_id: null,
                order_type: 'Buy',
                quantity: '',
                price: '',
                amount: 0,
                fee: 0,
                fee_amount: 0,
                total_amount: 0,
                order_complete: false
            },
        }))
    }

    handleSubmit = () => { }



    render() {
        const { property, tabValue, order_details, recent_transactions, users_orders } = this.state;

        return (
            <>
                <Dialog className='order-modal' onClose={this.closeModal} open={this.props.open}>
                    <DialogContent>
                        <Button className="btn-modal-close" onClick={this.closeModal}><CloseIcon /></Button>
                        <ValidatorForm
                            className='order-book-form'
                            onSubmit={this.handleSubmit}
                            ref={(r) => { this.form = r; }}
                            autoComplete="off"
                            instantValidate
                        >
                            <h2 className="modal-title">{i18n.t('Investment')} </h2>
                            {property && <div className="title-box">
                                <div>
                                    {property.property_images && property.property_images.length > 0 && (
                                        <img src={property.property_images[0].image_path} className="property-img" alt="Property" />
                                    )}
                                </div>
                                <div className="assets-box">
                                    <p className="txt-asset-title">{property.title}</p>
                                    <p className="txt-asset-symbol">{property.token_name}</p>
                                </div>
                            </div>}
                            {property && <div className="content-box">
                                <Card className="card-box">
                                    <CardContent>
                                        <Tabs
                                            value={tabValue}
                                            onChange={this.handleTabChange}
                                            className="tabs-header-container"
                                            indicatorColor="primary"
                                            textColor="primary"
                                            variant="scrollable"
                                            scrollButtons="auto"
                                            aria-label="scrollable auto tabs example"
                                        >
                                            <Tab className="tab-header-name" label={i18n.t("Current offers")} {...a11yProps(0)} />
                                            <Tab className="tab-header-name" style={{ display: 'none' }} label={i18n.t("Recent Transactions")} {...a11yProps(1)} />
                                            <Tab className="tab-header-name" label={i18n.t("Create offer")} {...a11yProps(2)} />
                                        </Tabs>
                                        <TabPanel value={tabValue} index={0} className="order-book-panel">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th>{i18n.t('Offer')}</th>
                                                        <th>{i18n.t('Quantity')}</th>
                                                        <th>{i18n.t('Price')}</th>
                                                        <th>{i18n.t('Total Price')}</th>
                                                        <th>{i18n.t('Return')} *</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {users_orders && users_orders.length > 0 ? (
                                                        users_orders.map((order, index) => {
                                                            var orderType = (order.order_type === 'Buy' || order.order_type === 'Kaufe') ? 'Sell' : 'Buy';
                                                            var orderTypeText = (order.order_type === 'Buy' || order.order_type === 'Kaufe') ? i18n.t('Sell') : i18n.t('Buy');
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{i18n.t(order.order_type)}</td>
                                                                    <td>{order.quantity}</td>
                                                                    <td>{order.price}</td>
                                                                    <td>&#36; {order.amount}</td>
                                                                    <td>{this.formatePrice((((property.distribution_surplus / (order.price * property.total_share_count)) * 100).toFixed(2) * 1 + property.predicted_value_increase_p_a * 1).toFixed(2))}%</td>
                                                                    <td><a href="#!" onClick={() => this.handlePlaceOrderFromBookingOrder(order, orderType)}>{orderTypeText}</a></td>
                                                                </tr>
                                                            )
                                                        })
                                                    ) : (
                                                            <tr>
                                                                <td colSpan="5" style={{
                                                                    textAlign: 'center',
                                                                    fontSize: '14px',
                                                                    color: '#212529',
                                                                    fontWeight: 400
                                                                }}>
                                                                    {i18n.t('No Current Offers')}
                                                                </td>
                                                            </tr>
                                                        )}
                                                </tbody>
                                            </table>
                                        </TabPanel>
                                        <TabPanel value={tabValue} index={1} className="recent-transaction-panel" style={{ display: 'none' }}>
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th>{i18n.t('Offer')}</th>
                                                        <th>{i18n.t('Quantity')}</th>
                                                        <th>{i18n.t('Price')}</th>
                                                        <th>{i18n.t('Total Price')}</th>
                                                        <th>{i18n.t('Date')}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {recent_transactions && recent_transactions.length > 0 ? (
                                                        recent_transactions.map((transaction, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{transaction.order_type}</td>
                                                                    <td>{transaction.quantity}</td>
                                                                    <td>{transaction.price}</td>
                                                                    <td>&#36; {transaction.amount}</td>
                                                                    <td>{transaction.createdAt}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    ) : (
                                                            <tr>
                                                                <td colSpan="5" style={{ textAlign: 'center', fontSize: '14px' }}>
                                                                    {i18n.t('No Recent Transactions')}
                                                                </td>
                                                            </tr>
                                                        )}
                                                </tbody>
                                            </table>
                                        </TabPanel>
                                        <TabPanel value={tabValue} index={2} className="place-order-panel">
                                            <div className="btn-container">
                                                <Grid
                                                    container
                                                    direction="row"
                                                    justify="center"
                                                    alignItems="center"
                                                    spacing={1}
                                                >
                                                    <Grid item xs={12} sm={4} md={3}>
                                                        <Button
                                                            fullWidth
                                                            name="Buy"
                                                            onClick={() => this.handleButtonAction("Buy")}
                                                            className={((order_details.order_type === 'Buy' || order_details.order_type === 'Kaufe') ? "btn-buy-active" : "selling-button") + ' btn-buy'}
                                                        >
                                                            {i18n.t('Buy')}
                                                        </Button>
                                                    </Grid>
                                                    <Grid item xs={12} sm={4} md={3}>
                                                        <Button
                                                            fullWidth
                                                            name="Sell"
                                                            onClick={() => this.handleButtonAction("Sell")}
                                                            className={(order_details.order_type === 'Sell' ? "btn-sell-active" : "selling-button") + ' btn-sell'}
                                                        >
                                                            {i18n.t('Sell')}
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                            <div className="inputbox-container">
                                                <div className="input-container-quantity">
                                                    <Grid
                                                        container
                                                        direction="row"
                                                        justify="center"
                                                        alignItems="center"
                                                        spacing={1}
                                                    >
                                                        <Grid item xs={12} sm={4} md={4}>
                                                            <label className="input-label">{i18n.t('Quantity')}</label>
                                                        </Grid>
                                                        <Grid item xs={12} sm={8} md={8}>
                                                            <div className="input-field">
                                                                <TextValidator
                                                                    fullWidth
                                                                    variant="outlined"
                                                                    size="small"
                                                                    value={order_details.quantity}
                                                                    onChange={this.handleChange}
                                                                    name="quantity"
                                                                    className="form-control"
                                                                    placeholder={i18n.t("Enter amount")}
                                                                    validators={['required', 'isNumber', 'minNumber:1']}
                                                                    errorMessages={[
                                                                        i18n.t('This field is required!'),
                                                                        i18n.t('This field only contains numbers'),
                                                                        i18n.t('Quantity must be greater than 0')
                                                                    ]}
                                                                />
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                                <div className="input-container-price">
                                                    <Grid
                                                        container
                                                        direction="row"
                                                        justify="center"
                                                        alignItems="center"
                                                        spacing={1}
                                                    >
                                                        <Grid item xs={12} sm={4} md={4}>
                                                            <label className="input-label">{i18n.t('Price / Brick')}</label>
                                                        </Grid>
                                                        <Grid item xs={12} sm={8} md={8}>
                                                            <div className="input-field">
                                                                <TextValidator
                                                                    fullWidth
                                                                    variant="outlined"
                                                                    value={order_details.price}
                                                                    onChange={this.handleChange}
                                                                    name="price"
                                                                    className="form-control"
                                                                    placeholder={i18n.t("Enter amount")}
                                                                    validators={['required', 'isFloat', 'minNumber:1']}
                                                                    errorMessages={[
                                                                        i18n.t('This field is required!'),
                                                                        i18n.t('This field only contains numbers'),
                                                                        i18n.t('Quantity must be greater than 0')
                                                                    ]}
                                                                />
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            </div>
                                            <div className="sales-information-container">
                                                {order_details.order_type === 'Sell' && (
                                                    <Grid item xs={12} sm={10} md={8}>
                                                        <div className="value-block">
                                                            <p className="sale-price-container">
                                                                <span className="text-title">{i18n.t('Your Sales Price')}</span>
                                                                <span className="txt-amount">$ {this.formatePrice(order_details.amount)}</span>
                                                            </p>
                                                            <p className="sale-fee-container">
                                                                <span className="text-title">{i18n.t('Fee')} ({this.formatePrice(this.getFeeRate() || 0)}%)</span>
                                                                <span className="txt-amount"> - $ {this.formatePrice(order_details.fee_amount)}</span>
                                                            </p>
                                                            <p className="line-breaker"></p>
                                                            <p className="sale-total-container">
                                                                <span className="total-title">{i18n.t('Sales Proceeds')}</span>
                                                                <span className="total-amount"> = $ {this.formatePrice(order_details.total_amount)}</span>
                                                            </p>
                                                        </div>
                                                    </Grid>
                                                )}
                                                {(order_details.order_type === 'Buy' || order_details.order_type === 'Kaufe') && (
                                                    <Grid item xs={12} sm={10} md={8}>
                                                        <div className="value-block">
                                                            <p className="sale-price-container">
                                                                <span className="text-title">{i18n.t('Your Investment')}</span>
                                                                <span className="txt-amount">$ {this.formatePrice(order_details.amount)}</span>
                                                            </p>
                                                            <p className="sale-fee-container">
                                                                <span className="text-title">{i18n.t('Fee')} ({this.formatePrice(this.getFeeRate() || 0)}%)</span>
                                                                <span className="txt-amount"> + $ {this.formatePrice(order_details.fee_amount)}</span>
                                                            </p>
                                                            <p className="line-breaker"></p>
                                                            <p className="sale-total-container">
                                                                <span className="total-title">{i18n.t('Total Amount')}</span>
                                                                <span className="total-amount"> = $ {this.formatePrice(order_details.total_amount)}</span>
                                                            </p>
                                                        </div>
                                                    </Grid>
                                                )}
                                            </div>
                                        </TabPanel>
                                    </CardContent>
                                </Card>

                                {(tabValue === 0 || tabValue === 1) && (
                                    <div className="action-button-container">
                                        <Button fullWidth className="btn-action place-order" variant="contained" onClick={(event) => this.handleTabChange(event, 2)}>{i18n.t("Create offer")}</Button>
                                    </div>
                                )}
                                {(tabValue === 2) && (
                                    <div>
                                        <div>
                                            {order_details.order_type === 'Sell' && (
                                                <div className="action-button-container">
                                                    <Button fullWidth className="btn-action place-sell-order" variant="contained" onClick={this.placeOrder}>{i18n.t('Next')}</Button>
                                                </div>
                                            )}
                                        </div>
                                        <div>
                                            {(order_details.order_type === 'Buy' || order_details.order_type === 'Kaufe') && (
                                                <div className="action-button-container">
                                                    <Button fullWidth className="btn-action btn-nxt" variant="contained" onClick={this.BuyTrade}>{i18n.t('Next')}</Button>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>}
                        </ValidatorForm>

                    </DialogContent>
                </Dialog>
                {this.state.forwardStateToTradeModal && (
                    <TradeModal
                        open={this.state.tradeModalStatus}
                        orderDetails={this.state.order_details}
                        resetOrderTransaction={this.resetOrderTransaction}
                        property={this.props.property}
                        handleClose={this.handleTradeModalClose}
                    />
                )}
            </>
        )
    }
}


function mapState(state) {
    const { users } = state;
    return { users };
}

export default connect(mapState, {})(OrderBookModal);
