import React from 'react';
import { connect } from 'react-redux'
import { Grid, Button } from '@material-ui/core';
import i18n from "../../i18n";
import logoB from '../../assets/images/logo-b.png';
import logoW from '../../assets/images/logo-w.png';
import "./styles.scss";
import { secureStorage } from '../../_services';

function InvetmentFooter({
  property,
  onClick,
  users,
  ...props
}) {
  const logos = {
    light: logoB,
    sea: logoB,
    cosmos: logoW,
    spring: logoB,
    forest: logoW,
    butterfly: logoB,
    sensual: logoW,
    dark: logoW,
  };

  const theme = users?.profile_data?.theme || 'light';

  const formatePrice = (val) => {
    if (val !== undefined && val !== null && !isNaN(val)) {
      var parts = val.toString().split(".");
      if (parts.length > 1) {
        val = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".") + (parts[1] ? "," + parts[1] : "");
      }
      val = numberWithCommas(val);
      return val;
    }
  }

  const numberWithCommas = (x) => {
    if (x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }
    return x;
  }

  const getButton = (type) => {
    if(type === 'disabled') {
      return (<>
        <Button onClick={onClick} className="btn-secondary btn-disabled" disabled>
          {i18n.t('Purchase Property')}
        </Button>
      </>);
    } else {
      return (<Button onClick={onClick} className="btn-secondary">
        {i18n.t('Purchase Property')}
      </Button>);
    }
  } 

  return (
    property && (<div className='invetment-footer'>
      {property.property_type === "primary" ? (
        <Grid container alignItems="center">
          <Grid item md={2} sm={3} xs={12}>
            <img src={logos[theme] || logoB} alt="Immobilium Logo" className="footer-logo" />
          </Grid>
          <Grid item md={7} sm={5} xs={12}>
            <p className="property-title">{property.title}</p>
          </Grid>
          <Grid className="text-right" item md={3} sm={4} xs={12}>
            <span className="property-share-value">&#36; {property.price_per_share} / {i18n.t("share_anteil")}</span>
            <Button onClick={onClick} className="btn-secondary">{i18n.t('Invest Now')}</Button>
          </Grid>
        </Grid>
      )
      : property.property_type === 'direct'
      ? (
        <Grid container alignItems="center">
          <Grid item md={2} sm={3} xs={12}>
            <img src={logos[theme] || logoB} alt="Immobilium Logo" className="footer-logo" />
          </Grid>
          <Grid item md={5} sm={3} xs={12}>
            <p className="property-title">{property.title}</p>
          </Grid>
          <Grid className="text-right" item md={5} sm={6} xs={12}>
            <span className="property-share-value">{i18n.t('Price')} &#36;{formatePrice(property.property_price)}</span> 
            {users.user_id === property._user_id ? 
              (getButton('disabled')) : (secureStorage.getItem('is_kyc_verified') ? 
                (getButton()) : (getButton('disabled'))
              )
            }
          </Grid>
        </Grid>
      )
      : (
        <Grid container alignItems="center">
          <Grid item md={2} sm={3} xs={12}>
            <img src={logos[theme] || logoB} alt="Immobilium Logo" className="footer-logo" />
          </Grid>
          <Grid item md={5} sm={3} xs={12}>
            <p className="property-title">{property.title}</p>
          </Grid>
          <Grid className="text-right" item md={5} sm={6} xs={12}>
            <span className="property-share-value">{i18n.t('Lowest Available Brick Price')} &#36; {property.lowest_available_brick_price}</span>
            <Button onClick={onClick} className="btn-secondary">{i18n.t('Create offer now')}</Button>
          </Grid>
        </Grid>
      )}
    </div>)
  );
}


function mapState(state) {
  const { users } = state;
  return { users };
}

export default connect(mapState)(InvetmentFooter);
