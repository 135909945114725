import { blockchainConstants } from '../_constants';

export const initialState = {
  languageCode: 'en'
}


export function blockchain(state = initialState, action) {
  switch (action.type) {
    case blockchainConstants.GET_BALANCES_SUCCESS:
      return {
        ...state,
        balances: action.payload
      }
    default:
      return state
  }
}
