import React from 'react';
import red from '@material-ui/core/colors/red';
import { ValidatorComponent } from 'react-form-validator-core';
import { Select } from '@material-ui/core';

const red300 = red['500'];

const styles = {
    errorMsg: {
        fontSize: '12px',
        color: red300,
        width: 'max-content',
    },
    selectBox: {
        border: '1px solid red'
    }
};

export default class SelectValidator extends ValidatorComponent {

    renderValidatorComponent() {
        const { error, errorMessages, validators, requiredError, helperText, validatorListener, withRequiredValidator, ...rest } = this.props;
        const selectStyle = !this.state.isValid ? styles.selectBox : '';
        return (
            <>
                <Select
                    style={{ ...selectStyle }}
                    {...rest}
                    ref={(r) => { this.input = r; }}
                >
                    {this.props.children}
                </Select>
                {this.errorText()}
            </>
        );
    }


    errorText() {
        const { isValid } = this.state;

        if (isValid) {
            return null;
        }

        return (
            <div style={styles.errorMsg}>
                {this.getErrorMessage()}
            </div>
        );
    }
}