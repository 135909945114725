import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import { NavLink } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, Hidden, IconButton, Drawer } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import logoB from './../../assets/images/logo-b.png';
import logoW from './../../assets/images/logo-w.png';
import HeaderLinks from './HeaderLinks';
import './styles.scss';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    position: 'sticky',
    top: 0,
    zIndex: 2000,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  }
}));


function HeaderComponent({
  users,
  stickyPosition = 0,
  noStickyClass = 'noSticky',
  ...props
}) {
  const classes = useStyles();
  const [mobileNavOpen, setMobileNavOpen] = React.useState(false);
  const [theme, setTheme] = React.useState('light');
  const [isSticky, setIsSticky] = useState(false);

  const handleNavDrawerToggle = () => {
    setMobileNavOpen(!mobileNavOpen);
  };

  const logos = {
    light: logoB,
    sea:  logoB,
    cosmos: logoW,
    spring:  logoB,
    forest: logoW,
    butterfly:  logoB,
    sensual: logoW,
    dark: logoW,
  };

  useEffect(() => {
    setTheme(users?.profile_data?.theme || 'light');
  }, [users]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  })

  const handleScroll = (event) => {
    let scrollTop = document.documentElement.scrollTop;
    setIsSticky(scrollTop > stickyPosition);
  }

  const brandComponent =
    <NavLink className="nav-logo" to="/">
      <img
        src={props.logopath != '' ? props.logopath : logos[theme] || logoB}
        alt="Crowdfunding Real Estate Blockchain Logo"
        style={{backgroundColor: props.logobackground, paddingLeft: 10, paddingRight: 10}}
      />
    </NavLink>;

  return (
    <div className={`app-navbar-container ${classes.root} ${isSticky ? 'isSticky' : noStickyClass}`}>
      <AppBar position="static" color="inherit" className="navbar">
        <Toolbar disableGutters>
          <Hidden mdUp>
            <IconButton
              className='btn-menu-toggle'
              color="inherit"
              aria-label="open drawer"
              onClick={handleNavDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>

          {brandComponent}

          <Hidden smDown implementation="css">
            <HeaderLinks />
          </Hidden>
        </Toolbar>

        <Hidden mdUp implementation="js">
          <Drawer
            variant="temporary"
            open={mobileNavOpen}
            onClose={handleNavDrawerToggle}
          >
            <div className='header-link-container'>
              <HeaderLinks navControl={handleNavDrawerToggle} />
            </div>
          </Drawer>
        </Hidden>
      </AppBar>
    </div>
  )
}


function mapState(state) {
  const { users } = state;
  return { users };
}

export default connect(mapState)(HeaderComponent);