import React from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
import { useConfirm } from './useConfirm';

const ConfirmModal = () => {
  const {
    prompt = '',
    proceed,
    cancel,
    waitingMessage,
    isOpen = false, 
    isLoading = false,
  } = useConfirm();

  const title = prompt.title || undefined;
  const message = prompt.message || prompt;
  const okayTitle = prompt.okay || 'Ok';
  const cancelTitle = prompt.cancel;

  const modals = [];
  if (isOpen) {
    modals.push((
      <Dialog
        open={isOpen}
        onClose={cancel}
        disableBackdropClick={prompt.disableBackdropClick}
      >
        <DialogTitle>
          {title}
        </DialogTitle>
        <DialogContent>
          {message}
        </DialogContent>
        <DialogActions>
          {cancelTitle && <Button disableElevation onClick={() => cancel && cancel()}>
            {cancelTitle}
          </Button>}
          <Button
            variant="contained"
            disableElevation
            onClick={() => proceed && proceed()}
          >
            {okayTitle}
          </Button>
        </DialogActions>
      </Dialog>
    ));
  }
  return modals.length
    ? <>{modals.map((m, idx) => <div key={idx}>{m}</div>)}</>
    : null;
};

export { ConfirmModal };
export default ConfirmModal;

// const Button = styled(ButtonComponent)`
//   min-width: 200px;
// `;
// const ConfirmDialog = styled(Dialog)`
//   .MuiDialog-paper {
//     padding: 8px 32px;
//   }
// `;
// const LoadingDialog = styled(Dialog)`
//   .MuiDialog-paper {
//     border-radius: 16px;
//     overflow: hidden;
//   }
// `;
// const CirularContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   min-width: 240px;
//   min-height: 240px;
//   align-items: center;
//   justify-content: center;
// `;
// const WaitingMessage = styled.div`
//   margin: 32px 32px -16px 32px;
// `