import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { initialState } from '../../_reducers/general.reducer';
import "./styles.scss";

class HighlightsComponent extends Component {
    getSectionLanguageBasedDetail = (sectionData) => {
        var heading = initialState.languageCode === 'en' ? sectionData.section_heading_en : sectionData.section_heading_de;
        var description = initialState.languageCode === 'en' ? sectionData.section_description_en : sectionData.section_description_de;
        return {
            heading,
            description
        }
    }

    render() {
        const { property } = this.props;
        const highlightSection = property.sections ? property.sections.find((section) => { return section.section_key === 'the_highlights' }) : "";
        const propertySection = property.sections ? property.sections.find((section) => { return section.section_key === 'the_property' }) : "";

        return (
            <div className="highlights-box">
                {highlightSection && (
                    <div className="section">
                        <h2 className="title-text">{this.getSectionLanguageBasedDetail(highlightSection).heading}</h2>
                        <div>{ReactHtmlParser(this.getSectionLanguageBasedDetail(highlightSection).description)}</div>
                    </div>
                )}
                {propertySection && (
                    <div className="section">
                        <h2 className="title-text">{this.getSectionLanguageBasedDetail(propertySection).heading}</h2>
                        <div>{ReactHtmlParser(this.getSectionLanguageBasedDetail(propertySection).description)}</div>
                    </div>
                )}
            </div>
        )
    }
}

export default HighlightsComponent;