import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import { connect } from "react-redux";
import ReactHtmlParser from 'react-html-parser';
import { generalActions } from "../../_actions";
import "./styles.scss";


const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        margin: theme.spacing(1),
    },
    partnerImage: {
        width: theme.spacing(9),
        height: theme.spacing(9),
        marginRight: theme.spacing(2),
    }
}));

export function PartnerList(props) {
    const classes = useStyles();

    return (
        <List className={`${classes.root} partner-list-root`}>
            {props.partners.map((partner, index) => {
                const { partner_id, involvement } = partner;
                var partnerDetails = props.getPartnerById(partner_id);
                var partner_name = partnerDetails !== null ? partnerDetails.name : '';
                var partner_logo = partnerDetails !== null ? partnerDetails.logo : '';
                var partner_description = partnerDetails !== null ? partnerDetails.description : '';

                return (
                    <div key={index} className="list-item-container">
                        <ListItem className="list-item" alignItems="flex-start">
                            <ListItemAvatar>
                                <Avatar className={classes.partnerImage} alt="test img" src={partner_logo ? partner_logo : ''}>
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText>
                                <>
                                    <div className="text-primary">
                                        <p><strong>{partner_name ? partner_name : ''}</strong></p>
                                        <p><strong>{involvement}</strong></p>
                                    </div>
                                    <div className="text-secondary">
                                        <p className="txt-description">{ReactHtmlParser(partner_description ? partner_description : '')}</p>
                                    </div>
                                    <Divider className="list-item-divider" variant="inset" component="div" />
                                </>
                            </ListItemText>
                        </ListItem>
                    </div>
                )
            })}
        </List>
    );
}

class PatnerDetails extends Component {
    constructor() {
        super()
        this.state = {
            available_partners: [],
        }
    }

    componentDidMount() {
        this.props.getPartners();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { partners } = nextProps.general;
        var update = {};

        if (partners && partners.status === true) {
            update = {
                ...update,
                available_partners: partners.data
            };
        }
        return update;
    }

    getPartnerById = (partnerId) => {
        const { available_partners } = this.state;
        if (available_partners.length) {
            var partner = available_partners.find((partner) => partner.id === partnerId);
            return partner ? partner : null;
        }
        return 'Unknown';
    }

    render() {
        const { property } = this.props;
        const isPropertyPartners = property && property.partners && property.partners.length > 0;
        const property_partners = property.partners;


        return (
            <div className='partners-details'>
                <h2 className="title-text">Partners</h2>
                {isPropertyPartners ?
                    <PartnerList
                        partners={property_partners}
                        getPartnerById={this.getPartnerById}
                    />
                    :
                    <Grid container>
                        <Grid item md={3} xs={12}>
                            <p>Not available</p>
                        </Grid>
                    </Grid>
                }
            </div>
        )
    }
}

function mapState(state) {
    const { general } = state;
    return { general };
}

const actionCreators = {
    getPartners: generalActions.getPartners,
};

export default connect(mapState, actionCreators)(PatnerDetails);