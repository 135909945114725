import React, { Component } from 'react';
import { Dialog, DialogContent, Box, Button } from '@material-ui/core';
import CreditCardOutlinedIcon from '@material-ui/icons/CreditCardOutlined';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import i18n from "../../../i18n";
import "./styles.scss";

class WithdrawModal extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        ValidatorForm.addValidationRule('isIntNumber', (value) => {
            const regex = /^[1-9]\d*$/;
            return regex.test(value);
        });
    }

    render() {
        return (
            <Dialog className='deposit-modal' fullWidth={true} maxWidth={"xs"} onClose={this.props.handleClose} open={this.props.open}>
                <DialogContent>
                    <div className="block-header">{i18n.t('Withdraw')}</div>
                    <div className="payment-icon"><CreditCardOutlinedIcon /></div>

                    <div className="block-content">
                        <p className="txt-type">{i18n.t('How much USD would you like to withdraw?')}</p>
                    </div>

                    <ValidatorForm
                        className='default-form'
                        onSubmit={this.props.nextWithdrawModal}
                        ref="form"
                        autoComplete="off"
                    >

                        <Box mx={3} my={1}>
                            <TextValidator
                                fullWidth
                                value={this.props.withdrawAmount}
                                onChange={this.props.handleChange}
                                name="withdraw"
                                className="form-control"
                                label={i18n.t('Money Amount ($)')}
                                margin="normal"
                                variant="outlined"
                                validators={['required', 'isIntNumber']}
                                errorMessages={[i18n.t('This field is required'), i18n.t('Not allowed')]}
                            />
                        </Box>

                        <Button type="submit" className="btn-print">{i18n.t('Next Step')}</Button>
                    </ValidatorForm>

                </DialogContent>
            </Dialog>
        )
    }
}

export default WithdrawModal;
